
import { newsletterMixin } from '~/mixins'
export default {
  name: 'NewsletterForm',
  mixins: [newsletterMixin],
  props: {
    skipInitialEmail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      initialLoaded: false,
    }
  },
  computed: {
    preselects() {
      return [
        {
          name: 'email',
          change: (input) => (input.defaultValue = this.form?.email),
        },
      ]
    },
  },
  watch: {
    showModal(newValue) {
      if (this.initialLoaded === false && newValue === true) {
        this.initialLoaded = true
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    },
  },
  methods: {
    openModal() {
      if (!this.skipInitialEmail) {
        this.$v.form.email.$touch()
        if (this.$v.form.email.$invalid === true) return
      }
      this.showModal = true
    },
  },
}
