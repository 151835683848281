const emailBlackList = ['ru', 'by', 'su']

export const isEmailSafe = (email) => {
  if (typeof email === 'string') {
    const [, host] = email.split('@')
    if (typeof host === 'undefined') return false
    const lowerCaseHost = host.toLowerCase()
    const splittedHost = lowerCaseHost.split('.')
    for (const ext of emailBlackList) {
      for (const sub of splittedHost) {
        if (sub === ext) return false
      }
    }
    return true
  } else {
    return false
  }
}
