export const FORM_NAMES = {
  TelematicsSupportForm: 'TelematicsSupportPageForm',
  NetworksSupportForm: 'NetworksSupportPageForm',
  WebinarRegistrationForm: 'WebinarsForm',
}

export const FORM_STATUSES = {
  LOADING: 'loading',
  ERROR: 'error',
  FINISHED: 'finished',
  SUCCESS: 'success',
}
