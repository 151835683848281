import { TAGS } from '~/types/tags'
export default {
  computed: {
    as() {
      if (!this.linkUrl) return TAGS.div
      return this.linkUrl.includes('http') ? TAGS.a : TAGS.nuxtLink
    },
    dynamicProps() {
      const props = {}
      if (this.as === TAGS.a) {
        props.href = this.linkUrl
        props.target = '_blank'
      } else if (this.as === TAGS.nuxtLink)
        props.to = this.localePath(this.linkUrl)
      return props
    },
  },
}
