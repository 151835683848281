export default {
  head(context) {
    const title = this.$t(
      context.$store.app.head.meta.find((obj) => obj?.hid === 'og:title')
        ?.content || 'front.global.iot.title',
    )
    const description = this.$t(
      context.$store.app.head.meta.find((obj) => obj?.hid === 'description')
        ?.content || 'front.global.iot.description',
    )
    return {
      title,
      meta: [
        {
          name: 'description',
          hid: 'description',
          content: description,
        },
        {
          property: 'og:title',
          hid: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          hid: 'og:description',
          content: description,
        },
        {
          property: 'twitter:title',
          hid: 'twitter:title',
          content: title,
        },
        {
          property: 'twitter:description',
          hid: 'twitter:description',
          content: description,
        },
      ],
    }
  },
}
