
import { mapMutations } from 'vuex'
import { debounce } from '@/utils'

export default {
  name: 'Navigation',
  data() {
    return {
      debouncedTrack: null,
      limitPosition: 65,
      lastPosition: 0,
      isNavigationVisible: true,
      isTop: true,
    }
  },
  computed: {
    theme() {
      return this.$store.state.navigation.theme
    },
  },
  watch: {
    isNavigationVisible(newValue) {
      this.setIsNavigationVisible(!!newValue)
      if (newValue === false) {
        this.closeNavigation()
        this.closeNavigation('mobile')
      }
    },
  },
  mounted() {
    this.debouncedTrack = debounce(this.trackScroll, 150)
    document.addEventListener('scroll', this.debouncedTrack, { passive: false })
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.debouncedTrack)
  },
  methods: {
    trackScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.isNavigationVisible = false
      }
      if (this.theme === 'transparent') {
        this.isTop = this.limitPosition > window.scrollY
      }
      if (this.lastPosition > window.scrollY) {
        this.isNavigationVisible = true
      }
      this.lastPosition = window.scrollY
    },
    ...mapMutations({
      closeNavigation: 'navigation/closeNavigation',
      setIsNavigationVisible: 'navigation/setIsNavigationVisible',
    }),
  },
}
