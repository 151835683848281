
import { mapGetters } from 'vuex'
export default {
  name: 'Link',
  props: {
    title: {
      type: String,
      default: 'Menu title missing',
    },
    url: {
      type: String,
      default: '',
    },
    children: {
      type: Array,
      default: () => [],
      validator(valueArray) {
        let isValid = true
        for (const item of valueArray) {
          if (!('title' in item && 'url' in item && 'children' in item)) {
            isValid = false
            break
          }
        }
        return isValid
      },
    },
    badge: {
      type: [Object, String],
      default: () => ({ type: '' }),
    },
    root: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  computed: {
    ...mapGetters({ isDesktop: 'resize/isDesktop' }),
    badges() {
      return this.$store.state.navigation.badges
    },
    badgeInfo() {
      if (!(`${this.badge?.type || ''}` in this.badges)) return false
      return this.badges[`${this.badge?.type}`]
    },
    block() {
      const block = {}
      if (typeof this.url === 'undefined') return { name: 'div' }

      if (this.root) {
        return {
          name: 'div',
        }
      }
      if (this.url.includes('https://')) {
        block.name = 'a'
        block.href = this.url
        block.target = '_blank'
      } else {
        block.name = 'NuxtLink'
        block.to = this.localePath(this.url)
      }
      return block
    },
    menuItems() {
      return this.children?.reduce((previous, current) => {
        if (current.url !== '') {
          previous.push(current)
        } else {
          console.error(`"${current.title}" menu im does not have a valid url`)
        }
        return previous
      }, [])
    },
  },
  watch: {
    isDesktop: {
      immediate: true,
      handler(newVal) {
        if (newVal) this.isExpanded = true
      },
    },
    isExpanded(newValue) {
      if (newValue) {
        for (const item of this.$parent.$children) {
          if ('setExpanded' in item && item?._uid !== this._uid) {
            item.setExpanded(false)
          }
        }
      }
    },
  },
  methods: {
    setExpanded(value) {
      if (this.root === false || this.isDesktop === false)
        this.isExpanded = value
    },
  },
}
