export default {
  methods: {
    scrollToCurrent() {
      if (!this.$route?.hash) return
      // load module only when needed
      import('vue-scrollto')
        .then((scroll) => {
          setTimeout(() => {
            scroll.scrollTo(this.$el, 600, {
              offset: -75,
            })
          }, 500) // timeout for dom
        })
        .catch((ex) => this.$sentry.captureException(ex))
    },
  },
}
