import { singleProduct } from '@/queries/singleProduct.gql'
import { formatMeta } from '~/utils'
export default {
  name: 'ProductsSlug',
  async asyncData({ $queryPage, $config, error, store }) {
    try {
      const {
        data: { product },
      } = await $queryPage(singleProduct, {
        domainId: $config.domainId,
      })

      const sections = product?.ui_sections

      if (!product || !Array.isArray(sections) || sections?.length === 0) {
        return error({ statusCode: 404, message: 'SERVER ERROR' })
      }
      store.commit(
        'navigation/setTheme',
        product?.templateSettings?.disableTransparency === true
          ? 'default'
          : 'transparent',
      )
      return {
        product,
        sections: sections || [],
        templateSettings: product.templateSettings,
      }
    } catch (ex) {
      console.log(ex)
    }
  },
  head() {
    const url = `${this.$config.domain}${
      this.$i18n.locale === 'en' ? '' : '' + this.$i18n.locale
    }${this.product?.current_url?.full_url}`
    const socialImage =
      this.product?.socialImages?.extras?.e_1200xAuto?.jpg?.src ||
      this.$config.domain + '/images/seo/teltonika_logo.jpg'

    return formatMeta({
      title: this.product?.metaTitle || 'Teltonika - IoT, Internet of Things',
      description: this.product?.metaDescription,
      img: socialImage,
      url,
      canonical: this.product?.canonicalUrl || '',
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    })
  },
  render(createElement) {
    const sections = this.sections.map((section) =>
      createElement('ProductsSections' + section.section, {
        attrs: {
          ...section.groups,
          component: section.section,
        },
      }),
    )
    return createElement('div', sections)
  },
}
