export const youtubeVideoID = (link) => {
  if (typeof link !== 'string') return ''

  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = link
    .replace(/<p[^>]*>/g, '')
    .replace(/<\/p>/g, '')
    .match(regExp)
  return match && match[2]?.length === 11 ? match[2] : ''
}

export const youtubeThumbUrl = (link, resolution = 'maxresdefault') => {
  const videoID = youtubeVideoID(link)
  return `https://img.youtube.com/vi_webp/${videoID}/${resolution}.webp`
}

export const getEmbeddedUrl = (url, autoplay = true, enablejsapi = false) => {
  const videoID = youtubeVideoID(url)
  let link = `https://www.youtube.com/embed/${videoID}`
  if (autoplay) {
    link = `https://www.youtube.com/embed/${videoID}?autoplay=1`
  }
  if (enablejsapi) {
    link = autoplay ? `${link}&enablejsapi=1` : `${link}?enablejsapi=1`
  }
  return link
}
