export const deepSearchByKey = (object, originalKey, matches = []) => {
  if (object != null) {
    if (Array.isArray(object)) {
      for (const arrayItem of object) {
        deepSearchByKey(arrayItem, originalKey, matches)
      }
    } else if (typeof object === 'object') {
      for (const key of Object.keys(object)) {
        if (key === originalKey) {
          matches.push(object)
        } else {
          deepSearchByKey(object[key], originalKey, matches)
        }
      }
    }
  }

  return matches
}

export const getDeepValueByKey = (object, key, equals) => {
  const matches = deepSearchByKey(object, key)
  for (const match of matches) {
    if (match[key] === equals) {
      const value = deepSearchByKey(match, 'value')
      return value?.[0]?.value || null
    }
  }
  return null
}

export const getUrlFromJson = (urlString = '{}') => {
  let url = ''
  try {
    const { language_code: languageCode, full_url: fullUrl } = urlString
    if (languageCode !== 'en' && !fullUrl.includes(`/${languageCode}/`)) {
      url = `/${languageCode}` + fullUrl
    } else {
      url = fullUrl
    }
  } catch (ex) {
    console.error(ex, `\nCan't parse url object: `, urlString)
  }

  return url
}
/*
    Filters out all duplicates values by key
    Params: arr: Array, key: String
    Returns: Array
*/
export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()]
}
