export default function safeParseJson(jsonString) {
  try {
    if (!JSON.parse(jsonString))
      throw new Error(
        'safeParseJson.js, no result after parsing, check if data is correct',
        jsonString,
      )
    return JSON.parse(jsonString)
  } catch (e) {
    console.error('safeParseJson error', e)
    return false
  }
}
