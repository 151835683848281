
// Do not use type="picture", because lazy loading is not working with it.
// If you need original image in webp format, use thumb="replaced"
// You need to explicitly write thumb if you want webp - because our default "original" does not have webp format

import { THUMBS } from './constants.js'
import Manager from './manager.js'
export default {
  name: 'FileManagerImage',
  props: {
    media: {
      type: Object,
      required: true,
      validator(value) {
        if (typeof value !== 'object') return false
        if (!Array.isArray(value.extras) && value.extras?.length <= 0)
          return false

        return true
      },
    },
    defaultImage: {
      type: String,
      default: '/images/default-image.jpg',
    },
    type: {
      type: String,
      default: 'img',
      validator(value) {
        return ['img', 'picture'].includes(value)
      },
    },
    format: {
      type: String,
      default: 'jpg',
      validator(value) {
        return ['jpg', 'png', 'webp'].includes(value)
      },
    },
    thumb: {
      type: String,
      default: 'original',
      validator(value) {
        return [...Object.keys(THUMBS)].includes(value)
      },
    },
    alt: {
      type: [String, Boolean],
      default: false,
    },
    loading: {
      type: [String, Boolean],
      default: false,
      validator(value) {
        return ['lazy', 'eager', 'auto', false].includes(value)
      },
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onLoad(event) {
      this.$emit('load', event)
    },
    onError(event) {
      this.type === 'picture'
        ? (event.target.parentElement.firstChild.srcset = this.defaultImage)
        : (event.target.src = this.defaultImage)
      this.$emit('error', event)
    },
  },
  render(h) {
    const { media } = this
    if (!media) return console.error('No media passed in FileManager component')
    const file = new Manager(media)
    const props = {
      alt: this?.alt || media?.alt || media?.name || '',
    }
    const listeners = {
      load: this.onLoad,
      error: this.onError,
    }
    if (this.thumb !== 'original') {
      const [width, height] = this.thumb.split('x')
      if (typeof this.$attrs.width === 'undefined') props.width = width
      if (typeof this.$attrs.height === 'undefined') props.height = height
    }
    if (this.loading) {
      props.loading = this.loading
    }

    if (this.isIcon) {
      return h('img', {
        attrs: {
          src: file.media.src,
          ...props,
        },
        on: {
          ...listeners,
        },
      })
    }

    if (this.type === 'img') {
      const src = file
        .thumb(this.thumb)
        .format(this.format)
        .get('src', this.defaultImage)

      return h('img', {
        attrs: {
          src,
          ...props,
        },
        on: {
          ...listeners,
        },
      })
    } else if (this.type === 'picture') {
      if (this.$vnode?.data?.staticClass) {
        props.class = this.$vnode?.data?.staticClass
      }
      if (
        this.$vnode?.data?.class &&
        Object.keys(this.$vnode?.data?.class).length > 0
      ) {
        const dynamicClasses = []
        for (const [key, value] of Object.entries(this.$vnode?.data?.class)) {
          if (value) dynamicClasses.push(key)
        }
        const sterilizedClasses = dynamicClasses.toString().replace(/,/g, ' ')
        props.class = props.class + ' ' + sterilizedClasses
      }
      return h('picture', [
        h('source', {
          attrs: {
            srcset: file
              .thumb(this.thumb)
              .format('webp')
              .get('src', this.defaultImage),
            type: 'image/webp',
          },
          on: {
            ...listeners,
          },
        }),
        h('img', {
          attrs: {
            ...props,
            src: file
              .thumb(this.thumb)
              .format('jpg')
              .get('src', this.defaultImage),
          },

          on: {
            ...listeners,
          },
        }),
      ])
    } else {
      return h('div', 'Invalid image type')
    }
  },
}
