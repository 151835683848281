import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _41c45f1c = () => interopDefault(import('../pages/wrapper.vue' /* webpackChunkName: "wrapper" */))
const _9b8cc324 = () => interopDefault(import('../pages/compare.vue' /* webpackChunkName: "compare" */))
const _20752c4f = () => interopDefault(import('../pages/teltonika-networks.com-landing.vue' /* webpackChunkName: "teltonika-networks.com-landing" */))
const _7f2d4265 = () => interopDefault(import('../pages/glossary.vue' /* webpackChunkName: "glossary" */))
const _10463ea2 = () => interopDefault(import('../pages/media-archive.vue' /* webpackChunkName: "media-archive" */))
const _73ff5b1d = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "news" */))
const _755bf3d3 = () => interopDefault(import('../pages/productsNetworks/index.vue' /* webpackChunkName: "productsNetworks" */))
const _2673e8af = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "search" */))
const _928d5fe4 = () => interopDefault(import('../pages/dynamic-newsletter.vue' /* webpackChunkName: "dynamic-newsletter" */))
const _89a5fc3c = () => interopDefault(import('../pages/t-mobile.vue' /* webpackChunkName: "t-mobile" */))
const _ea1bd202 = () => interopDefault(import('../pages/defaultContent.vue' /* webpackChunkName: "defaultContent" */))
const _fd754a6e = () => interopDefault(import('../pages/use-cases/index.vue' /* webpackChunkName: "use-cases" */))
const _cd863b84 = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "careers" */))
const _43c793cc = () => interopDefault(import('../pages/contacts.vue' /* webpackChunkName: "contacts" */))
const _17157922 = () => interopDefault(import('../pages/mission-vision-values.vue' /* webpackChunkName: "mission-vision-values" */))
const _89ee1394 = () => interopDefault(import('../pages/policiesCertificates/index.vue' /* webpackChunkName: "policiesCertificates" */))
const _61180b77 = () => interopDefault(import('../pages/who-we-are-networks.vue' /* webpackChunkName: "who-we-are-networks" */))
const _66cb1dcc = () => interopDefault(import('../pages/custom-product.vue' /* webpackChunkName: "custom-product" */))
const _737c122a = () => interopDefault(import('../pages/iot-platforms.vue' /* webpackChunkName: "iot-platforms" */))
const _2ae9892c = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "blog" */))
const _99d23f9e = () => interopDefault(import('../pages/certificationProgram.vue' /* webpackChunkName: "certificationProgram" */))
const _a54c8f24 = () => interopDefault(import('../pages/share-your-solution.vue' /* webpackChunkName: "share-your-solution" */))
const _09273272 = () => interopDefault(import('../pages/video/index.vue' /* webpackChunkName: "video" */))
const _2e232d93 = () => interopDefault(import('../pages/webinars/index.vue' /* webpackChunkName: "webinars" */))
const _c25f0f92 = () => interopDefault(import('../pages/eol-networks.vue' /* webpackChunkName: "eol-networks" */))
const _6eb69794 = () => interopDefault(import('../pages/gpl-code-center.vue' /* webpackChunkName: "gpl-code-center" */))
const _5c755c58 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "support" */))
const _626335ec = () => interopDefault(import('../pages/vulnerability-report.vue' /* webpackChunkName: "vulnerability-report" */))
const _f4e2d386 = () => interopDefault(import('../pages/warranty-and-repair.vue' /* webpackChunkName: "warranty-and-repair" */))
const _73a5528b = () => interopDefault(import('../pages/productsNetworks/_slug.vue' /* webpackChunkName: "productsNetworks" */))
const _8d5b5624 = () => interopDefault(import('../pages/policiesCertificates/_slug.vue' /* webpackChunkName: "policiesCertificates" */))
const _2932e7e4 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "blog" */))
const _0c947502 = () => interopDefault(import('../pages/video/_slug.vue' /* webpackChunkName: "video" */))
const _2c6c8c4b = () => interopDefault(import('../pages/webinars/_slug.vue' /* webpackChunkName: "webinars" */))
const _7f8eb981 = () => interopDefault(import('../pages/use-cases/_slug.vue' /* webpackChunkName: "use-cases" */))
const _7248b9d5 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "news" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","lt":"\u002Fapie-mus","de":"\u002Fabout-us","es":"\u002Fabout-us","jp":"\u002Fabout-us","ua":"\u002Fabout-us"},"page_id":14},
    name: "about-us-14___en"
  }, {
    path: "/compare",
    component: _9b8cc324,
    meta: {"paths":{"en":"\u002Fcompare","lt":"\u002Fcompare","de":"\u002Fcompare","es":"\u002Fcompare","jp":"\u002Fcompare","ua":"\u002Fcompare"},"page_id":165},
    name: "compare-165___en"
  }, {
    path: "/de",
    component: _20752c4f,
    meta: {"paths":{"en":"\u002F","lt":"\u002F","de":"\u002F","es":"\u002F","jp":"\u002F","ua":"\u002F"},"page_id":3},
    name: "teltonika-networks.com-landing-3___de"
  }, {
    path: "/es",
    component: _20752c4f,
    meta: {"paths":{"en":"\u002F","lt":"\u002F","de":"\u002F","es":"\u002F","jp":"\u002F","ua":"\u002F"},"page_id":3},
    name: "teltonika-networks.com-landing-3___es"
  }, {
    path: "/glossary",
    component: _7f2d4265,
    meta: {"paths":{"en":"\u002Fglossary","lt":"\u002Fglossary","de":"\u002Fglossary","es":"\u002Fglossary","jp":"\u002Fglossary","ua":"\u002Fglossary"},"page_id":206},
    name: "glossary-206___en"
  }, {
    path: "/jp",
    component: _20752c4f,
    meta: {"paths":{"en":"\u002F","lt":"\u002F","de":"\u002F","es":"\u002F","jp":"\u002F","ua":"\u002F"},"page_id":3},
    name: "teltonika-networks.com-landing-3___jp"
  }, {
    path: "/lt",
    component: _20752c4f,
    meta: {"paths":{"en":"\u002F","lt":"\u002F","de":"\u002F","es":"\u002F","jp":"\u002F","ua":"\u002F"},"page_id":3},
    name: "teltonika-networks.com-landing-3___lt"
  }, {
    path: "/media-archive",
    component: _10463ea2,
    meta: {"paths":{"en":"\u002Fmedia-archive","lt":"\u002Fmedia-archive","de":"\u002Fmedia-archive","es":"\u002Fmedia-archive","jp":"\u002Fmedia-archive","ua":"\u002Fmedia-archive"},"page_id":162},
    name: "media-archive-162___en"
  }, {
    path: "/news",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","lt":"\u002Fnaujienos-1","de":"\u002Fnews","es":"\u002Fnews","jp":"\u002Fnews","ua":"\u002Fnews"},"page_id":7},
    name: "news-7___en"
  }, {
    path: "/newsroom",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","lt":"\u002Fnaujienos","de":"\u002Fnewsroom","es":"\u002Fnewsroom","jp":"\u002Fnewsroom","ua":"\u002Fnewsroom"},"page_id":8},
    name: "newsroom-8___en"
  }, {
    path: "/products",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts","lt":"\u002Fproduktai","de":"\u002Fproducts","es":"\u002Fproducts","jp":"\u002Fproducts","ua":"\u002Fproducts"},"page_id":114},
    name: "products-114___en"
  }, {
    path: "/products-wrapper",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fproducts-wrapper","lt":"\u002Fproduktai-wrapper","de":"\u002Fproducts-wrapper","es":"\u002Fproducts-wrapper","jp":"\u002Fproducts-wrapper","ua":"\u002Fproducts-wrapper"},"page_id":54},
    name: "products-wrapper-54___en"
  }, {
    path: "/resources",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fresources","lt":"\u002Fresursai","de":"\u002Fresources","es":"\u002Fresources","jp":"\u002Fresources","ua":"\u002Fresources"},"page_id":28},
    name: "resources-28___en"
  }, {
    path: "/search",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","lt":"\u002Fpaieska","de":"\u002Fsearch","es":"\u002Fsearch","jp":"\u002Fsearch","ua":"\u002Fsearch"},"page_id":45},
    name: "search-45___en"
  }, {
    path: "/subscribe",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","lt":"\u002Fprenumeruok","de":"\u002Fsubscribe","es":"\u002Fsubscribe","jp":"\u002Fsubscribe","ua":"\u002Fsubscribe"},"page_id":103},
    name: "subscribe-103___en"
  }, {
    path: "/support-video-tutorials",
    component: _10463ea2,
    meta: {"paths":{"en":"\u002Fsupport-video-tutorials","lt":"\u002Fsupport-video-tutorials","de":"\u002Fsupport-video-tutorials","es":"\u002Fsupport-video-tutorials","jp":"\u002Fsupport-video-tutorials","ua":"\u002Fsupport-video-tutorials"},"page_id":171},
    name: "support-video-tutorials-171___en"
  }, {
    path: "/t-mobile",
    component: _89a5fc3c,
    meta: {"paths":{"en":"\u002Ft-mobile","lt":"\u002Ft-mobile","de":"\u002Ft-mobile","es":"\u002Ft-mobile","jp":"\u002Ft-mobile","ua":"\u002Ft-mobile"},"page_id":196},
    name: "t-mobile-196___en"
  }, {
    path: "/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___en"
  }, {
    path: "/ua",
    component: _20752c4f,
    meta: {"paths":{"en":"\u002F","lt":"\u002F","de":"\u002F","es":"\u002F","jp":"\u002F","ua":"\u002F"},"page_id":3},
    name: "teltonika-networks.com-landing-3___ua"
  }, {
    path: "/use-cases",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases","lt":"\u002Fvisi-panaudojimai","de":"\u002Fuse-cases","es":"\u002Fuse-cases","jp":"\u002Fuse-cases","ua":"\u002Fuse-cases"},"page_id":96},
    name: "use-cases-96___en"
  }, {
    path: "/about-us/career",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcareer","lt":"\u002Fapie-mus\u002Fkarjera","de":"\u002Fabout-us\u002Fcareer","es":"\u002Fabout-us\u002Fcareer","jp":"\u002Fabout-us\u002Fcareer","ua":"\u002Fabout-us\u002Fcareer"},"page_id":21},
    name: "about-us-career-21___en"
  }, {
    path: "/about-us/contacts",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai","de":"\u002Fabout-us\u002Fcontacts","es":"\u002Fabout-us\u002Fcontacts","jp":"\u002Fabout-us\u002Fcontacts","ua":"\u002Fabout-us\u002Fcontacts"},"page_id":19},
    name: "about-us-contacts-19___en"
  }, {
    path: "/about-us/mission-vision-values",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes","de":"\u002Fabout-us\u002Fmission-vision-values","es":"\u002Fabout-us\u002Fmission-vision-values","jp":"\u002Fabout-us\u002Fmission-vision-values","ua":"\u002Fabout-us\u002Fmission-vision-values"},"page_id":16},
    name: "about-us-mission-vision-values-16___en"
  }, {
    path: "/about-us/policies-certificates",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai","de":"\u002Fabout-us\u002Fpolicies-certificates","es":"\u002Fabout-us\u002Fpolicies-certificates","jp":"\u002Fabout-us\u002Fpolicies-certificates","ua":"\u002Fabout-us\u002Fpolicies-certificates"},"page_id":18},
    name: "about-us-policies-certificates-18___en"
  }, {
    path: "/about-us/who-we-are",
    component: _61180b77,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fwho-we-are","lt":"\u002Fapie-mus\u002Fkas-mes-esame","de":"\u002Fabout-us\u002Fwho-we-are","es":"\u002Fabout-us\u002Fwho-we-are","jp":"\u002Fabout-us\u002Fwho-we-are","ua":"\u002Fabout-us\u002Fwho-we-are"},"page_id":104},
    name: "about-us-who-we-are-104___en"
  }, {
    path: "/de/about-us",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","lt":"\u002Fapie-mus","de":"\u002Fabout-us","es":"\u002Fabout-us","jp":"\u002Fabout-us","ua":"\u002Fabout-us"},"page_id":14},
    name: "about-us-14___de"
  }, {
    path: "/de/compare",
    component: _9b8cc324,
    meta: {"paths":{"en":"\u002Fcompare","lt":"\u002Fcompare","de":"\u002Fcompare","es":"\u002Fcompare","jp":"\u002Fcompare","ua":"\u002Fcompare"},"page_id":165},
    name: "compare-165___de"
  }, {
    path: "/de/glossary",
    component: _7f2d4265,
    meta: {"paths":{"en":"\u002Fglossary","lt":"\u002Fglossary","de":"\u002Fglossary","es":"\u002Fglossary","jp":"\u002Fglossary","ua":"\u002Fglossary"},"page_id":206},
    name: "glossary-206___de"
  }, {
    path: "/de/media-archive",
    component: _10463ea2,
    meta: {"paths":{"en":"\u002Fmedia-archive","lt":"\u002Fmedia-archive","de":"\u002Fmedia-archive","es":"\u002Fmedia-archive","jp":"\u002Fmedia-archive","ua":"\u002Fmedia-archive"},"page_id":162},
    name: "media-archive-162___de"
  }, {
    path: "/de/news",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","lt":"\u002Fnaujienos-1","de":"\u002Fnews","es":"\u002Fnews","jp":"\u002Fnews","ua":"\u002Fnews"},"page_id":7},
    name: "news-7___de"
  }, {
    path: "/de/newsroom",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","lt":"\u002Fnaujienos","de":"\u002Fnewsroom","es":"\u002Fnewsroom","jp":"\u002Fnewsroom","ua":"\u002Fnewsroom"},"page_id":8},
    name: "newsroom-8___de"
  }, {
    path: "/de/products",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts","lt":"\u002Fproduktai","de":"\u002Fproducts","es":"\u002Fproducts","jp":"\u002Fproducts","ua":"\u002Fproducts"},"page_id":114},
    name: "products-114___de"
  }, {
    path: "/de/products-wrapper",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fproducts-wrapper","lt":"\u002Fproduktai-wrapper","de":"\u002Fproducts-wrapper","es":"\u002Fproducts-wrapper","jp":"\u002Fproducts-wrapper","ua":"\u002Fproducts-wrapper"},"page_id":54},
    name: "products-wrapper-54___de"
  }, {
    path: "/de/resources",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fresources","lt":"\u002Fresursai","de":"\u002Fresources","es":"\u002Fresources","jp":"\u002Fresources","ua":"\u002Fresources"},"page_id":28},
    name: "resources-28___de"
  }, {
    path: "/de/search",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","lt":"\u002Fpaieska","de":"\u002Fsearch","es":"\u002Fsearch","jp":"\u002Fsearch","ua":"\u002Fsearch"},"page_id":45},
    name: "search-45___de"
  }, {
    path: "/de/subscribe",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","lt":"\u002Fprenumeruok","de":"\u002Fsubscribe","es":"\u002Fsubscribe","jp":"\u002Fsubscribe","ua":"\u002Fsubscribe"},"page_id":103},
    name: "subscribe-103___de"
  }, {
    path: "/de/support-video-tutorials",
    component: _10463ea2,
    meta: {"paths":{"en":"\u002Fsupport-video-tutorials","lt":"\u002Fsupport-video-tutorials","de":"\u002Fsupport-video-tutorials","es":"\u002Fsupport-video-tutorials","jp":"\u002Fsupport-video-tutorials","ua":"\u002Fsupport-video-tutorials"},"page_id":171},
    name: "support-video-tutorials-171___de"
  }, {
    path: "/de/t-mobile",
    component: _89a5fc3c,
    meta: {"paths":{"en":"\u002Ft-mobile","lt":"\u002Ft-mobile","de":"\u002Ft-mobile","es":"\u002Ft-mobile","jp":"\u002Ft-mobile","ua":"\u002Ft-mobile"},"page_id":196},
    name: "t-mobile-196___de"
  }, {
    path: "/de/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___de"
  }, {
    path: "/de/use-cases",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases","lt":"\u002Fvisi-panaudojimai","de":"\u002Fuse-cases","es":"\u002Fuse-cases","jp":"\u002Fuse-cases","ua":"\u002Fuse-cases"},"page_id":96},
    name: "use-cases-96___de"
  }, {
    path: "/es/about-us",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","lt":"\u002Fapie-mus","de":"\u002Fabout-us","es":"\u002Fabout-us","jp":"\u002Fabout-us","ua":"\u002Fabout-us"},"page_id":14},
    name: "about-us-14___es"
  }, {
    path: "/es/compare",
    component: _9b8cc324,
    meta: {"paths":{"en":"\u002Fcompare","lt":"\u002Fcompare","de":"\u002Fcompare","es":"\u002Fcompare","jp":"\u002Fcompare","ua":"\u002Fcompare"},"page_id":165},
    name: "compare-165___es"
  }, {
    path: "/es/glossary",
    component: _7f2d4265,
    meta: {"paths":{"en":"\u002Fglossary","lt":"\u002Fglossary","de":"\u002Fglossary","es":"\u002Fglossary","jp":"\u002Fglossary","ua":"\u002Fglossary"},"page_id":206},
    name: "glossary-206___es"
  }, {
    path: "/es/media-archive",
    component: _10463ea2,
    meta: {"paths":{"en":"\u002Fmedia-archive","lt":"\u002Fmedia-archive","de":"\u002Fmedia-archive","es":"\u002Fmedia-archive","jp":"\u002Fmedia-archive","ua":"\u002Fmedia-archive"},"page_id":162},
    name: "media-archive-162___es"
  }, {
    path: "/es/news",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","lt":"\u002Fnaujienos-1","de":"\u002Fnews","es":"\u002Fnews","jp":"\u002Fnews","ua":"\u002Fnews"},"page_id":7},
    name: "news-7___es"
  }, {
    path: "/es/newsroom",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","lt":"\u002Fnaujienos","de":"\u002Fnewsroom","es":"\u002Fnewsroom","jp":"\u002Fnewsroom","ua":"\u002Fnewsroom"},"page_id":8},
    name: "newsroom-8___es"
  }, {
    path: "/es/products",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts","lt":"\u002Fproduktai","de":"\u002Fproducts","es":"\u002Fproducts","jp":"\u002Fproducts","ua":"\u002Fproducts"},"page_id":114},
    name: "products-114___es"
  }, {
    path: "/es/products-wrapper",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fproducts-wrapper","lt":"\u002Fproduktai-wrapper","de":"\u002Fproducts-wrapper","es":"\u002Fproducts-wrapper","jp":"\u002Fproducts-wrapper","ua":"\u002Fproducts-wrapper"},"page_id":54},
    name: "products-wrapper-54___es"
  }, {
    path: "/es/resources",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fresources","lt":"\u002Fresursai","de":"\u002Fresources","es":"\u002Fresources","jp":"\u002Fresources","ua":"\u002Fresources"},"page_id":28},
    name: "resources-28___es"
  }, {
    path: "/es/search",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","lt":"\u002Fpaieska","de":"\u002Fsearch","es":"\u002Fsearch","jp":"\u002Fsearch","ua":"\u002Fsearch"},"page_id":45},
    name: "search-45___es"
  }, {
    path: "/es/subscribe",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","lt":"\u002Fprenumeruok","de":"\u002Fsubscribe","es":"\u002Fsubscribe","jp":"\u002Fsubscribe","ua":"\u002Fsubscribe"},"page_id":103},
    name: "subscribe-103___es"
  }, {
    path: "/es/support-video-tutorials",
    component: _10463ea2,
    meta: {"paths":{"en":"\u002Fsupport-video-tutorials","lt":"\u002Fsupport-video-tutorials","de":"\u002Fsupport-video-tutorials","es":"\u002Fsupport-video-tutorials","jp":"\u002Fsupport-video-tutorials","ua":"\u002Fsupport-video-tutorials"},"page_id":171},
    name: "support-video-tutorials-171___es"
  }, {
    path: "/es/t-mobile",
    component: _89a5fc3c,
    meta: {"paths":{"en":"\u002Ft-mobile","lt":"\u002Ft-mobile","de":"\u002Ft-mobile","es":"\u002Ft-mobile","jp":"\u002Ft-mobile","ua":"\u002Ft-mobile"},"page_id":196},
    name: "t-mobile-196___es"
  }, {
    path: "/es/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___es"
  }, {
    path: "/es/use-cases",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases","lt":"\u002Fvisi-panaudojimai","de":"\u002Fuse-cases","es":"\u002Fuse-cases","jp":"\u002Fuse-cases","ua":"\u002Fuse-cases"},"page_id":96},
    name: "use-cases-96___es"
  }, {
    path: "/jp/about-us",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","lt":"\u002Fapie-mus","de":"\u002Fabout-us","es":"\u002Fabout-us","jp":"\u002Fabout-us","ua":"\u002Fabout-us"},"page_id":14},
    name: "about-us-14___jp"
  }, {
    path: "/jp/compare",
    component: _9b8cc324,
    meta: {"paths":{"en":"\u002Fcompare","lt":"\u002Fcompare","de":"\u002Fcompare","es":"\u002Fcompare","jp":"\u002Fcompare","ua":"\u002Fcompare"},"page_id":165},
    name: "compare-165___jp"
  }, {
    path: "/jp/glossary",
    component: _7f2d4265,
    meta: {"paths":{"en":"\u002Fglossary","lt":"\u002Fglossary","de":"\u002Fglossary","es":"\u002Fglossary","jp":"\u002Fglossary","ua":"\u002Fglossary"},"page_id":206},
    name: "glossary-206___jp"
  }, {
    path: "/jp/media-archive",
    component: _10463ea2,
    meta: {"paths":{"en":"\u002Fmedia-archive","lt":"\u002Fmedia-archive","de":"\u002Fmedia-archive","es":"\u002Fmedia-archive","jp":"\u002Fmedia-archive","ua":"\u002Fmedia-archive"},"page_id":162},
    name: "media-archive-162___jp"
  }, {
    path: "/jp/news",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","lt":"\u002Fnaujienos-1","de":"\u002Fnews","es":"\u002Fnews","jp":"\u002Fnews","ua":"\u002Fnews"},"page_id":7},
    name: "news-7___jp"
  }, {
    path: "/jp/newsroom",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","lt":"\u002Fnaujienos","de":"\u002Fnewsroom","es":"\u002Fnewsroom","jp":"\u002Fnewsroom","ua":"\u002Fnewsroom"},"page_id":8},
    name: "newsroom-8___jp"
  }, {
    path: "/jp/products",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts","lt":"\u002Fproduktai","de":"\u002Fproducts","es":"\u002Fproducts","jp":"\u002Fproducts","ua":"\u002Fproducts"},"page_id":114},
    name: "products-114___jp"
  }, {
    path: "/jp/products-wrapper",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fproducts-wrapper","lt":"\u002Fproduktai-wrapper","de":"\u002Fproducts-wrapper","es":"\u002Fproducts-wrapper","jp":"\u002Fproducts-wrapper","ua":"\u002Fproducts-wrapper"},"page_id":54},
    name: "products-wrapper-54___jp"
  }, {
    path: "/jp/resources",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fresources","lt":"\u002Fresursai","de":"\u002Fresources","es":"\u002Fresources","jp":"\u002Fresources","ua":"\u002Fresources"},"page_id":28},
    name: "resources-28___jp"
  }, {
    path: "/jp/search",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","lt":"\u002Fpaieska","de":"\u002Fsearch","es":"\u002Fsearch","jp":"\u002Fsearch","ua":"\u002Fsearch"},"page_id":45},
    name: "search-45___jp"
  }, {
    path: "/jp/subscribe",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","lt":"\u002Fprenumeruok","de":"\u002Fsubscribe","es":"\u002Fsubscribe","jp":"\u002Fsubscribe","ua":"\u002Fsubscribe"},"page_id":103},
    name: "subscribe-103___jp"
  }, {
    path: "/jp/support-video-tutorials",
    component: _10463ea2,
    meta: {"paths":{"en":"\u002Fsupport-video-tutorials","lt":"\u002Fsupport-video-tutorials","de":"\u002Fsupport-video-tutorials","es":"\u002Fsupport-video-tutorials","jp":"\u002Fsupport-video-tutorials","ua":"\u002Fsupport-video-tutorials"},"page_id":171},
    name: "support-video-tutorials-171___jp"
  }, {
    path: "/jp/t-mobile",
    component: _89a5fc3c,
    meta: {"paths":{"en":"\u002Ft-mobile","lt":"\u002Ft-mobile","de":"\u002Ft-mobile","es":"\u002Ft-mobile","jp":"\u002Ft-mobile","ua":"\u002Ft-mobile"},"page_id":196},
    name: "t-mobile-196___jp"
  }, {
    path: "/jp/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___jp"
  }, {
    path: "/jp/use-cases",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases","lt":"\u002Fvisi-panaudojimai","de":"\u002Fuse-cases","es":"\u002Fuse-cases","jp":"\u002Fuse-cases","ua":"\u002Fuse-cases"},"page_id":96},
    name: "use-cases-96___jp"
  }, {
    path: "/lt/apie-mus",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","lt":"\u002Fapie-mus","de":"\u002Fabout-us","es":"\u002Fabout-us","jp":"\u002Fabout-us","ua":"\u002Fabout-us"},"page_id":14},
    name: "about-us-14___lt"
  }, {
    path: "/lt/compare",
    component: _9b8cc324,
    meta: {"paths":{"en":"\u002Fcompare","lt":"\u002Fcompare","de":"\u002Fcompare","es":"\u002Fcompare","jp":"\u002Fcompare","ua":"\u002Fcompare"},"page_id":165},
    name: "compare-165___lt"
  }, {
    path: "/lt/glossary",
    component: _7f2d4265,
    meta: {"paths":{"en":"\u002Fglossary","lt":"\u002Fglossary","de":"\u002Fglossary","es":"\u002Fglossary","jp":"\u002Fglossary","ua":"\u002Fglossary"},"page_id":206},
    name: "glossary-206___lt"
  }, {
    path: "/lt/media-archive",
    component: _10463ea2,
    meta: {"paths":{"en":"\u002Fmedia-archive","lt":"\u002Fmedia-archive","de":"\u002Fmedia-archive","es":"\u002Fmedia-archive","jp":"\u002Fmedia-archive","ua":"\u002Fmedia-archive"},"page_id":162},
    name: "media-archive-162___lt"
  }, {
    path: "/lt/naujienos-1",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","lt":"\u002Fnaujienos-1","de":"\u002Fnews","es":"\u002Fnews","jp":"\u002Fnews","ua":"\u002Fnews"},"page_id":7},
    name: "news-7___lt"
  }, {
    path: "/lt/naujienos",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","lt":"\u002Fnaujienos","de":"\u002Fnewsroom","es":"\u002Fnewsroom","jp":"\u002Fnewsroom","ua":"\u002Fnewsroom"},"page_id":8},
    name: "newsroom-8___lt"
  }, {
    path: "/lt/produktai",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts","lt":"\u002Fproduktai","de":"\u002Fproducts","es":"\u002Fproducts","jp":"\u002Fproducts","ua":"\u002Fproducts"},"page_id":114},
    name: "products-114___lt"
  }, {
    path: "/lt/produktai-wrapper",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fproducts-wrapper","lt":"\u002Fproduktai-wrapper","de":"\u002Fproducts-wrapper","es":"\u002Fproducts-wrapper","jp":"\u002Fproducts-wrapper","ua":"\u002Fproducts-wrapper"},"page_id":54},
    name: "products-wrapper-54___lt"
  }, {
    path: "/lt/resursai",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fresources","lt":"\u002Fresursai","de":"\u002Fresources","es":"\u002Fresources","jp":"\u002Fresources","ua":"\u002Fresources"},"page_id":28},
    name: "resources-28___lt"
  }, {
    path: "/lt/paieska",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","lt":"\u002Fpaieska","de":"\u002Fsearch","es":"\u002Fsearch","jp":"\u002Fsearch","ua":"\u002Fsearch"},"page_id":45},
    name: "search-45___lt"
  }, {
    path: "/lt/prenumeruok",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","lt":"\u002Fprenumeruok","de":"\u002Fsubscribe","es":"\u002Fsubscribe","jp":"\u002Fsubscribe","ua":"\u002Fsubscribe"},"page_id":103},
    name: "subscribe-103___lt"
  }, {
    path: "/lt/support-video-tutorials",
    component: _10463ea2,
    meta: {"paths":{"en":"\u002Fsupport-video-tutorials","lt":"\u002Fsupport-video-tutorials","de":"\u002Fsupport-video-tutorials","es":"\u002Fsupport-video-tutorials","jp":"\u002Fsupport-video-tutorials","ua":"\u002Fsupport-video-tutorials"},"page_id":171},
    name: "support-video-tutorials-171___lt"
  }, {
    path: "/lt/t-mobile",
    component: _89a5fc3c,
    meta: {"paths":{"en":"\u002Ft-mobile","lt":"\u002Ft-mobile","de":"\u002Ft-mobile","es":"\u002Ft-mobile","jp":"\u002Ft-mobile","ua":"\u002Ft-mobile"},"page_id":196},
    name: "t-mobile-196___lt"
  }, {
    path: "/lt/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___lt"
  }, {
    path: "/lt/visi-panaudojimai",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases","lt":"\u002Fvisi-panaudojimai","de":"\u002Fuse-cases","es":"\u002Fuse-cases","jp":"\u002Fuse-cases","ua":"\u002Fuse-cases"},"page_id":96},
    name: "use-cases-96___lt"
  }, {
    path: "/products/access-points",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccess-points","lt":"\u002Fproduktai\u002Faccess-points","de":"\u002Fproducts\u002Faccess-points","es":"\u002Fproducts\u002Faccess-points","jp":"\u002Fproducts\u002Faccess-points","ua":"\u002Fproducts\u002Faccess-points"},"page_id":182},
    name: "products-access-points-182___en"
  }, {
    path: "/products/accessories",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories","lt":"\u002Fproduktai\u002Fpriedai","de":"\u002Fproducts\u002Faccessories","es":"\u002Fproducts\u002Faccessories","jp":"\u002Fproducts\u002Faccessories","ua":"\u002Fproducts\u002Faccessories"},"page_id":116},
    name: "products-accessories-116___en"
  }, {
    path: "/products/cellular-iot",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fcellular-iot","lt":"\u002Fproduktai\u002Fmobilus-iot","de":"\u002Fproducts\u002Fcellular-iot","es":"\u002Fproducts\u002Fcellular-iot","jp":"\u002Fproducts\u002Fcellular-iot","ua":"\u002Fproducts\u002Fcellular-iot"},"page_id":110},
    name: "products-cellular-iot-110___en"
  }, {
    path: "/products/custom-products",
    component: _66cb1dcc,
    meta: {"paths":{"en":"\u002Fproducts\u002Fcustom-products","lt":"\u002Fproduktai\u002Funikalus-produktai","de":"\u002Fproducts\u002Fcustom-products","es":"\u002Fproducts\u002Fcustom-products","jp":"\u002Fproducts\u002Fcustom-products","ua":"\u002Fproducts\u002Fcustom-products"},"page_id":111},
    name: "products-custom-products-111___en"
  }, {
    path: "/products/ethernet-switches",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-switches","lt":"\u002Fproduktai\u002Fsakotuvai","de":"\u002Fproducts\u002Fethernet-switches","es":"\u002Fproducts\u002Fethernet-switches","jp":"\u002Fproducts\u002Fethernet-switches","ua":"\u002Fproducts\u002Fethernet-switches"},"page_id":120},
    name: "products-ethernet-switches-120___en"
  }, {
    path: "/products/ethernet-wireless",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys","de":"\u002Fproducts\u002Fethernet-wireless","es":"\u002Fproducts\u002Fethernet-wireless","jp":"\u002Fproducts\u002Fethernet-wireless","ua":"\u002Fproducts\u002Fethernet-wireless"},"page_id":117},
    name: "products-ethernet-wireless-117___en"
  }, {
    path: "/products/gateways",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fgateways","lt":"\u002Fproduktai\u002Ftinklu-sietuvai","de":"\u002Fproducts\u002Fgateways","es":"\u002Fproducts\u002Fgateways","jp":"\u002Fproducts\u002Fgateways","ua":"\u002Fproducts\u002Fgateways"},"page_id":113},
    name: "products-gateways-113___en"
  }, {
    path: "/products/iot-platforms",
    component: _737c122a,
    meta: {"paths":{"en":"\u002Fproducts\u002Fiot-platforms","lt":"\u002Fproduktai\u002Fiot-platforms","de":"\u002Fproducts\u002Fiot-platforms","es":"\u002Fproducts\u002Fiot-platforms","jp":"\u002Fproducts\u002Fiot-platforms","ua":"\u002Fproducts\u002Fiot-platforms"},"page_id":121},
    name: "products-iot-platforms-121___en"
  }, {
    path: "/products/modems",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fmodems","lt":"\u002Fproduktai\u002Fmodemai","de":"\u002Fproducts\u002Fmodems","es":"\u002Fproducts\u002Fmodems","jp":"\u002Fproducts\u002Fmodems","ua":"\u002Fproducts\u002Fmodems"},"page_id":112},
    name: "products-modems-112___en"
  }, {
    path: "/products/routers",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters","lt":"\u002Fproduktai\u002Fmarsrutizatoriai","de":"\u002Fproducts\u002Frouters","es":"\u002Fproducts\u002Frouters","jp":"\u002Fproducts\u002Frouters","ua":"\u002Fproducts\u002Frouters"},"page_id":115},
    name: "products-routers-115___en"
  }, {
    path: "/resources/blogs",
    component: _2ae9892c,
    meta: {"paths":{"en":"\u002Fresources\u002Fblogs","lt":"\u002Fresursai\u002Fblogs","de":"\u002Fresources\u002Fblogs","es":"\u002Fresources\u002Fblogs","jp":"\u002Fresources\u002Fblogs","ua":"\u002Fresources\u002Fblogs"},"page_id":124},
    name: "resources-blogs-124___en"
  }, {
    path: "/resources/certification-program",
    component: _99d23f9e,
    meta: {"paths":{"en":"\u002Fresources\u002Fcertification-program","lt":"\u002Fresursai\u002Fsertifikavimo-programa","de":"\u002Fresources\u002Fcertification-program","es":"\u002Fresources\u002Fcertification-program","jp":"\u002Fresources\u002Fcertification-program","ua":"\u002Fresources\u002Fcertification-program"},"page_id":102},
    name: "resources-certification-program-102___en"
  }, {
    path: "/resources/share-your-solution",
    component: _a54c8f24,
    meta: {"paths":{"en":"\u002Fresources\u002Fshare-your-solution","lt":"\u002Fresursai\u002Fpasidalinkite-savo-sprendimu","de":"\u002Fresources\u002Fshare-your-solution","es":"\u002Fresources\u002Fshare-your-solution","jp":"\u002Fresources\u002Fshare-your-solution","ua":"\u002Fresources\u002Fshare-your-solution"},"page_id":109},
    name: "resources-share-your-solution-109___en"
  }, {
    path: "/resources/videos",
    component: _09273272,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos","lt":"\u002Fresursai\u002Fvaizdo-irasai","de":"\u002Fresources\u002Fvideos","es":"\u002Fresources\u002Fvideos","jp":"\u002Fresources\u002Fvideos","ua":"\u002Fresources\u002Fvideos"},"page_id":101},
    name: "resources-videos-101___en"
  }, {
    path: "/resources/webinars",
    component: _2e232d93,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars","lt":"\u002Fresursai\u002Fwebinarai","de":"\u002Fresources\u002Fwebinars","es":"\u002Fresources\u002Fwebinars","jp":"\u002Fresources\u002Fwebinars","ua":"\u002Fresources\u002Fwebinars"},"page_id":179},
    name: "resources-webinars-179___en"
  }, {
    path: "/support/eol-products",
    component: _c25f0f92,
    meta: {"paths":{"en":"\u002Fsupport\u002Feol-products","lt":"\u002Faptarnavimas\u002Fproduktu-gyvavimo-ciklo-pabaiga","de":"\u002Fsupport\u002Feol-products","es":"\u002Fsupport\u002Feol-products","jp":"\u002Fsupport\u002Feol-products","ua":"\u002Fsupport\u002Feol-products"},"page_id":107},
    name: "support-eol-products-107___en"
  }, {
    path: "/support/gpl-code-center",
    component: _6eb69794,
    meta: {"paths":{"en":"\u002Fsupport\u002Fgpl-code-center","lt":"\u002Faptarnavimas\u002Fgpl-atvirasis-kodas","de":"\u002Fsupport\u002Fgpl-code-center","es":"\u002Fsupport\u002Fgpl-code-center","jp":"\u002Fsupport\u002Fgpl-code-center","ua":"\u002Fsupport\u002Fgpl-code-center"},"page_id":108},
    name: "support-gpl-code-center-108___en"
  }, {
    path: "/support/product-support",
    component: _5c755c58,
    meta: {"paths":{"en":"\u002Fsupport\u002Fproduct-support","lt":"\u002Faptarnavimas\u002Fprodukto-aptarnavimas","de":"\u002Fsupport\u002Fproduct-support","es":"\u002Fsupport\u002Fproduct-support","jp":"\u002Fsupport\u002Fproduct-support","ua":"\u002Fsupport\u002Fproduct-support"},"page_id":105},
    name: "support-product-support-105___en"
  }, {
    path: "/support/security-centre",
    component: _626335ec,
    meta: {"paths":{"en":"\u002Fsupport\u002Fsecurity-centre","lt":"\u002Faptarnavimas\u002Fsaugumo-centras","de":"\u002Fsupport\u002Fsecurity-centre","es":"\u002Fsupport\u002Fsecurity-centre","jp":"\u002Fsupport\u002Fsecurity-centre","ua":"\u002Fsupport\u002Fsecurity-centre"},"page_id":99},
    name: "support-security-centre-99___en"
  }, {
    path: "/support/warranty-repair",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fsupport\u002Fwarranty-repair","lt":"\u002Faptarnavimas\u002Fgarantija-ir-remontas-1","de":"\u002Fsupport\u002Fgarantie-und-reparatur","es":"\u002Fsupport\u002Fgarantia-y-reparaciones","jp":"\u002Fsupport\u002Fwarranty-repair","ua":"\u002Fsupport\u002Fgarantiia-ta-remont"},"page_id":75},
    name: "support-warranty-repair-75___en"
  }, {
    path: "/ua/about-us",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fabout-us","lt":"\u002Fapie-mus","de":"\u002Fabout-us","es":"\u002Fabout-us","jp":"\u002Fabout-us","ua":"\u002Fabout-us"},"page_id":14},
    name: "about-us-14___ua"
  }, {
    path: "/ua/compare",
    component: _9b8cc324,
    meta: {"paths":{"en":"\u002Fcompare","lt":"\u002Fcompare","de":"\u002Fcompare","es":"\u002Fcompare","jp":"\u002Fcompare","ua":"\u002Fcompare"},"page_id":165},
    name: "compare-165___ua"
  }, {
    path: "/ua/glossary",
    component: _7f2d4265,
    meta: {"paths":{"en":"\u002Fglossary","lt":"\u002Fglossary","de":"\u002Fglossary","es":"\u002Fglossary","jp":"\u002Fglossary","ua":"\u002Fglossary"},"page_id":206},
    name: "glossary-206___ua"
  }, {
    path: "/ua/media-archive",
    component: _10463ea2,
    meta: {"paths":{"en":"\u002Fmedia-archive","lt":"\u002Fmedia-archive","de":"\u002Fmedia-archive","es":"\u002Fmedia-archive","jp":"\u002Fmedia-archive","ua":"\u002Fmedia-archive"},"page_id":162},
    name: "media-archive-162___ua"
  }, {
    path: "/ua/news",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fnews","lt":"\u002Fnaujienos-1","de":"\u002Fnews","es":"\u002Fnews","jp":"\u002Fnews","ua":"\u002Fnews"},"page_id":7},
    name: "news-7___ua"
  }, {
    path: "/ua/newsroom",
    component: _73ff5b1d,
    meta: {"paths":{"en":"\u002Fnewsroom","lt":"\u002Fnaujienos","de":"\u002Fnewsroom","es":"\u002Fnewsroom","jp":"\u002Fnewsroom","ua":"\u002Fnewsroom"},"page_id":8},
    name: "newsroom-8___ua"
  }, {
    path: "/ua/products",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts","lt":"\u002Fproduktai","de":"\u002Fproducts","es":"\u002Fproducts","jp":"\u002Fproducts","ua":"\u002Fproducts"},"page_id":114},
    name: "products-114___ua"
  }, {
    path: "/ua/products-wrapper",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fproducts-wrapper","lt":"\u002Fproduktai-wrapper","de":"\u002Fproducts-wrapper","es":"\u002Fproducts-wrapper","jp":"\u002Fproducts-wrapper","ua":"\u002Fproducts-wrapper"},"page_id":54},
    name: "products-wrapper-54___ua"
  }, {
    path: "/ua/resources",
    component: _41c45f1c,
    meta: {"paths":{"en":"\u002Fresources","lt":"\u002Fresursai","de":"\u002Fresources","es":"\u002Fresources","jp":"\u002Fresources","ua":"\u002Fresources"},"page_id":28},
    name: "resources-28___ua"
  }, {
    path: "/ua/search",
    component: _2673e8af,
    meta: {"paths":{"en":"\u002Fsearch","lt":"\u002Fpaieska","de":"\u002Fsearch","es":"\u002Fsearch","jp":"\u002Fsearch","ua":"\u002Fsearch"},"page_id":45},
    name: "search-45___ua"
  }, {
    path: "/ua/subscribe",
    component: _928d5fe4,
    meta: {"paths":{"en":"\u002Fsubscribe","lt":"\u002Fprenumeruok","de":"\u002Fsubscribe","es":"\u002Fsubscribe","jp":"\u002Fsubscribe","ua":"\u002Fsubscribe"},"page_id":103},
    name: "subscribe-103___ua"
  }, {
    path: "/ua/support-video-tutorials",
    component: _10463ea2,
    meta: {"paths":{"en":"\u002Fsupport-video-tutorials","lt":"\u002Fsupport-video-tutorials","de":"\u002Fsupport-video-tutorials","es":"\u002Fsupport-video-tutorials","jp":"\u002Fsupport-video-tutorials","ua":"\u002Fsupport-video-tutorials"},"page_id":171},
    name: "support-video-tutorials-171___ua"
  }, {
    path: "/ua/t-mobile",
    component: _89a5fc3c,
    meta: {"paths":{"en":"\u002Ft-mobile","lt":"\u002Ft-mobile","de":"\u002Ft-mobile","es":"\u002Ft-mobile","jp":"\u002Ft-mobile","ua":"\u002Ft-mobile"},"page_id":196},
    name: "t-mobile-196___ua"
  }, {
    path: "/ua/translations",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Ftranslations"}},
    name: "defaultContent___ua"
  }, {
    path: "/ua/use-cases",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases","lt":"\u002Fvisi-panaudojimai","de":"\u002Fuse-cases","es":"\u002Fuse-cases","jp":"\u002Fuse-cases","ua":"\u002Fuse-cases"},"page_id":96},
    name: "use-cases-96___ua"
  }, {
    path: "/use-cases/energy-utilities",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenergy-utilities","lt":"\u002Fvisi-panaudojimai\u002Fenergetika","de":"\u002Fuse-cases\u002Fenergy-utilities","es":"\u002Fuse-cases\u002Fenergy-utilities","jp":"\u002Fuse-cases\u002Fenergy-utilities","ua":"\u002Fuse-cases\u002Fenergy-utilities"},"page_id":91},
    name: "use-cases-energy-utilities-91___en"
  }, {
    path: "/use-cases/enterprise",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenterprise","lt":"\u002Fvisi-panaudojimai\u002Fverslo-sektorius","de":"\u002Fuse-cases\u002Fenterprise","es":"\u002Fuse-cases\u002Fenterprise","jp":"\u002Fuse-cases\u002Fenterprise","ua":"\u002Fuse-cases\u002Fenterprise"},"page_id":94},
    name: "use-cases-enterprise-94___en"
  }, {
    path: "/use-cases/industrial-automation",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Findustrial-automation","lt":"\u002Fvisi-panaudojimai\u002Fautomatizavimas","de":"\u002Fuse-cases\u002Findustrial-automation","es":"\u002Fuse-cases\u002Findustrial-automation","jp":"\u002Fuse-cases\u002Findustrial-automation","ua":"\u002Fuse-cases\u002Findustrial-automation"},"page_id":90},
    name: "use-cases-industrial-automation-90___en"
  }, {
    path: "/use-cases/retail",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fretail","lt":"\u002Fvisi-panaudojimai\u002Fprekybos-sektorius","de":"\u002Fuse-cases\u002Fretail","es":"\u002Fuse-cases\u002Fretail","jp":"\u002Fuse-cases\u002Fretail","ua":"\u002Fuse-cases\u002Fretail"},"page_id":95},
    name: "use-cases-retail-95___en"
  }, {
    path: "/use-cases/smart-city",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fsmart-city","lt":"\u002Fvisi-panaudojimai\u002Fismanus-miestas","de":"\u002Fuse-cases\u002Fsmart-city","es":"\u002Fuse-cases\u002Fsmart-city","jp":"\u002Fuse-cases\u002Fsmart-city","ua":"\u002Fuse-cases\u002Fsmart-city"},"page_id":92},
    name: "use-cases-smart-city-92___en"
  }, {
    path: "/use-cases/transportation",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftransportation","lt":"\u002Fvisi-panaudojimai\u002Ftransportas","de":"\u002Fuse-cases\u002Ftransportation","es":"\u002Fuse-cases\u002Ftransportation","jp":"\u002Fuse-cases\u002Ftransportation","ua":"\u002Fuse-cases\u002Ftransportation"},"page_id":93},
    name: "use-cases-transportation-93___en"
  }, {
    path: "/de/about-us/career",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcareer","lt":"\u002Fapie-mus\u002Fkarjera","de":"\u002Fabout-us\u002Fcareer","es":"\u002Fabout-us\u002Fcareer","jp":"\u002Fabout-us\u002Fcareer","ua":"\u002Fabout-us\u002Fcareer"},"page_id":21},
    name: "about-us-career-21___de"
  }, {
    path: "/de/about-us/contacts",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai","de":"\u002Fabout-us\u002Fcontacts","es":"\u002Fabout-us\u002Fcontacts","jp":"\u002Fabout-us\u002Fcontacts","ua":"\u002Fabout-us\u002Fcontacts"},"page_id":19},
    name: "about-us-contacts-19___de"
  }, {
    path: "/de/about-us/mission-vision-values",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes","de":"\u002Fabout-us\u002Fmission-vision-values","es":"\u002Fabout-us\u002Fmission-vision-values","jp":"\u002Fabout-us\u002Fmission-vision-values","ua":"\u002Fabout-us\u002Fmission-vision-values"},"page_id":16},
    name: "about-us-mission-vision-values-16___de"
  }, {
    path: "/de/about-us/policies-certificates",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai","de":"\u002Fabout-us\u002Fpolicies-certificates","es":"\u002Fabout-us\u002Fpolicies-certificates","jp":"\u002Fabout-us\u002Fpolicies-certificates","ua":"\u002Fabout-us\u002Fpolicies-certificates"},"page_id":18},
    name: "about-us-policies-certificates-18___de"
  }, {
    path: "/de/about-us/who-we-are",
    component: _61180b77,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fwho-we-are","lt":"\u002Fapie-mus\u002Fkas-mes-esame","de":"\u002Fabout-us\u002Fwho-we-are","es":"\u002Fabout-us\u002Fwho-we-are","jp":"\u002Fabout-us\u002Fwho-we-are","ua":"\u002Fabout-us\u002Fwho-we-are"},"page_id":104},
    name: "about-us-who-we-are-104___de"
  }, {
    path: "/de/products/access-points",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccess-points","lt":"\u002Fproduktai\u002Faccess-points","de":"\u002Fproducts\u002Faccess-points","es":"\u002Fproducts\u002Faccess-points","jp":"\u002Fproducts\u002Faccess-points","ua":"\u002Fproducts\u002Faccess-points"},"page_id":182},
    name: "products-access-points-182___de"
  }, {
    path: "/de/products/accessories",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories","lt":"\u002Fproduktai\u002Fpriedai","de":"\u002Fproducts\u002Faccessories","es":"\u002Fproducts\u002Faccessories","jp":"\u002Fproducts\u002Faccessories","ua":"\u002Fproducts\u002Faccessories"},"page_id":116},
    name: "products-accessories-116___de"
  }, {
    path: "/de/products/cellular-iot",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fcellular-iot","lt":"\u002Fproduktai\u002Fmobilus-iot","de":"\u002Fproducts\u002Fcellular-iot","es":"\u002Fproducts\u002Fcellular-iot","jp":"\u002Fproducts\u002Fcellular-iot","ua":"\u002Fproducts\u002Fcellular-iot"},"page_id":110},
    name: "products-cellular-iot-110___de"
  }, {
    path: "/de/products/custom-products",
    component: _66cb1dcc,
    meta: {"paths":{"en":"\u002Fproducts\u002Fcustom-products","lt":"\u002Fproduktai\u002Funikalus-produktai","de":"\u002Fproducts\u002Fcustom-products","es":"\u002Fproducts\u002Fcustom-products","jp":"\u002Fproducts\u002Fcustom-products","ua":"\u002Fproducts\u002Fcustom-products"},"page_id":111},
    name: "products-custom-products-111___de"
  }, {
    path: "/de/products/ethernet-switches",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-switches","lt":"\u002Fproduktai\u002Fsakotuvai","de":"\u002Fproducts\u002Fethernet-switches","es":"\u002Fproducts\u002Fethernet-switches","jp":"\u002Fproducts\u002Fethernet-switches","ua":"\u002Fproducts\u002Fethernet-switches"},"page_id":120},
    name: "products-ethernet-switches-120___de"
  }, {
    path: "/de/products/ethernet-wireless",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys","de":"\u002Fproducts\u002Fethernet-wireless","es":"\u002Fproducts\u002Fethernet-wireless","jp":"\u002Fproducts\u002Fethernet-wireless","ua":"\u002Fproducts\u002Fethernet-wireless"},"page_id":117},
    name: "products-ethernet-wireless-117___de"
  }, {
    path: "/de/products/gateways",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fgateways","lt":"\u002Fproduktai\u002Ftinklu-sietuvai","de":"\u002Fproducts\u002Fgateways","es":"\u002Fproducts\u002Fgateways","jp":"\u002Fproducts\u002Fgateways","ua":"\u002Fproducts\u002Fgateways"},"page_id":113},
    name: "products-gateways-113___de"
  }, {
    path: "/de/products/iot-platforms",
    component: _737c122a,
    meta: {"paths":{"en":"\u002Fproducts\u002Fiot-platforms","lt":"\u002Fproduktai\u002Fiot-platforms","de":"\u002Fproducts\u002Fiot-platforms","es":"\u002Fproducts\u002Fiot-platforms","jp":"\u002Fproducts\u002Fiot-platforms","ua":"\u002Fproducts\u002Fiot-platforms"},"page_id":121},
    name: "products-iot-platforms-121___de"
  }, {
    path: "/de/products/modems",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fmodems","lt":"\u002Fproduktai\u002Fmodemai","de":"\u002Fproducts\u002Fmodems","es":"\u002Fproducts\u002Fmodems","jp":"\u002Fproducts\u002Fmodems","ua":"\u002Fproducts\u002Fmodems"},"page_id":112},
    name: "products-modems-112___de"
  }, {
    path: "/de/products/routers",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters","lt":"\u002Fproduktai\u002Fmarsrutizatoriai","de":"\u002Fproducts\u002Frouters","es":"\u002Fproducts\u002Frouters","jp":"\u002Fproducts\u002Frouters","ua":"\u002Fproducts\u002Frouters"},"page_id":115},
    name: "products-routers-115___de"
  }, {
    path: "/de/resources/blogs",
    component: _2ae9892c,
    meta: {"paths":{"en":"\u002Fresources\u002Fblogs","lt":"\u002Fresursai\u002Fblogs","de":"\u002Fresources\u002Fblogs","es":"\u002Fresources\u002Fblogs","jp":"\u002Fresources\u002Fblogs","ua":"\u002Fresources\u002Fblogs"},"page_id":124},
    name: "resources-blogs-124___de"
  }, {
    path: "/de/resources/certification-program",
    component: _99d23f9e,
    meta: {"paths":{"en":"\u002Fresources\u002Fcertification-program","lt":"\u002Fresursai\u002Fsertifikavimo-programa","de":"\u002Fresources\u002Fcertification-program","es":"\u002Fresources\u002Fcertification-program","jp":"\u002Fresources\u002Fcertification-program","ua":"\u002Fresources\u002Fcertification-program"},"page_id":102},
    name: "resources-certification-program-102___de"
  }, {
    path: "/de/resources/share-your-solution",
    component: _a54c8f24,
    meta: {"paths":{"en":"\u002Fresources\u002Fshare-your-solution","lt":"\u002Fresursai\u002Fpasidalinkite-savo-sprendimu","de":"\u002Fresources\u002Fshare-your-solution","es":"\u002Fresources\u002Fshare-your-solution","jp":"\u002Fresources\u002Fshare-your-solution","ua":"\u002Fresources\u002Fshare-your-solution"},"page_id":109},
    name: "resources-share-your-solution-109___de"
  }, {
    path: "/de/resources/videos",
    component: _09273272,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos","lt":"\u002Fresursai\u002Fvaizdo-irasai","de":"\u002Fresources\u002Fvideos","es":"\u002Fresources\u002Fvideos","jp":"\u002Fresources\u002Fvideos","ua":"\u002Fresources\u002Fvideos"},"page_id":101},
    name: "resources-videos-101___de"
  }, {
    path: "/de/resources/webinars",
    component: _2e232d93,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars","lt":"\u002Fresursai\u002Fwebinarai","de":"\u002Fresources\u002Fwebinars","es":"\u002Fresources\u002Fwebinars","jp":"\u002Fresources\u002Fwebinars","ua":"\u002Fresources\u002Fwebinars"},"page_id":179},
    name: "resources-webinars-179___de"
  }, {
    path: "/de/support/eol-products",
    component: _c25f0f92,
    meta: {"paths":{"en":"\u002Fsupport\u002Feol-products","lt":"\u002Faptarnavimas\u002Fproduktu-gyvavimo-ciklo-pabaiga","de":"\u002Fsupport\u002Feol-products","es":"\u002Fsupport\u002Feol-products","jp":"\u002Fsupport\u002Feol-products","ua":"\u002Fsupport\u002Feol-products"},"page_id":107},
    name: "support-eol-products-107___de"
  }, {
    path: "/de/support/gpl-code-center",
    component: _6eb69794,
    meta: {"paths":{"en":"\u002Fsupport\u002Fgpl-code-center","lt":"\u002Faptarnavimas\u002Fgpl-atvirasis-kodas","de":"\u002Fsupport\u002Fgpl-code-center","es":"\u002Fsupport\u002Fgpl-code-center","jp":"\u002Fsupport\u002Fgpl-code-center","ua":"\u002Fsupport\u002Fgpl-code-center"},"page_id":108},
    name: "support-gpl-code-center-108___de"
  }, {
    path: "/de/support/product-support",
    component: _5c755c58,
    meta: {"paths":{"en":"\u002Fsupport\u002Fproduct-support","lt":"\u002Faptarnavimas\u002Fprodukto-aptarnavimas","de":"\u002Fsupport\u002Fproduct-support","es":"\u002Fsupport\u002Fproduct-support","jp":"\u002Fsupport\u002Fproduct-support","ua":"\u002Fsupport\u002Fproduct-support"},"page_id":105},
    name: "support-product-support-105___de"
  }, {
    path: "/de/support/security-centre",
    component: _626335ec,
    meta: {"paths":{"en":"\u002Fsupport\u002Fsecurity-centre","lt":"\u002Faptarnavimas\u002Fsaugumo-centras","de":"\u002Fsupport\u002Fsecurity-centre","es":"\u002Fsupport\u002Fsecurity-centre","jp":"\u002Fsupport\u002Fsecurity-centre","ua":"\u002Fsupport\u002Fsecurity-centre"},"page_id":99},
    name: "support-security-centre-99___de"
  }, {
    path: "/de/support/garantie-und-reparatur",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fsupport\u002Fwarranty-repair","lt":"\u002Faptarnavimas\u002Fgarantija-ir-remontas-1","de":"\u002Fsupport\u002Fgarantie-und-reparatur","es":"\u002Fsupport\u002Fgarantia-y-reparaciones","jp":"\u002Fsupport\u002Fwarranty-repair","ua":"\u002Fsupport\u002Fgarantiia-ta-remont"},"page_id":75},
    name: "support-warranty-repair-75___de"
  }, {
    path: "/de/use-cases/energy-utilities",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenergy-utilities","lt":"\u002Fvisi-panaudojimai\u002Fenergetika","de":"\u002Fuse-cases\u002Fenergy-utilities","es":"\u002Fuse-cases\u002Fenergy-utilities","jp":"\u002Fuse-cases\u002Fenergy-utilities","ua":"\u002Fuse-cases\u002Fenergy-utilities"},"page_id":91},
    name: "use-cases-energy-utilities-91___de"
  }, {
    path: "/de/use-cases/enterprise",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenterprise","lt":"\u002Fvisi-panaudojimai\u002Fverslo-sektorius","de":"\u002Fuse-cases\u002Fenterprise","es":"\u002Fuse-cases\u002Fenterprise","jp":"\u002Fuse-cases\u002Fenterprise","ua":"\u002Fuse-cases\u002Fenterprise"},"page_id":94},
    name: "use-cases-enterprise-94___de"
  }, {
    path: "/de/use-cases/industrial-automation",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Findustrial-automation","lt":"\u002Fvisi-panaudojimai\u002Fautomatizavimas","de":"\u002Fuse-cases\u002Findustrial-automation","es":"\u002Fuse-cases\u002Findustrial-automation","jp":"\u002Fuse-cases\u002Findustrial-automation","ua":"\u002Fuse-cases\u002Findustrial-automation"},"page_id":90},
    name: "use-cases-industrial-automation-90___de"
  }, {
    path: "/de/use-cases/retail",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fretail","lt":"\u002Fvisi-panaudojimai\u002Fprekybos-sektorius","de":"\u002Fuse-cases\u002Fretail","es":"\u002Fuse-cases\u002Fretail","jp":"\u002Fuse-cases\u002Fretail","ua":"\u002Fuse-cases\u002Fretail"},"page_id":95},
    name: "use-cases-retail-95___de"
  }, {
    path: "/de/use-cases/smart-city",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fsmart-city","lt":"\u002Fvisi-panaudojimai\u002Fismanus-miestas","de":"\u002Fuse-cases\u002Fsmart-city","es":"\u002Fuse-cases\u002Fsmart-city","jp":"\u002Fuse-cases\u002Fsmart-city","ua":"\u002Fuse-cases\u002Fsmart-city"},"page_id":92},
    name: "use-cases-smart-city-92___de"
  }, {
    path: "/de/use-cases/transportation",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftransportation","lt":"\u002Fvisi-panaudojimai\u002Ftransportas","de":"\u002Fuse-cases\u002Ftransportation","es":"\u002Fuse-cases\u002Ftransportation","jp":"\u002Fuse-cases\u002Ftransportation","ua":"\u002Fuse-cases\u002Ftransportation"},"page_id":93},
    name: "use-cases-transportation-93___de"
  }, {
    path: "/es/about-us/career",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcareer","lt":"\u002Fapie-mus\u002Fkarjera","de":"\u002Fabout-us\u002Fcareer","es":"\u002Fabout-us\u002Fcareer","jp":"\u002Fabout-us\u002Fcareer","ua":"\u002Fabout-us\u002Fcareer"},"page_id":21},
    name: "about-us-career-21___es"
  }, {
    path: "/es/about-us/contacts",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai","de":"\u002Fabout-us\u002Fcontacts","es":"\u002Fabout-us\u002Fcontacts","jp":"\u002Fabout-us\u002Fcontacts","ua":"\u002Fabout-us\u002Fcontacts"},"page_id":19},
    name: "about-us-contacts-19___es"
  }, {
    path: "/es/about-us/mission-vision-values",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes","de":"\u002Fabout-us\u002Fmission-vision-values","es":"\u002Fabout-us\u002Fmission-vision-values","jp":"\u002Fabout-us\u002Fmission-vision-values","ua":"\u002Fabout-us\u002Fmission-vision-values"},"page_id":16},
    name: "about-us-mission-vision-values-16___es"
  }, {
    path: "/es/about-us/policies-certificates",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai","de":"\u002Fabout-us\u002Fpolicies-certificates","es":"\u002Fabout-us\u002Fpolicies-certificates","jp":"\u002Fabout-us\u002Fpolicies-certificates","ua":"\u002Fabout-us\u002Fpolicies-certificates"},"page_id":18},
    name: "about-us-policies-certificates-18___es"
  }, {
    path: "/es/about-us/who-we-are",
    component: _61180b77,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fwho-we-are","lt":"\u002Fapie-mus\u002Fkas-mes-esame","de":"\u002Fabout-us\u002Fwho-we-are","es":"\u002Fabout-us\u002Fwho-we-are","jp":"\u002Fabout-us\u002Fwho-we-are","ua":"\u002Fabout-us\u002Fwho-we-are"},"page_id":104},
    name: "about-us-who-we-are-104___es"
  }, {
    path: "/es/products/access-points",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccess-points","lt":"\u002Fproduktai\u002Faccess-points","de":"\u002Fproducts\u002Faccess-points","es":"\u002Fproducts\u002Faccess-points","jp":"\u002Fproducts\u002Faccess-points","ua":"\u002Fproducts\u002Faccess-points"},"page_id":182},
    name: "products-access-points-182___es"
  }, {
    path: "/es/products/accessories",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories","lt":"\u002Fproduktai\u002Fpriedai","de":"\u002Fproducts\u002Faccessories","es":"\u002Fproducts\u002Faccessories","jp":"\u002Fproducts\u002Faccessories","ua":"\u002Fproducts\u002Faccessories"},"page_id":116},
    name: "products-accessories-116___es"
  }, {
    path: "/es/products/cellular-iot",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fcellular-iot","lt":"\u002Fproduktai\u002Fmobilus-iot","de":"\u002Fproducts\u002Fcellular-iot","es":"\u002Fproducts\u002Fcellular-iot","jp":"\u002Fproducts\u002Fcellular-iot","ua":"\u002Fproducts\u002Fcellular-iot"},"page_id":110},
    name: "products-cellular-iot-110___es"
  }, {
    path: "/es/products/custom-products",
    component: _66cb1dcc,
    meta: {"paths":{"en":"\u002Fproducts\u002Fcustom-products","lt":"\u002Fproduktai\u002Funikalus-produktai","de":"\u002Fproducts\u002Fcustom-products","es":"\u002Fproducts\u002Fcustom-products","jp":"\u002Fproducts\u002Fcustom-products","ua":"\u002Fproducts\u002Fcustom-products"},"page_id":111},
    name: "products-custom-products-111___es"
  }, {
    path: "/es/products/ethernet-switches",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-switches","lt":"\u002Fproduktai\u002Fsakotuvai","de":"\u002Fproducts\u002Fethernet-switches","es":"\u002Fproducts\u002Fethernet-switches","jp":"\u002Fproducts\u002Fethernet-switches","ua":"\u002Fproducts\u002Fethernet-switches"},"page_id":120},
    name: "products-ethernet-switches-120___es"
  }, {
    path: "/es/products/ethernet-wireless",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys","de":"\u002Fproducts\u002Fethernet-wireless","es":"\u002Fproducts\u002Fethernet-wireless","jp":"\u002Fproducts\u002Fethernet-wireless","ua":"\u002Fproducts\u002Fethernet-wireless"},"page_id":117},
    name: "products-ethernet-wireless-117___es"
  }, {
    path: "/es/products/gateways",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fgateways","lt":"\u002Fproduktai\u002Ftinklu-sietuvai","de":"\u002Fproducts\u002Fgateways","es":"\u002Fproducts\u002Fgateways","jp":"\u002Fproducts\u002Fgateways","ua":"\u002Fproducts\u002Fgateways"},"page_id":113},
    name: "products-gateways-113___es"
  }, {
    path: "/es/products/iot-platforms",
    component: _737c122a,
    meta: {"paths":{"en":"\u002Fproducts\u002Fiot-platforms","lt":"\u002Fproduktai\u002Fiot-platforms","de":"\u002Fproducts\u002Fiot-platforms","es":"\u002Fproducts\u002Fiot-platforms","jp":"\u002Fproducts\u002Fiot-platforms","ua":"\u002Fproducts\u002Fiot-platforms"},"page_id":121},
    name: "products-iot-platforms-121___es"
  }, {
    path: "/es/products/modems",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fmodems","lt":"\u002Fproduktai\u002Fmodemai","de":"\u002Fproducts\u002Fmodems","es":"\u002Fproducts\u002Fmodems","jp":"\u002Fproducts\u002Fmodems","ua":"\u002Fproducts\u002Fmodems"},"page_id":112},
    name: "products-modems-112___es"
  }, {
    path: "/es/products/routers",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters","lt":"\u002Fproduktai\u002Fmarsrutizatoriai","de":"\u002Fproducts\u002Frouters","es":"\u002Fproducts\u002Frouters","jp":"\u002Fproducts\u002Frouters","ua":"\u002Fproducts\u002Frouters"},"page_id":115},
    name: "products-routers-115___es"
  }, {
    path: "/es/resources/blogs",
    component: _2ae9892c,
    meta: {"paths":{"en":"\u002Fresources\u002Fblogs","lt":"\u002Fresursai\u002Fblogs","de":"\u002Fresources\u002Fblogs","es":"\u002Fresources\u002Fblogs","jp":"\u002Fresources\u002Fblogs","ua":"\u002Fresources\u002Fblogs"},"page_id":124},
    name: "resources-blogs-124___es"
  }, {
    path: "/es/resources/certification-program",
    component: _99d23f9e,
    meta: {"paths":{"en":"\u002Fresources\u002Fcertification-program","lt":"\u002Fresursai\u002Fsertifikavimo-programa","de":"\u002Fresources\u002Fcertification-program","es":"\u002Fresources\u002Fcertification-program","jp":"\u002Fresources\u002Fcertification-program","ua":"\u002Fresources\u002Fcertification-program"},"page_id":102},
    name: "resources-certification-program-102___es"
  }, {
    path: "/es/resources/share-your-solution",
    component: _a54c8f24,
    meta: {"paths":{"en":"\u002Fresources\u002Fshare-your-solution","lt":"\u002Fresursai\u002Fpasidalinkite-savo-sprendimu","de":"\u002Fresources\u002Fshare-your-solution","es":"\u002Fresources\u002Fshare-your-solution","jp":"\u002Fresources\u002Fshare-your-solution","ua":"\u002Fresources\u002Fshare-your-solution"},"page_id":109},
    name: "resources-share-your-solution-109___es"
  }, {
    path: "/es/resources/videos",
    component: _09273272,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos","lt":"\u002Fresursai\u002Fvaizdo-irasai","de":"\u002Fresources\u002Fvideos","es":"\u002Fresources\u002Fvideos","jp":"\u002Fresources\u002Fvideos","ua":"\u002Fresources\u002Fvideos"},"page_id":101},
    name: "resources-videos-101___es"
  }, {
    path: "/es/resources/webinars",
    component: _2e232d93,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars","lt":"\u002Fresursai\u002Fwebinarai","de":"\u002Fresources\u002Fwebinars","es":"\u002Fresources\u002Fwebinars","jp":"\u002Fresources\u002Fwebinars","ua":"\u002Fresources\u002Fwebinars"},"page_id":179},
    name: "resources-webinars-179___es"
  }, {
    path: "/es/support/eol-products",
    component: _c25f0f92,
    meta: {"paths":{"en":"\u002Fsupport\u002Feol-products","lt":"\u002Faptarnavimas\u002Fproduktu-gyvavimo-ciklo-pabaiga","de":"\u002Fsupport\u002Feol-products","es":"\u002Fsupport\u002Feol-products","jp":"\u002Fsupport\u002Feol-products","ua":"\u002Fsupport\u002Feol-products"},"page_id":107},
    name: "support-eol-products-107___es"
  }, {
    path: "/es/support/gpl-code-center",
    component: _6eb69794,
    meta: {"paths":{"en":"\u002Fsupport\u002Fgpl-code-center","lt":"\u002Faptarnavimas\u002Fgpl-atvirasis-kodas","de":"\u002Fsupport\u002Fgpl-code-center","es":"\u002Fsupport\u002Fgpl-code-center","jp":"\u002Fsupport\u002Fgpl-code-center","ua":"\u002Fsupport\u002Fgpl-code-center"},"page_id":108},
    name: "support-gpl-code-center-108___es"
  }, {
    path: "/es/support/product-support",
    component: _5c755c58,
    meta: {"paths":{"en":"\u002Fsupport\u002Fproduct-support","lt":"\u002Faptarnavimas\u002Fprodukto-aptarnavimas","de":"\u002Fsupport\u002Fproduct-support","es":"\u002Fsupport\u002Fproduct-support","jp":"\u002Fsupport\u002Fproduct-support","ua":"\u002Fsupport\u002Fproduct-support"},"page_id":105},
    name: "support-product-support-105___es"
  }, {
    path: "/es/support/security-centre",
    component: _626335ec,
    meta: {"paths":{"en":"\u002Fsupport\u002Fsecurity-centre","lt":"\u002Faptarnavimas\u002Fsaugumo-centras","de":"\u002Fsupport\u002Fsecurity-centre","es":"\u002Fsupport\u002Fsecurity-centre","jp":"\u002Fsupport\u002Fsecurity-centre","ua":"\u002Fsupport\u002Fsecurity-centre"},"page_id":99},
    name: "support-security-centre-99___es"
  }, {
    path: "/es/support/garantia-y-reparaciones",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fsupport\u002Fwarranty-repair","lt":"\u002Faptarnavimas\u002Fgarantija-ir-remontas-1","de":"\u002Fsupport\u002Fgarantie-und-reparatur","es":"\u002Fsupport\u002Fgarantia-y-reparaciones","jp":"\u002Fsupport\u002Fwarranty-repair","ua":"\u002Fsupport\u002Fgarantiia-ta-remont"},"page_id":75},
    name: "support-warranty-repair-75___es"
  }, {
    path: "/es/use-cases/energy-utilities",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenergy-utilities","lt":"\u002Fvisi-panaudojimai\u002Fenergetika","de":"\u002Fuse-cases\u002Fenergy-utilities","es":"\u002Fuse-cases\u002Fenergy-utilities","jp":"\u002Fuse-cases\u002Fenergy-utilities","ua":"\u002Fuse-cases\u002Fenergy-utilities"},"page_id":91},
    name: "use-cases-energy-utilities-91___es"
  }, {
    path: "/es/use-cases/enterprise",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenterprise","lt":"\u002Fvisi-panaudojimai\u002Fverslo-sektorius","de":"\u002Fuse-cases\u002Fenterprise","es":"\u002Fuse-cases\u002Fenterprise","jp":"\u002Fuse-cases\u002Fenterprise","ua":"\u002Fuse-cases\u002Fenterprise"},"page_id":94},
    name: "use-cases-enterprise-94___es"
  }, {
    path: "/es/use-cases/industrial-automation",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Findustrial-automation","lt":"\u002Fvisi-panaudojimai\u002Fautomatizavimas","de":"\u002Fuse-cases\u002Findustrial-automation","es":"\u002Fuse-cases\u002Findustrial-automation","jp":"\u002Fuse-cases\u002Findustrial-automation","ua":"\u002Fuse-cases\u002Findustrial-automation"},"page_id":90},
    name: "use-cases-industrial-automation-90___es"
  }, {
    path: "/es/use-cases/retail",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fretail","lt":"\u002Fvisi-panaudojimai\u002Fprekybos-sektorius","de":"\u002Fuse-cases\u002Fretail","es":"\u002Fuse-cases\u002Fretail","jp":"\u002Fuse-cases\u002Fretail","ua":"\u002Fuse-cases\u002Fretail"},"page_id":95},
    name: "use-cases-retail-95___es"
  }, {
    path: "/es/use-cases/smart-city",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fsmart-city","lt":"\u002Fvisi-panaudojimai\u002Fismanus-miestas","de":"\u002Fuse-cases\u002Fsmart-city","es":"\u002Fuse-cases\u002Fsmart-city","jp":"\u002Fuse-cases\u002Fsmart-city","ua":"\u002Fuse-cases\u002Fsmart-city"},"page_id":92},
    name: "use-cases-smart-city-92___es"
  }, {
    path: "/es/use-cases/transportation",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftransportation","lt":"\u002Fvisi-panaudojimai\u002Ftransportas","de":"\u002Fuse-cases\u002Ftransportation","es":"\u002Fuse-cases\u002Ftransportation","jp":"\u002Fuse-cases\u002Ftransportation","ua":"\u002Fuse-cases\u002Ftransportation"},"page_id":93},
    name: "use-cases-transportation-93___es"
  }, {
    path: "/jp/about-us/career",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcareer","lt":"\u002Fapie-mus\u002Fkarjera","de":"\u002Fabout-us\u002Fcareer","es":"\u002Fabout-us\u002Fcareer","jp":"\u002Fabout-us\u002Fcareer","ua":"\u002Fabout-us\u002Fcareer"},"page_id":21},
    name: "about-us-career-21___jp"
  }, {
    path: "/jp/about-us/contacts",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai","de":"\u002Fabout-us\u002Fcontacts","es":"\u002Fabout-us\u002Fcontacts","jp":"\u002Fabout-us\u002Fcontacts","ua":"\u002Fabout-us\u002Fcontacts"},"page_id":19},
    name: "about-us-contacts-19___jp"
  }, {
    path: "/jp/about-us/mission-vision-values",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes","de":"\u002Fabout-us\u002Fmission-vision-values","es":"\u002Fabout-us\u002Fmission-vision-values","jp":"\u002Fabout-us\u002Fmission-vision-values","ua":"\u002Fabout-us\u002Fmission-vision-values"},"page_id":16},
    name: "about-us-mission-vision-values-16___jp"
  }, {
    path: "/jp/about-us/policies-certificates",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai","de":"\u002Fabout-us\u002Fpolicies-certificates","es":"\u002Fabout-us\u002Fpolicies-certificates","jp":"\u002Fabout-us\u002Fpolicies-certificates","ua":"\u002Fabout-us\u002Fpolicies-certificates"},"page_id":18},
    name: "about-us-policies-certificates-18___jp"
  }, {
    path: "/jp/about-us/who-we-are",
    component: _61180b77,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fwho-we-are","lt":"\u002Fapie-mus\u002Fkas-mes-esame","de":"\u002Fabout-us\u002Fwho-we-are","es":"\u002Fabout-us\u002Fwho-we-are","jp":"\u002Fabout-us\u002Fwho-we-are","ua":"\u002Fabout-us\u002Fwho-we-are"},"page_id":104},
    name: "about-us-who-we-are-104___jp"
  }, {
    path: "/jp/products/access-points",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccess-points","lt":"\u002Fproduktai\u002Faccess-points","de":"\u002Fproducts\u002Faccess-points","es":"\u002Fproducts\u002Faccess-points","jp":"\u002Fproducts\u002Faccess-points","ua":"\u002Fproducts\u002Faccess-points"},"page_id":182},
    name: "products-access-points-182___jp"
  }, {
    path: "/jp/products/accessories",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories","lt":"\u002Fproduktai\u002Fpriedai","de":"\u002Fproducts\u002Faccessories","es":"\u002Fproducts\u002Faccessories","jp":"\u002Fproducts\u002Faccessories","ua":"\u002Fproducts\u002Faccessories"},"page_id":116},
    name: "products-accessories-116___jp"
  }, {
    path: "/jp/products/cellular-iot",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fcellular-iot","lt":"\u002Fproduktai\u002Fmobilus-iot","de":"\u002Fproducts\u002Fcellular-iot","es":"\u002Fproducts\u002Fcellular-iot","jp":"\u002Fproducts\u002Fcellular-iot","ua":"\u002Fproducts\u002Fcellular-iot"},"page_id":110},
    name: "products-cellular-iot-110___jp"
  }, {
    path: "/jp/products/custom-products",
    component: _66cb1dcc,
    meta: {"paths":{"en":"\u002Fproducts\u002Fcustom-products","lt":"\u002Fproduktai\u002Funikalus-produktai","de":"\u002Fproducts\u002Fcustom-products","es":"\u002Fproducts\u002Fcustom-products","jp":"\u002Fproducts\u002Fcustom-products","ua":"\u002Fproducts\u002Fcustom-products"},"page_id":111},
    name: "products-custom-products-111___jp"
  }, {
    path: "/jp/products/ethernet-switches",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-switches","lt":"\u002Fproduktai\u002Fsakotuvai","de":"\u002Fproducts\u002Fethernet-switches","es":"\u002Fproducts\u002Fethernet-switches","jp":"\u002Fproducts\u002Fethernet-switches","ua":"\u002Fproducts\u002Fethernet-switches"},"page_id":120},
    name: "products-ethernet-switches-120___jp"
  }, {
    path: "/jp/products/ethernet-wireless",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys","de":"\u002Fproducts\u002Fethernet-wireless","es":"\u002Fproducts\u002Fethernet-wireless","jp":"\u002Fproducts\u002Fethernet-wireless","ua":"\u002Fproducts\u002Fethernet-wireless"},"page_id":117},
    name: "products-ethernet-wireless-117___jp"
  }, {
    path: "/jp/products/gateways",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fgateways","lt":"\u002Fproduktai\u002Ftinklu-sietuvai","de":"\u002Fproducts\u002Fgateways","es":"\u002Fproducts\u002Fgateways","jp":"\u002Fproducts\u002Fgateways","ua":"\u002Fproducts\u002Fgateways"},"page_id":113},
    name: "products-gateways-113___jp"
  }, {
    path: "/jp/products/iot-platforms",
    component: _737c122a,
    meta: {"paths":{"en":"\u002Fproducts\u002Fiot-platforms","lt":"\u002Fproduktai\u002Fiot-platforms","de":"\u002Fproducts\u002Fiot-platforms","es":"\u002Fproducts\u002Fiot-platforms","jp":"\u002Fproducts\u002Fiot-platforms","ua":"\u002Fproducts\u002Fiot-platforms"},"page_id":121},
    name: "products-iot-platforms-121___jp"
  }, {
    path: "/jp/products/modems",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fmodems","lt":"\u002Fproduktai\u002Fmodemai","de":"\u002Fproducts\u002Fmodems","es":"\u002Fproducts\u002Fmodems","jp":"\u002Fproducts\u002Fmodems","ua":"\u002Fproducts\u002Fmodems"},"page_id":112},
    name: "products-modems-112___jp"
  }, {
    path: "/jp/products/routers",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters","lt":"\u002Fproduktai\u002Fmarsrutizatoriai","de":"\u002Fproducts\u002Frouters","es":"\u002Fproducts\u002Frouters","jp":"\u002Fproducts\u002Frouters","ua":"\u002Fproducts\u002Frouters"},"page_id":115},
    name: "products-routers-115___jp"
  }, {
    path: "/jp/resources/blogs",
    component: _2ae9892c,
    meta: {"paths":{"en":"\u002Fresources\u002Fblogs","lt":"\u002Fresursai\u002Fblogs","de":"\u002Fresources\u002Fblogs","es":"\u002Fresources\u002Fblogs","jp":"\u002Fresources\u002Fblogs","ua":"\u002Fresources\u002Fblogs"},"page_id":124},
    name: "resources-blogs-124___jp"
  }, {
    path: "/jp/resources/certification-program",
    component: _99d23f9e,
    meta: {"paths":{"en":"\u002Fresources\u002Fcertification-program","lt":"\u002Fresursai\u002Fsertifikavimo-programa","de":"\u002Fresources\u002Fcertification-program","es":"\u002Fresources\u002Fcertification-program","jp":"\u002Fresources\u002Fcertification-program","ua":"\u002Fresources\u002Fcertification-program"},"page_id":102},
    name: "resources-certification-program-102___jp"
  }, {
    path: "/jp/resources/share-your-solution",
    component: _a54c8f24,
    meta: {"paths":{"en":"\u002Fresources\u002Fshare-your-solution","lt":"\u002Fresursai\u002Fpasidalinkite-savo-sprendimu","de":"\u002Fresources\u002Fshare-your-solution","es":"\u002Fresources\u002Fshare-your-solution","jp":"\u002Fresources\u002Fshare-your-solution","ua":"\u002Fresources\u002Fshare-your-solution"},"page_id":109},
    name: "resources-share-your-solution-109___jp"
  }, {
    path: "/jp/resources/videos",
    component: _09273272,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos","lt":"\u002Fresursai\u002Fvaizdo-irasai","de":"\u002Fresources\u002Fvideos","es":"\u002Fresources\u002Fvideos","jp":"\u002Fresources\u002Fvideos","ua":"\u002Fresources\u002Fvideos"},"page_id":101},
    name: "resources-videos-101___jp"
  }, {
    path: "/jp/resources/webinars",
    component: _2e232d93,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars","lt":"\u002Fresursai\u002Fwebinarai","de":"\u002Fresources\u002Fwebinars","es":"\u002Fresources\u002Fwebinars","jp":"\u002Fresources\u002Fwebinars","ua":"\u002Fresources\u002Fwebinars"},"page_id":179},
    name: "resources-webinars-179___jp"
  }, {
    path: "/jp/support/eol-products",
    component: _c25f0f92,
    meta: {"paths":{"en":"\u002Fsupport\u002Feol-products","lt":"\u002Faptarnavimas\u002Fproduktu-gyvavimo-ciklo-pabaiga","de":"\u002Fsupport\u002Feol-products","es":"\u002Fsupport\u002Feol-products","jp":"\u002Fsupport\u002Feol-products","ua":"\u002Fsupport\u002Feol-products"},"page_id":107},
    name: "support-eol-products-107___jp"
  }, {
    path: "/jp/support/gpl-code-center",
    component: _6eb69794,
    meta: {"paths":{"en":"\u002Fsupport\u002Fgpl-code-center","lt":"\u002Faptarnavimas\u002Fgpl-atvirasis-kodas","de":"\u002Fsupport\u002Fgpl-code-center","es":"\u002Fsupport\u002Fgpl-code-center","jp":"\u002Fsupport\u002Fgpl-code-center","ua":"\u002Fsupport\u002Fgpl-code-center"},"page_id":108},
    name: "support-gpl-code-center-108___jp"
  }, {
    path: "/jp/support/product-support",
    component: _5c755c58,
    meta: {"paths":{"en":"\u002Fsupport\u002Fproduct-support","lt":"\u002Faptarnavimas\u002Fprodukto-aptarnavimas","de":"\u002Fsupport\u002Fproduct-support","es":"\u002Fsupport\u002Fproduct-support","jp":"\u002Fsupport\u002Fproduct-support","ua":"\u002Fsupport\u002Fproduct-support"},"page_id":105},
    name: "support-product-support-105___jp"
  }, {
    path: "/jp/support/security-centre",
    component: _626335ec,
    meta: {"paths":{"en":"\u002Fsupport\u002Fsecurity-centre","lt":"\u002Faptarnavimas\u002Fsaugumo-centras","de":"\u002Fsupport\u002Fsecurity-centre","es":"\u002Fsupport\u002Fsecurity-centre","jp":"\u002Fsupport\u002Fsecurity-centre","ua":"\u002Fsupport\u002Fsecurity-centre"},"page_id":99},
    name: "support-security-centre-99___jp"
  }, {
    path: "/jp/support/warranty-repair",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fsupport\u002Fwarranty-repair","lt":"\u002Faptarnavimas\u002Fgarantija-ir-remontas-1","de":"\u002Fsupport\u002Fgarantie-und-reparatur","es":"\u002Fsupport\u002Fgarantia-y-reparaciones","jp":"\u002Fsupport\u002Fwarranty-repair","ua":"\u002Fsupport\u002Fgarantiia-ta-remont"},"page_id":75},
    name: "support-warranty-repair-75___jp"
  }, {
    path: "/jp/use-cases/energy-utilities",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenergy-utilities","lt":"\u002Fvisi-panaudojimai\u002Fenergetika","de":"\u002Fuse-cases\u002Fenergy-utilities","es":"\u002Fuse-cases\u002Fenergy-utilities","jp":"\u002Fuse-cases\u002Fenergy-utilities","ua":"\u002Fuse-cases\u002Fenergy-utilities"},"page_id":91},
    name: "use-cases-energy-utilities-91___jp"
  }, {
    path: "/jp/use-cases/enterprise",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenterprise","lt":"\u002Fvisi-panaudojimai\u002Fverslo-sektorius","de":"\u002Fuse-cases\u002Fenterprise","es":"\u002Fuse-cases\u002Fenterprise","jp":"\u002Fuse-cases\u002Fenterprise","ua":"\u002Fuse-cases\u002Fenterprise"},"page_id":94},
    name: "use-cases-enterprise-94___jp"
  }, {
    path: "/jp/use-cases/industrial-automation",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Findustrial-automation","lt":"\u002Fvisi-panaudojimai\u002Fautomatizavimas","de":"\u002Fuse-cases\u002Findustrial-automation","es":"\u002Fuse-cases\u002Findustrial-automation","jp":"\u002Fuse-cases\u002Findustrial-automation","ua":"\u002Fuse-cases\u002Findustrial-automation"},"page_id":90},
    name: "use-cases-industrial-automation-90___jp"
  }, {
    path: "/jp/use-cases/retail",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fretail","lt":"\u002Fvisi-panaudojimai\u002Fprekybos-sektorius","de":"\u002Fuse-cases\u002Fretail","es":"\u002Fuse-cases\u002Fretail","jp":"\u002Fuse-cases\u002Fretail","ua":"\u002Fuse-cases\u002Fretail"},"page_id":95},
    name: "use-cases-retail-95___jp"
  }, {
    path: "/jp/use-cases/smart-city",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fsmart-city","lt":"\u002Fvisi-panaudojimai\u002Fismanus-miestas","de":"\u002Fuse-cases\u002Fsmart-city","es":"\u002Fuse-cases\u002Fsmart-city","jp":"\u002Fuse-cases\u002Fsmart-city","ua":"\u002Fuse-cases\u002Fsmart-city"},"page_id":92},
    name: "use-cases-smart-city-92___jp"
  }, {
    path: "/jp/use-cases/transportation",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftransportation","lt":"\u002Fvisi-panaudojimai\u002Ftransportas","de":"\u002Fuse-cases\u002Ftransportation","es":"\u002Fuse-cases\u002Ftransportation","jp":"\u002Fuse-cases\u002Ftransportation","ua":"\u002Fuse-cases\u002Ftransportation"},"page_id":93},
    name: "use-cases-transportation-93___jp"
  }, {
    path: "/lt/apie-mus/karjera",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcareer","lt":"\u002Fapie-mus\u002Fkarjera","de":"\u002Fabout-us\u002Fcareer","es":"\u002Fabout-us\u002Fcareer","jp":"\u002Fabout-us\u002Fcareer","ua":"\u002Fabout-us\u002Fcareer"},"page_id":21},
    name: "about-us-career-21___lt"
  }, {
    path: "/lt/apie-mus/kontaktai",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai","de":"\u002Fabout-us\u002Fcontacts","es":"\u002Fabout-us\u002Fcontacts","jp":"\u002Fabout-us\u002Fcontacts","ua":"\u002Fabout-us\u002Fcontacts"},"page_id":19},
    name: "about-us-contacts-19___lt"
  }, {
    path: "/lt/apie-mus/misija-vizija-ir-vertybes",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes","de":"\u002Fabout-us\u002Fmission-vision-values","es":"\u002Fabout-us\u002Fmission-vision-values","jp":"\u002Fabout-us\u002Fmission-vision-values","ua":"\u002Fabout-us\u002Fmission-vision-values"},"page_id":16},
    name: "about-us-mission-vision-values-16___lt"
  }, {
    path: "/lt/apie-mus/politika-ir-sertifikatai",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai","de":"\u002Fabout-us\u002Fpolicies-certificates","es":"\u002Fabout-us\u002Fpolicies-certificates","jp":"\u002Fabout-us\u002Fpolicies-certificates","ua":"\u002Fabout-us\u002Fpolicies-certificates"},"page_id":18},
    name: "about-us-policies-certificates-18___lt"
  }, {
    path: "/lt/apie-mus/kas-mes-esame",
    component: _61180b77,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fwho-we-are","lt":"\u002Fapie-mus\u002Fkas-mes-esame","de":"\u002Fabout-us\u002Fwho-we-are","es":"\u002Fabout-us\u002Fwho-we-are","jp":"\u002Fabout-us\u002Fwho-we-are","ua":"\u002Fabout-us\u002Fwho-we-are"},"page_id":104},
    name: "about-us-who-we-are-104___lt"
  }, {
    path: "/lt/produktai/access-points",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccess-points","lt":"\u002Fproduktai\u002Faccess-points","de":"\u002Fproducts\u002Faccess-points","es":"\u002Fproducts\u002Faccess-points","jp":"\u002Fproducts\u002Faccess-points","ua":"\u002Fproducts\u002Faccess-points"},"page_id":182},
    name: "products-access-points-182___lt"
  }, {
    path: "/lt/produktai/priedai",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories","lt":"\u002Fproduktai\u002Fpriedai","de":"\u002Fproducts\u002Faccessories","es":"\u002Fproducts\u002Faccessories","jp":"\u002Fproducts\u002Faccessories","ua":"\u002Fproducts\u002Faccessories"},"page_id":116},
    name: "products-accessories-116___lt"
  }, {
    path: "/lt/produktai/mobilus-iot",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fcellular-iot","lt":"\u002Fproduktai\u002Fmobilus-iot","de":"\u002Fproducts\u002Fcellular-iot","es":"\u002Fproducts\u002Fcellular-iot","jp":"\u002Fproducts\u002Fcellular-iot","ua":"\u002Fproducts\u002Fcellular-iot"},"page_id":110},
    name: "products-cellular-iot-110___lt"
  }, {
    path: "/lt/produktai/unikalus-produktai",
    component: _66cb1dcc,
    meta: {"paths":{"en":"\u002Fproducts\u002Fcustom-products","lt":"\u002Fproduktai\u002Funikalus-produktai","de":"\u002Fproducts\u002Fcustom-products","es":"\u002Fproducts\u002Fcustom-products","jp":"\u002Fproducts\u002Fcustom-products","ua":"\u002Fproducts\u002Fcustom-products"},"page_id":111},
    name: "products-custom-products-111___lt"
  }, {
    path: "/lt/produktai/sakotuvai",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-switches","lt":"\u002Fproduktai\u002Fsakotuvai","de":"\u002Fproducts\u002Fethernet-switches","es":"\u002Fproducts\u002Fethernet-switches","jp":"\u002Fproducts\u002Fethernet-switches","ua":"\u002Fproducts\u002Fethernet-switches"},"page_id":120},
    name: "products-ethernet-switches-120___lt"
  }, {
    path: "/lt/produktai/eternetas-ir-belaidis-rysys",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys","de":"\u002Fproducts\u002Fethernet-wireless","es":"\u002Fproducts\u002Fethernet-wireless","jp":"\u002Fproducts\u002Fethernet-wireless","ua":"\u002Fproducts\u002Fethernet-wireless"},"page_id":117},
    name: "products-ethernet-wireless-117___lt"
  }, {
    path: "/lt/produktai/tinklu-sietuvai",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fgateways","lt":"\u002Fproduktai\u002Ftinklu-sietuvai","de":"\u002Fproducts\u002Fgateways","es":"\u002Fproducts\u002Fgateways","jp":"\u002Fproducts\u002Fgateways","ua":"\u002Fproducts\u002Fgateways"},"page_id":113},
    name: "products-gateways-113___lt"
  }, {
    path: "/lt/produktai/iot-platforms",
    component: _737c122a,
    meta: {"paths":{"en":"\u002Fproducts\u002Fiot-platforms","lt":"\u002Fproduktai\u002Fiot-platforms","de":"\u002Fproducts\u002Fiot-platforms","es":"\u002Fproducts\u002Fiot-platforms","jp":"\u002Fproducts\u002Fiot-platforms","ua":"\u002Fproducts\u002Fiot-platforms"},"page_id":121},
    name: "products-iot-platforms-121___lt"
  }, {
    path: "/lt/produktai/modemai",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fmodems","lt":"\u002Fproduktai\u002Fmodemai","de":"\u002Fproducts\u002Fmodems","es":"\u002Fproducts\u002Fmodems","jp":"\u002Fproducts\u002Fmodems","ua":"\u002Fproducts\u002Fmodems"},"page_id":112},
    name: "products-modems-112___lt"
  }, {
    path: "/lt/produktai/marsrutizatoriai",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters","lt":"\u002Fproduktai\u002Fmarsrutizatoriai","de":"\u002Fproducts\u002Frouters","es":"\u002Fproducts\u002Frouters","jp":"\u002Fproducts\u002Frouters","ua":"\u002Fproducts\u002Frouters"},"page_id":115},
    name: "products-routers-115___lt"
  }, {
    path: "/lt/resursai/blogs",
    component: _2ae9892c,
    meta: {"paths":{"en":"\u002Fresources\u002Fblogs","lt":"\u002Fresursai\u002Fblogs","de":"\u002Fresources\u002Fblogs","es":"\u002Fresources\u002Fblogs","jp":"\u002Fresources\u002Fblogs","ua":"\u002Fresources\u002Fblogs"},"page_id":124},
    name: "resources-blogs-124___lt"
  }, {
    path: "/lt/resursai/sertifikavimo-programa",
    component: _99d23f9e,
    meta: {"paths":{"en":"\u002Fresources\u002Fcertification-program","lt":"\u002Fresursai\u002Fsertifikavimo-programa","de":"\u002Fresources\u002Fcertification-program","es":"\u002Fresources\u002Fcertification-program","jp":"\u002Fresources\u002Fcertification-program","ua":"\u002Fresources\u002Fcertification-program"},"page_id":102},
    name: "resources-certification-program-102___lt"
  }, {
    path: "/lt/resursai/pasidalinkite-savo-sprendimu",
    component: _a54c8f24,
    meta: {"paths":{"en":"\u002Fresources\u002Fshare-your-solution","lt":"\u002Fresursai\u002Fpasidalinkite-savo-sprendimu","de":"\u002Fresources\u002Fshare-your-solution","es":"\u002Fresources\u002Fshare-your-solution","jp":"\u002Fresources\u002Fshare-your-solution","ua":"\u002Fresources\u002Fshare-your-solution"},"page_id":109},
    name: "resources-share-your-solution-109___lt"
  }, {
    path: "/lt/resursai/vaizdo-irasai",
    component: _09273272,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos","lt":"\u002Fresursai\u002Fvaizdo-irasai","de":"\u002Fresources\u002Fvideos","es":"\u002Fresources\u002Fvideos","jp":"\u002Fresources\u002Fvideos","ua":"\u002Fresources\u002Fvideos"},"page_id":101},
    name: "resources-videos-101___lt"
  }, {
    path: "/lt/resursai/webinarai",
    component: _2e232d93,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars","lt":"\u002Fresursai\u002Fwebinarai","de":"\u002Fresources\u002Fwebinars","es":"\u002Fresources\u002Fwebinars","jp":"\u002Fresources\u002Fwebinars","ua":"\u002Fresources\u002Fwebinars"},"page_id":179},
    name: "resources-webinars-179___lt"
  }, {
    path: "/lt/aptarnavimas/produktu-gyvavimo-ciklo-pabaiga",
    component: _c25f0f92,
    meta: {"paths":{"en":"\u002Fsupport\u002Feol-products","lt":"\u002Faptarnavimas\u002Fproduktu-gyvavimo-ciklo-pabaiga","de":"\u002Fsupport\u002Feol-products","es":"\u002Fsupport\u002Feol-products","jp":"\u002Fsupport\u002Feol-products","ua":"\u002Fsupport\u002Feol-products"},"page_id":107},
    name: "support-eol-products-107___lt"
  }, {
    path: "/lt/aptarnavimas/gpl-atvirasis-kodas",
    component: _6eb69794,
    meta: {"paths":{"en":"\u002Fsupport\u002Fgpl-code-center","lt":"\u002Faptarnavimas\u002Fgpl-atvirasis-kodas","de":"\u002Fsupport\u002Fgpl-code-center","es":"\u002Fsupport\u002Fgpl-code-center","jp":"\u002Fsupport\u002Fgpl-code-center","ua":"\u002Fsupport\u002Fgpl-code-center"},"page_id":108},
    name: "support-gpl-code-center-108___lt"
  }, {
    path: "/lt/aptarnavimas/produkto-aptarnavimas",
    component: _5c755c58,
    meta: {"paths":{"en":"\u002Fsupport\u002Fproduct-support","lt":"\u002Faptarnavimas\u002Fprodukto-aptarnavimas","de":"\u002Fsupport\u002Fproduct-support","es":"\u002Fsupport\u002Fproduct-support","jp":"\u002Fsupport\u002Fproduct-support","ua":"\u002Fsupport\u002Fproduct-support"},"page_id":105},
    name: "support-product-support-105___lt"
  }, {
    path: "/lt/aptarnavimas/saugumo-centras",
    component: _626335ec,
    meta: {"paths":{"en":"\u002Fsupport\u002Fsecurity-centre","lt":"\u002Faptarnavimas\u002Fsaugumo-centras","de":"\u002Fsupport\u002Fsecurity-centre","es":"\u002Fsupport\u002Fsecurity-centre","jp":"\u002Fsupport\u002Fsecurity-centre","ua":"\u002Fsupport\u002Fsecurity-centre"},"page_id":99},
    name: "support-security-centre-99___lt"
  }, {
    path: "/lt/aptarnavimas/garantija-ir-remontas-1",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fsupport\u002Fwarranty-repair","lt":"\u002Faptarnavimas\u002Fgarantija-ir-remontas-1","de":"\u002Fsupport\u002Fgarantie-und-reparatur","es":"\u002Fsupport\u002Fgarantia-y-reparaciones","jp":"\u002Fsupport\u002Fwarranty-repair","ua":"\u002Fsupport\u002Fgarantiia-ta-remont"},"page_id":75},
    name: "support-warranty-repair-75___lt"
  }, {
    path: "/lt/visi-panaudojimai/energetika",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenergy-utilities","lt":"\u002Fvisi-panaudojimai\u002Fenergetika","de":"\u002Fuse-cases\u002Fenergy-utilities","es":"\u002Fuse-cases\u002Fenergy-utilities","jp":"\u002Fuse-cases\u002Fenergy-utilities","ua":"\u002Fuse-cases\u002Fenergy-utilities"},"page_id":91},
    name: "use-cases-energy-utilities-91___lt"
  }, {
    path: "/lt/visi-panaudojimai/verslo-sektorius",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenterprise","lt":"\u002Fvisi-panaudojimai\u002Fverslo-sektorius","de":"\u002Fuse-cases\u002Fenterprise","es":"\u002Fuse-cases\u002Fenterprise","jp":"\u002Fuse-cases\u002Fenterprise","ua":"\u002Fuse-cases\u002Fenterprise"},"page_id":94},
    name: "use-cases-enterprise-94___lt"
  }, {
    path: "/lt/visi-panaudojimai/automatizavimas",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Findustrial-automation","lt":"\u002Fvisi-panaudojimai\u002Fautomatizavimas","de":"\u002Fuse-cases\u002Findustrial-automation","es":"\u002Fuse-cases\u002Findustrial-automation","jp":"\u002Fuse-cases\u002Findustrial-automation","ua":"\u002Fuse-cases\u002Findustrial-automation"},"page_id":90},
    name: "use-cases-industrial-automation-90___lt"
  }, {
    path: "/lt/visi-panaudojimai/prekybos-sektorius",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fretail","lt":"\u002Fvisi-panaudojimai\u002Fprekybos-sektorius","de":"\u002Fuse-cases\u002Fretail","es":"\u002Fuse-cases\u002Fretail","jp":"\u002Fuse-cases\u002Fretail","ua":"\u002Fuse-cases\u002Fretail"},"page_id":95},
    name: "use-cases-retail-95___lt"
  }, {
    path: "/lt/visi-panaudojimai/ismanus-miestas",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fsmart-city","lt":"\u002Fvisi-panaudojimai\u002Fismanus-miestas","de":"\u002Fuse-cases\u002Fsmart-city","es":"\u002Fuse-cases\u002Fsmart-city","jp":"\u002Fuse-cases\u002Fsmart-city","ua":"\u002Fuse-cases\u002Fsmart-city"},"page_id":92},
    name: "use-cases-smart-city-92___lt"
  }, {
    path: "/lt/visi-panaudojimai/transportas",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftransportation","lt":"\u002Fvisi-panaudojimai\u002Ftransportas","de":"\u002Fuse-cases\u002Ftransportation","es":"\u002Fuse-cases\u002Ftransportation","jp":"\u002Fuse-cases\u002Ftransportation","ua":"\u002Fuse-cases\u002Ftransportation"},"page_id":93},
    name: "use-cases-transportation-93___lt"
  }, {
    path: "/products/accessories/3rd-party-solutions",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","lt":"\u002Fproduktai\u002Fpriedai\u002Ftreciuju-saliu-sprendimai","de":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","es":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","jp":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","ua":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions"},"page_id":130},
    name: "products-accessories-3rd-party-solutions-130___en"
  }, {
    path: "/products/accessories/antenna-options",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fantenna-options","lt":"\u002Fproduktai\u002Fpriedai\u002Fantenu-parinktys","de":"\u002Fproducts\u002Faccessories\u002Fantenna-options","es":"\u002Fproducts\u002Faccessories\u002Fantenna-options","jp":"\u002Fproducts\u002Faccessories\u002Fantenna-options","ua":"\u002Fproducts\u002Faccessories\u002Fantenna-options"},"page_id":127},
    name: "products-accessories-antenna-options-127___en"
  }, {
    path: "/products/accessories/mounting-options",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fmounting-options","lt":"\u002Fproduktai\u002Fpriedai\u002Fmontavimo-galimybes","de":"\u002Fproducts\u002Faccessories\u002Fmounting-options","es":"\u002Fproducts\u002Faccessories\u002Fmounting-options","jp":"\u002Fproducts\u002Faccessories\u002Fmounting-options","ua":"\u002Fproducts\u002Faccessories\u002Fmounting-options"},"page_id":128},
    name: "products-accessories-mounting-options-128___en"
  }, {
    path: "/products/accessories/other",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita","de":"\u002Fproducts\u002Faccessories\u002Fother","es":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":126},
    name: "products-accessories-other-126___en"
  }, {
    path: "/products/accessories/other-1",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother-1","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita-1","de":"\u002Fproducts\u002Faccessories\u002Fother-1","es":"\u002Fproducts\u002Faccessories\u002Fother-1","jp":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":129},
    name: "products-accessories-other-1-129___en"
  }, {
    path: "/products/accessories/other-2",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother-2","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita-2","de":"\u002Fproducts\u002Faccessories\u002Fother-2","es":"\u002Fproducts\u002Faccessories\u002Fother-2","jp":"\u002Fproducts\u002Faccessories\u002Fother-1","ua":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":131},
    name: "products-accessories-other-2-131___en"
  }, {
    path: "/products/accessories/powering-options",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpowering-options","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-priedai","de":"\u002Fproducts\u002Faccessories\u002Fpowering-options","es":"\u002Fproducts\u002Faccessories\u002Fpowering-options","jp":"\u002Fproducts\u002Faccessories\u002Fpowering-options","ua":"\u002Fproducts\u002Faccessories\u002Fpowering-options"},"page_id":125},
    name: "products-accessories-powering-options-125___en"
  }, {
    path: "/products/ethernet-wireless/routers-ethernet",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys\u002Frouters-ethernet","de":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","es":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","jp":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","ua":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet"},"page_id":134},
    name: "products-ethernet-wireless-routers-ethernet-134___en"
  }, {
    path: "/products/routers/ethernet-wireless-1",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1","lt":"\u002Fproduktai\u002Fmarsrutizatoriai\u002Fethernet-wireless","de":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1","es":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1","jp":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1"},"page_id":133},
    name: "products-routers-ethernet-wireless-1-133___en"
  }, {
    path: "/ua/about-us/career",
    component: _cd863b84,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcareer","lt":"\u002Fapie-mus\u002Fkarjera","de":"\u002Fabout-us\u002Fcareer","es":"\u002Fabout-us\u002Fcareer","jp":"\u002Fabout-us\u002Fcareer","ua":"\u002Fabout-us\u002Fcareer"},"page_id":21},
    name: "about-us-career-21___ua"
  }, {
    path: "/ua/about-us/contacts",
    component: _43c793cc,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fcontacts","lt":"\u002Fapie-mus\u002Fkontaktai","de":"\u002Fabout-us\u002Fcontacts","es":"\u002Fabout-us\u002Fcontacts","jp":"\u002Fabout-us\u002Fcontacts","ua":"\u002Fabout-us\u002Fcontacts"},"page_id":19},
    name: "about-us-contacts-19___ua"
  }, {
    path: "/ua/about-us/mission-vision-values",
    component: _17157922,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fmission-vision-values","lt":"\u002Fapie-mus\u002Fmisija-vizija-ir-vertybes","de":"\u002Fabout-us\u002Fmission-vision-values","es":"\u002Fabout-us\u002Fmission-vision-values","jp":"\u002Fabout-us\u002Fmission-vision-values","ua":"\u002Fabout-us\u002Fmission-vision-values"},"page_id":16},
    name: "about-us-mission-vision-values-16___ua"
  }, {
    path: "/ua/about-us/policies-certificates",
    component: _89ee1394,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai","de":"\u002Fabout-us\u002Fpolicies-certificates","es":"\u002Fabout-us\u002Fpolicies-certificates","jp":"\u002Fabout-us\u002Fpolicies-certificates","ua":"\u002Fabout-us\u002Fpolicies-certificates"},"page_id":18},
    name: "about-us-policies-certificates-18___ua"
  }, {
    path: "/ua/about-us/who-we-are",
    component: _61180b77,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fwho-we-are","lt":"\u002Fapie-mus\u002Fkas-mes-esame","de":"\u002Fabout-us\u002Fwho-we-are","es":"\u002Fabout-us\u002Fwho-we-are","jp":"\u002Fabout-us\u002Fwho-we-are","ua":"\u002Fabout-us\u002Fwho-we-are"},"page_id":104},
    name: "about-us-who-we-are-104___ua"
  }, {
    path: "/ua/products/access-points",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccess-points","lt":"\u002Fproduktai\u002Faccess-points","de":"\u002Fproducts\u002Faccess-points","es":"\u002Fproducts\u002Faccess-points","jp":"\u002Fproducts\u002Faccess-points","ua":"\u002Fproducts\u002Faccess-points"},"page_id":182},
    name: "products-access-points-182___ua"
  }, {
    path: "/ua/products/accessories",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories","lt":"\u002Fproduktai\u002Fpriedai","de":"\u002Fproducts\u002Faccessories","es":"\u002Fproducts\u002Faccessories","jp":"\u002Fproducts\u002Faccessories","ua":"\u002Fproducts\u002Faccessories"},"page_id":116},
    name: "products-accessories-116___ua"
  }, {
    path: "/ua/products/cellular-iot",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fcellular-iot","lt":"\u002Fproduktai\u002Fmobilus-iot","de":"\u002Fproducts\u002Fcellular-iot","es":"\u002Fproducts\u002Fcellular-iot","jp":"\u002Fproducts\u002Fcellular-iot","ua":"\u002Fproducts\u002Fcellular-iot"},"page_id":110},
    name: "products-cellular-iot-110___ua"
  }, {
    path: "/ua/products/custom-products",
    component: _66cb1dcc,
    meta: {"paths":{"en":"\u002Fproducts\u002Fcustom-products","lt":"\u002Fproduktai\u002Funikalus-produktai","de":"\u002Fproducts\u002Fcustom-products","es":"\u002Fproducts\u002Fcustom-products","jp":"\u002Fproducts\u002Fcustom-products","ua":"\u002Fproducts\u002Fcustom-products"},"page_id":111},
    name: "products-custom-products-111___ua"
  }, {
    path: "/ua/products/ethernet-switches",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-switches","lt":"\u002Fproduktai\u002Fsakotuvai","de":"\u002Fproducts\u002Fethernet-switches","es":"\u002Fproducts\u002Fethernet-switches","jp":"\u002Fproducts\u002Fethernet-switches","ua":"\u002Fproducts\u002Fethernet-switches"},"page_id":120},
    name: "products-ethernet-switches-120___ua"
  }, {
    path: "/ua/products/ethernet-wireless",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys","de":"\u002Fproducts\u002Fethernet-wireless","es":"\u002Fproducts\u002Fethernet-wireless","jp":"\u002Fproducts\u002Fethernet-wireless","ua":"\u002Fproducts\u002Fethernet-wireless"},"page_id":117},
    name: "products-ethernet-wireless-117___ua"
  }, {
    path: "/ua/products/gateways",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fgateways","lt":"\u002Fproduktai\u002Ftinklu-sietuvai","de":"\u002Fproducts\u002Fgateways","es":"\u002Fproducts\u002Fgateways","jp":"\u002Fproducts\u002Fgateways","ua":"\u002Fproducts\u002Fgateways"},"page_id":113},
    name: "products-gateways-113___ua"
  }, {
    path: "/ua/products/iot-platforms",
    component: _737c122a,
    meta: {"paths":{"en":"\u002Fproducts\u002Fiot-platforms","lt":"\u002Fproduktai\u002Fiot-platforms","de":"\u002Fproducts\u002Fiot-platforms","es":"\u002Fproducts\u002Fiot-platforms","jp":"\u002Fproducts\u002Fiot-platforms","ua":"\u002Fproducts\u002Fiot-platforms"},"page_id":121},
    name: "products-iot-platforms-121___ua"
  }, {
    path: "/ua/products/modems",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fmodems","lt":"\u002Fproduktai\u002Fmodemai","de":"\u002Fproducts\u002Fmodems","es":"\u002Fproducts\u002Fmodems","jp":"\u002Fproducts\u002Fmodems","ua":"\u002Fproducts\u002Fmodems"},"page_id":112},
    name: "products-modems-112___ua"
  }, {
    path: "/ua/products/routers",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters","lt":"\u002Fproduktai\u002Fmarsrutizatoriai","de":"\u002Fproducts\u002Frouters","es":"\u002Fproducts\u002Frouters","jp":"\u002Fproducts\u002Frouters","ua":"\u002Fproducts\u002Frouters"},"page_id":115},
    name: "products-routers-115___ua"
  }, {
    path: "/ua/resources/blogs",
    component: _2ae9892c,
    meta: {"paths":{"en":"\u002Fresources\u002Fblogs","lt":"\u002Fresursai\u002Fblogs","de":"\u002Fresources\u002Fblogs","es":"\u002Fresources\u002Fblogs","jp":"\u002Fresources\u002Fblogs","ua":"\u002Fresources\u002Fblogs"},"page_id":124},
    name: "resources-blogs-124___ua"
  }, {
    path: "/ua/resources/certification-program",
    component: _99d23f9e,
    meta: {"paths":{"en":"\u002Fresources\u002Fcertification-program","lt":"\u002Fresursai\u002Fsertifikavimo-programa","de":"\u002Fresources\u002Fcertification-program","es":"\u002Fresources\u002Fcertification-program","jp":"\u002Fresources\u002Fcertification-program","ua":"\u002Fresources\u002Fcertification-program"},"page_id":102},
    name: "resources-certification-program-102___ua"
  }, {
    path: "/ua/resources/share-your-solution",
    component: _a54c8f24,
    meta: {"paths":{"en":"\u002Fresources\u002Fshare-your-solution","lt":"\u002Fresursai\u002Fpasidalinkite-savo-sprendimu","de":"\u002Fresources\u002Fshare-your-solution","es":"\u002Fresources\u002Fshare-your-solution","jp":"\u002Fresources\u002Fshare-your-solution","ua":"\u002Fresources\u002Fshare-your-solution"},"page_id":109},
    name: "resources-share-your-solution-109___ua"
  }, {
    path: "/ua/resources/videos",
    component: _09273272,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos","lt":"\u002Fresursai\u002Fvaizdo-irasai","de":"\u002Fresources\u002Fvideos","es":"\u002Fresources\u002Fvideos","jp":"\u002Fresources\u002Fvideos","ua":"\u002Fresources\u002Fvideos"},"page_id":101},
    name: "resources-videos-101___ua"
  }, {
    path: "/ua/resources/webinars",
    component: _2e232d93,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars","lt":"\u002Fresursai\u002Fwebinarai","de":"\u002Fresources\u002Fwebinars","es":"\u002Fresources\u002Fwebinars","jp":"\u002Fresources\u002Fwebinars","ua":"\u002Fresources\u002Fwebinars"},"page_id":179},
    name: "resources-webinars-179___ua"
  }, {
    path: "/ua/support/eol-products",
    component: _c25f0f92,
    meta: {"paths":{"en":"\u002Fsupport\u002Feol-products","lt":"\u002Faptarnavimas\u002Fproduktu-gyvavimo-ciklo-pabaiga","de":"\u002Fsupport\u002Feol-products","es":"\u002Fsupport\u002Feol-products","jp":"\u002Fsupport\u002Feol-products","ua":"\u002Fsupport\u002Feol-products"},"page_id":107},
    name: "support-eol-products-107___ua"
  }, {
    path: "/ua/support/gpl-code-center",
    component: _6eb69794,
    meta: {"paths":{"en":"\u002Fsupport\u002Fgpl-code-center","lt":"\u002Faptarnavimas\u002Fgpl-atvirasis-kodas","de":"\u002Fsupport\u002Fgpl-code-center","es":"\u002Fsupport\u002Fgpl-code-center","jp":"\u002Fsupport\u002Fgpl-code-center","ua":"\u002Fsupport\u002Fgpl-code-center"},"page_id":108},
    name: "support-gpl-code-center-108___ua"
  }, {
    path: "/ua/support/product-support",
    component: _5c755c58,
    meta: {"paths":{"en":"\u002Fsupport\u002Fproduct-support","lt":"\u002Faptarnavimas\u002Fprodukto-aptarnavimas","de":"\u002Fsupport\u002Fproduct-support","es":"\u002Fsupport\u002Fproduct-support","jp":"\u002Fsupport\u002Fproduct-support","ua":"\u002Fsupport\u002Fproduct-support"},"page_id":105},
    name: "support-product-support-105___ua"
  }, {
    path: "/ua/support/security-centre",
    component: _626335ec,
    meta: {"paths":{"en":"\u002Fsupport\u002Fsecurity-centre","lt":"\u002Faptarnavimas\u002Fsaugumo-centras","de":"\u002Fsupport\u002Fsecurity-centre","es":"\u002Fsupport\u002Fsecurity-centre","jp":"\u002Fsupport\u002Fsecurity-centre","ua":"\u002Fsupport\u002Fsecurity-centre"},"page_id":99},
    name: "support-security-centre-99___ua"
  }, {
    path: "/ua/support/garantiia-ta-remont",
    component: _f4e2d386,
    meta: {"paths":{"en":"\u002Fsupport\u002Fwarranty-repair","lt":"\u002Faptarnavimas\u002Fgarantija-ir-remontas-1","de":"\u002Fsupport\u002Fgarantie-und-reparatur","es":"\u002Fsupport\u002Fgarantia-y-reparaciones","jp":"\u002Fsupport\u002Fwarranty-repair","ua":"\u002Fsupport\u002Fgarantiia-ta-remont"},"page_id":75},
    name: "support-warranty-repair-75___ua"
  }, {
    path: "/ua/use-cases/energy-utilities",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenergy-utilities","lt":"\u002Fvisi-panaudojimai\u002Fenergetika","de":"\u002Fuse-cases\u002Fenergy-utilities","es":"\u002Fuse-cases\u002Fenergy-utilities","jp":"\u002Fuse-cases\u002Fenergy-utilities","ua":"\u002Fuse-cases\u002Fenergy-utilities"},"page_id":91},
    name: "use-cases-energy-utilities-91___ua"
  }, {
    path: "/ua/use-cases/enterprise",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenterprise","lt":"\u002Fvisi-panaudojimai\u002Fverslo-sektorius","de":"\u002Fuse-cases\u002Fenterprise","es":"\u002Fuse-cases\u002Fenterprise","jp":"\u002Fuse-cases\u002Fenterprise","ua":"\u002Fuse-cases\u002Fenterprise"},"page_id":94},
    name: "use-cases-enterprise-94___ua"
  }, {
    path: "/ua/use-cases/industrial-automation",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Findustrial-automation","lt":"\u002Fvisi-panaudojimai\u002Fautomatizavimas","de":"\u002Fuse-cases\u002Findustrial-automation","es":"\u002Fuse-cases\u002Findustrial-automation","jp":"\u002Fuse-cases\u002Findustrial-automation","ua":"\u002Fuse-cases\u002Findustrial-automation"},"page_id":90},
    name: "use-cases-industrial-automation-90___ua"
  }, {
    path: "/ua/use-cases/retail",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fretail","lt":"\u002Fvisi-panaudojimai\u002Fprekybos-sektorius","de":"\u002Fuse-cases\u002Fretail","es":"\u002Fuse-cases\u002Fretail","jp":"\u002Fuse-cases\u002Fretail","ua":"\u002Fuse-cases\u002Fretail"},"page_id":95},
    name: "use-cases-retail-95___ua"
  }, {
    path: "/ua/use-cases/smart-city",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fsmart-city","lt":"\u002Fvisi-panaudojimai\u002Fismanus-miestas","de":"\u002Fuse-cases\u002Fsmart-city","es":"\u002Fuse-cases\u002Fsmart-city","jp":"\u002Fuse-cases\u002Fsmart-city","ua":"\u002Fuse-cases\u002Fsmart-city"},"page_id":92},
    name: "use-cases-smart-city-92___ua"
  }, {
    path: "/ua/use-cases/transportation",
    component: _fd754a6e,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftransportation","lt":"\u002Fvisi-panaudojimai\u002Ftransportas","de":"\u002Fuse-cases\u002Ftransportation","es":"\u002Fuse-cases\u002Ftransportation","jp":"\u002Fuse-cases\u002Ftransportation","ua":"\u002Fuse-cases\u002Ftransportation"},"page_id":93},
    name: "use-cases-transportation-93___ua"
  }, {
    path: "/de/products/accessories/3rd-party-solutions",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","lt":"\u002Fproduktai\u002Fpriedai\u002Ftreciuju-saliu-sprendimai","de":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","es":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","jp":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","ua":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions"},"page_id":130},
    name: "products-accessories-3rd-party-solutions-130___de"
  }, {
    path: "/de/products/accessories/antenna-options",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fantenna-options","lt":"\u002Fproduktai\u002Fpriedai\u002Fantenu-parinktys","de":"\u002Fproducts\u002Faccessories\u002Fantenna-options","es":"\u002Fproducts\u002Faccessories\u002Fantenna-options","jp":"\u002Fproducts\u002Faccessories\u002Fantenna-options","ua":"\u002Fproducts\u002Faccessories\u002Fantenna-options"},"page_id":127},
    name: "products-accessories-antenna-options-127___de"
  }, {
    path: "/de/products/accessories/mounting-options",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fmounting-options","lt":"\u002Fproduktai\u002Fpriedai\u002Fmontavimo-galimybes","de":"\u002Fproducts\u002Faccessories\u002Fmounting-options","es":"\u002Fproducts\u002Faccessories\u002Fmounting-options","jp":"\u002Fproducts\u002Faccessories\u002Fmounting-options","ua":"\u002Fproducts\u002Faccessories\u002Fmounting-options"},"page_id":128},
    name: "products-accessories-mounting-options-128___de"
  }, {
    path: "/de/products/accessories/other",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita","de":"\u002Fproducts\u002Faccessories\u002Fother","es":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":126},
    name: "products-accessories-other-126___de"
  }, {
    path: "/de/products/accessories/other-1",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother-1","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita-1","de":"\u002Fproducts\u002Faccessories\u002Fother-1","es":"\u002Fproducts\u002Faccessories\u002Fother-1","jp":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":129},
    name: "products-accessories-other-1-129___de"
  }, {
    path: "/de/products/accessories/other-2",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother-2","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita-2","de":"\u002Fproducts\u002Faccessories\u002Fother-2","es":"\u002Fproducts\u002Faccessories\u002Fother-2","jp":"\u002Fproducts\u002Faccessories\u002Fother-1","ua":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":131},
    name: "products-accessories-other-2-131___de"
  }, {
    path: "/de/products/accessories/powering-options",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpowering-options","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-priedai","de":"\u002Fproducts\u002Faccessories\u002Fpowering-options","es":"\u002Fproducts\u002Faccessories\u002Fpowering-options","jp":"\u002Fproducts\u002Faccessories\u002Fpowering-options","ua":"\u002Fproducts\u002Faccessories\u002Fpowering-options"},"page_id":125},
    name: "products-accessories-powering-options-125___de"
  }, {
    path: "/de/products/ethernet-wireless/routers-ethernet",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys\u002Frouters-ethernet","de":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","es":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","jp":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","ua":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet"},"page_id":134},
    name: "products-ethernet-wireless-routers-ethernet-134___de"
  }, {
    path: "/de/products/routers/ethernet-wireless-1",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1","lt":"\u002Fproduktai\u002Fmarsrutizatoriai\u002Fethernet-wireless","de":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1","es":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1","jp":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1"},"page_id":133},
    name: "products-routers-ethernet-wireless-1-133___de"
  }, {
    path: "/es/products/accessories/3rd-party-solutions",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","lt":"\u002Fproduktai\u002Fpriedai\u002Ftreciuju-saliu-sprendimai","de":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","es":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","jp":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","ua":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions"},"page_id":130},
    name: "products-accessories-3rd-party-solutions-130___es"
  }, {
    path: "/es/products/accessories/antenna-options",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fantenna-options","lt":"\u002Fproduktai\u002Fpriedai\u002Fantenu-parinktys","de":"\u002Fproducts\u002Faccessories\u002Fantenna-options","es":"\u002Fproducts\u002Faccessories\u002Fantenna-options","jp":"\u002Fproducts\u002Faccessories\u002Fantenna-options","ua":"\u002Fproducts\u002Faccessories\u002Fantenna-options"},"page_id":127},
    name: "products-accessories-antenna-options-127___es"
  }, {
    path: "/es/products/accessories/mounting-options",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fmounting-options","lt":"\u002Fproduktai\u002Fpriedai\u002Fmontavimo-galimybes","de":"\u002Fproducts\u002Faccessories\u002Fmounting-options","es":"\u002Fproducts\u002Faccessories\u002Fmounting-options","jp":"\u002Fproducts\u002Faccessories\u002Fmounting-options","ua":"\u002Fproducts\u002Faccessories\u002Fmounting-options"},"page_id":128},
    name: "products-accessories-mounting-options-128___es"
  }, {
    path: "/es/products/accessories/other",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita","de":"\u002Fproducts\u002Faccessories\u002Fother","es":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":126},
    name: "products-accessories-other-126___es"
  }, {
    path: "/es/products/accessories/other-1",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother-1","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita-1","de":"\u002Fproducts\u002Faccessories\u002Fother-1","es":"\u002Fproducts\u002Faccessories\u002Fother-1","jp":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":129},
    name: "products-accessories-other-1-129___es"
  }, {
    path: "/es/products/accessories/other-2",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother-2","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita-2","de":"\u002Fproducts\u002Faccessories\u002Fother-2","es":"\u002Fproducts\u002Faccessories\u002Fother-2","jp":"\u002Fproducts\u002Faccessories\u002Fother-1","ua":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":131},
    name: "products-accessories-other-2-131___es"
  }, {
    path: "/es/products/accessories/powering-options",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpowering-options","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-priedai","de":"\u002Fproducts\u002Faccessories\u002Fpowering-options","es":"\u002Fproducts\u002Faccessories\u002Fpowering-options","jp":"\u002Fproducts\u002Faccessories\u002Fpowering-options","ua":"\u002Fproducts\u002Faccessories\u002Fpowering-options"},"page_id":125},
    name: "products-accessories-powering-options-125___es"
  }, {
    path: "/es/products/ethernet-wireless/routers-ethernet",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys\u002Frouters-ethernet","de":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","es":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","jp":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","ua":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet"},"page_id":134},
    name: "products-ethernet-wireless-routers-ethernet-134___es"
  }, {
    path: "/es/products/routers/ethernet-wireless-1",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1","lt":"\u002Fproduktai\u002Fmarsrutizatoriai\u002Fethernet-wireless","de":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1","es":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1","jp":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1"},"page_id":133},
    name: "products-routers-ethernet-wireless-1-133___es"
  }, {
    path: "/jp/products/accessories/3rd-party-solutions",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","lt":"\u002Fproduktai\u002Fpriedai\u002Ftreciuju-saliu-sprendimai","de":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","es":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","jp":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","ua":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions"},"page_id":130},
    name: "products-accessories-3rd-party-solutions-130___jp"
  }, {
    path: "/jp/products/accessories/antenna-options",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fantenna-options","lt":"\u002Fproduktai\u002Fpriedai\u002Fantenu-parinktys","de":"\u002Fproducts\u002Faccessories\u002Fantenna-options","es":"\u002Fproducts\u002Faccessories\u002Fantenna-options","jp":"\u002Fproducts\u002Faccessories\u002Fantenna-options","ua":"\u002Fproducts\u002Faccessories\u002Fantenna-options"},"page_id":127},
    name: "products-accessories-antenna-options-127___jp"
  }, {
    path: "/jp/products/accessories/mounting-options",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fmounting-options","lt":"\u002Fproduktai\u002Fpriedai\u002Fmontavimo-galimybes","de":"\u002Fproducts\u002Faccessories\u002Fmounting-options","es":"\u002Fproducts\u002Faccessories\u002Fmounting-options","jp":"\u002Fproducts\u002Faccessories\u002Fmounting-options","ua":"\u002Fproducts\u002Faccessories\u002Fmounting-options"},"page_id":128},
    name: "products-accessories-mounting-options-128___jp"
  }, {
    path: "/jp/products/accessories/other",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita","de":"\u002Fproducts\u002Faccessories\u002Fother","es":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":126},
    name: "products-accessories-other-126___jp"
  }, {
    path: "/jp/products/accessories/other",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother-1","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita-1","de":"\u002Fproducts\u002Faccessories\u002Fother-1","es":"\u002Fproducts\u002Faccessories\u002Fother-1","jp":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":129},
    name: "products-accessories-other-1-129___jp"
  }, {
    path: "/jp/products/accessories/other-1",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother-2","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita-2","de":"\u002Fproducts\u002Faccessories\u002Fother-2","es":"\u002Fproducts\u002Faccessories\u002Fother-2","jp":"\u002Fproducts\u002Faccessories\u002Fother-1","ua":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":131},
    name: "products-accessories-other-2-131___jp"
  }, {
    path: "/jp/products/accessories/powering-options",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpowering-options","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-priedai","de":"\u002Fproducts\u002Faccessories\u002Fpowering-options","es":"\u002Fproducts\u002Faccessories\u002Fpowering-options","jp":"\u002Fproducts\u002Faccessories\u002Fpowering-options","ua":"\u002Fproducts\u002Faccessories\u002Fpowering-options"},"page_id":125},
    name: "products-accessories-powering-options-125___jp"
  }, {
    path: "/jp/products/ethernet-wireless/routers-ethernet",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys\u002Frouters-ethernet","de":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","es":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","jp":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","ua":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet"},"page_id":134},
    name: "products-ethernet-wireless-routers-ethernet-134___jp"
  }, {
    path: "/jp/products/routers/ethernet-wireless-1",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1","lt":"\u002Fproduktai\u002Fmarsrutizatoriai\u002Fethernet-wireless","de":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1","es":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1","jp":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1"},"page_id":133},
    name: "products-routers-ethernet-wireless-1-133___jp"
  }, {
    path: "/lt/produktai/priedai/treciuju-saliu-sprendimai",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","lt":"\u002Fproduktai\u002Fpriedai\u002Ftreciuju-saliu-sprendimai","de":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","es":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","jp":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","ua":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions"},"page_id":130},
    name: "products-accessories-3rd-party-solutions-130___lt"
  }, {
    path: "/lt/produktai/priedai/antenu-parinktys",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fantenna-options","lt":"\u002Fproduktai\u002Fpriedai\u002Fantenu-parinktys","de":"\u002Fproducts\u002Faccessories\u002Fantenna-options","es":"\u002Fproducts\u002Faccessories\u002Fantenna-options","jp":"\u002Fproducts\u002Faccessories\u002Fantenna-options","ua":"\u002Fproducts\u002Faccessories\u002Fantenna-options"},"page_id":127},
    name: "products-accessories-antenna-options-127___lt"
  }, {
    path: "/lt/produktai/priedai/montavimo-galimybes",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fmounting-options","lt":"\u002Fproduktai\u002Fpriedai\u002Fmontavimo-galimybes","de":"\u002Fproducts\u002Faccessories\u002Fmounting-options","es":"\u002Fproducts\u002Faccessories\u002Fmounting-options","jp":"\u002Fproducts\u002Faccessories\u002Fmounting-options","ua":"\u002Fproducts\u002Faccessories\u002Fmounting-options"},"page_id":128},
    name: "products-accessories-mounting-options-128___lt"
  }, {
    path: "/lt/produktai/priedai/kita",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita","de":"\u002Fproducts\u002Faccessories\u002Fother","es":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":126},
    name: "products-accessories-other-126___lt"
  }, {
    path: "/lt/produktai/priedai/kita-1",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother-1","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita-1","de":"\u002Fproducts\u002Faccessories\u002Fother-1","es":"\u002Fproducts\u002Faccessories\u002Fother-1","jp":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":129},
    name: "products-accessories-other-1-129___lt"
  }, {
    path: "/lt/produktai/priedai/kita-2",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother-2","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita-2","de":"\u002Fproducts\u002Faccessories\u002Fother-2","es":"\u002Fproducts\u002Faccessories\u002Fother-2","jp":"\u002Fproducts\u002Faccessories\u002Fother-1","ua":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":131},
    name: "products-accessories-other-2-131___lt"
  }, {
    path: "/lt/produktai/priedai/maitinimo-priedai",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpowering-options","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-priedai","de":"\u002Fproducts\u002Faccessories\u002Fpowering-options","es":"\u002Fproducts\u002Faccessories\u002Fpowering-options","jp":"\u002Fproducts\u002Faccessories\u002Fpowering-options","ua":"\u002Fproducts\u002Faccessories\u002Fpowering-options"},"page_id":125},
    name: "products-accessories-powering-options-125___lt"
  }, {
    path: "/lt/produktai/eternetas-ir-belaidis-rysys/routers-ethernet",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys\u002Frouters-ethernet","de":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","es":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","jp":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","ua":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet"},"page_id":134},
    name: "products-ethernet-wireless-routers-ethernet-134___lt"
  }, {
    path: "/lt/produktai/marsrutizatoriai/ethernet-wireless",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1","lt":"\u002Fproduktai\u002Fmarsrutizatoriai\u002Fethernet-wireless","de":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1","es":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1","jp":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1"},"page_id":133},
    name: "products-routers-ethernet-wireless-1-133___lt"
  }, {
    path: "/ua/products/accessories/3rd-party-solutions",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","lt":"\u002Fproduktai\u002Fpriedai\u002Ftreciuju-saliu-sprendimai","de":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","es":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","jp":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions","ua":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions"},"page_id":130},
    name: "products-accessories-3rd-party-solutions-130___ua"
  }, {
    path: "/ua/products/accessories/antenna-options",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fantenna-options","lt":"\u002Fproduktai\u002Fpriedai\u002Fantenu-parinktys","de":"\u002Fproducts\u002Faccessories\u002Fantenna-options","es":"\u002Fproducts\u002Faccessories\u002Fantenna-options","jp":"\u002Fproducts\u002Faccessories\u002Fantenna-options","ua":"\u002Fproducts\u002Faccessories\u002Fantenna-options"},"page_id":127},
    name: "products-accessories-antenna-options-127___ua"
  }, {
    path: "/ua/products/accessories/mounting-options",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fmounting-options","lt":"\u002Fproduktai\u002Fpriedai\u002Fmontavimo-galimybes","de":"\u002Fproducts\u002Faccessories\u002Fmounting-options","es":"\u002Fproducts\u002Faccessories\u002Fmounting-options","jp":"\u002Fproducts\u002Faccessories\u002Fmounting-options","ua":"\u002Fproducts\u002Faccessories\u002Fmounting-options"},"page_id":128},
    name: "products-accessories-mounting-options-128___ua"
  }, {
    path: "/ua/products/accessories/other",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita","de":"\u002Fproducts\u002Faccessories\u002Fother","es":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":126},
    name: "products-accessories-other-126___ua"
  }, {
    path: "/ua/products/accessories/other-1",
    component: _ea1bd202,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother-1","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita-1","de":"\u002Fproducts\u002Faccessories\u002Fother-1","es":"\u002Fproducts\u002Faccessories\u002Fother-1","jp":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":129},
    name: "products-accessories-other-1-129___ua"
  }, {
    path: "/ua/products/accessories/other",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother-2","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita-2","de":"\u002Fproducts\u002Faccessories\u002Fother-2","es":"\u002Fproducts\u002Faccessories\u002Fother-2","jp":"\u002Fproducts\u002Faccessories\u002Fother-1","ua":"\u002Fproducts\u002Faccessories\u002Fother"},"page_id":131},
    name: "products-accessories-other-2-131___ua"
  }, {
    path: "/ua/products/accessories/powering-options",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpowering-options","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-priedai","de":"\u002Fproducts\u002Faccessories\u002Fpowering-options","es":"\u002Fproducts\u002Faccessories\u002Fpowering-options","jp":"\u002Fproducts\u002Faccessories\u002Fpowering-options","ua":"\u002Fproducts\u002Faccessories\u002Fpowering-options"},"page_id":125},
    name: "products-accessories-powering-options-125___ua"
  }, {
    path: "/ua/products/ethernet-wireless/routers-ethernet",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys\u002Frouters-ethernet","de":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","es":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","jp":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet","ua":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet"},"page_id":134},
    name: "products-ethernet-wireless-routers-ethernet-134___ua"
  }, {
    path: "/ua/products/routers/ethernet-wireless-1",
    component: _755bf3d3,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1","lt":"\u002Fproduktai\u002Fmarsrutizatoriai\u002Fethernet-wireless","de":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1","es":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1","jp":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1"},"page_id":133},
    name: "products-routers-ethernet-wireless-1-133___ua"
  }, {
    path: "/",
    component: _20752c4f,
    meta: {"paths":{"en":"\u002F","lt":"\u002F","de":"\u002F","es":"\u002F","jp":"\u002F","ua":"\u002F"},"page_id":3},
    name: "teltonika-networks.com-landing-3___en"
  }, {
    path: "/de/products/accessories/3rd-party-solutions/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Ftreciuju-saliu-sprendimai\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug"},"page_id":130},
    name: "products-accessories-3rd-party-solutions-:slug-130-slug___de"
  }, {
    path: "/de/products/accessories/antenna-options/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fantenu-parinktys\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug"},"page_id":127},
    name: "products-accessories-antenna-options-:slug-127-slug___de"
  }, {
    path: "/de/products/accessories/mounting-options/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fmontavimo-galimybes\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug"},"page_id":128},
    name: "products-accessories-mounting-options-:slug-128-slug___de"
  }, {
    path: "/de/products/accessories/other-2/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother-2\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita-2\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fother-2\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fother-2\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fother-1\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug"},"page_id":131},
    name: "products-accessories-other-2-:slug-131-slug___de"
  }, {
    path: "/de/products/accessories/powering-options/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-priedai\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug"},"page_id":125},
    name: "products-accessories-powering-options-:slug-125-slug___de"
  }, {
    path: "/de/products/ethernet-wireless/routers-ethernet/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys\u002Frouters-ethernet\u002F:slug","de":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","es":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","jp":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","ua":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug"},"page_id":134},
    name: "products-ethernet-wireless-routers-ethernet-:slug-134-slug___de"
  }, {
    path: "/de/products/routers/ethernet-wireless-1/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug","lt":"\u002Fproduktai\u002Fmarsrutizatoriai\u002Fethernet-wireless\u002F:slug","de":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug","es":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug","jp":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug"},"page_id":133},
    name: "products-routers-ethernet-wireless-1-:slug-133-slug___de"
  }, {
    path: "/es/products/accessories/3rd-party-solutions/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Ftreciuju-saliu-sprendimai\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug"},"page_id":130},
    name: "products-accessories-3rd-party-solutions-:slug-130-slug___es"
  }, {
    path: "/es/products/accessories/antenna-options/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fantenu-parinktys\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug"},"page_id":127},
    name: "products-accessories-antenna-options-:slug-127-slug___es"
  }, {
    path: "/es/products/accessories/mounting-options/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fmontavimo-galimybes\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug"},"page_id":128},
    name: "products-accessories-mounting-options-:slug-128-slug___es"
  }, {
    path: "/es/products/accessories/other-2/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother-2\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita-2\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fother-2\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fother-2\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fother-1\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug"},"page_id":131},
    name: "products-accessories-other-2-:slug-131-slug___es"
  }, {
    path: "/es/products/accessories/powering-options/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-priedai\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug"},"page_id":125},
    name: "products-accessories-powering-options-:slug-125-slug___es"
  }, {
    path: "/es/products/ethernet-wireless/routers-ethernet/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys\u002Frouters-ethernet\u002F:slug","de":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","es":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","jp":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","ua":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug"},"page_id":134},
    name: "products-ethernet-wireless-routers-ethernet-:slug-134-slug___es"
  }, {
    path: "/es/products/routers/ethernet-wireless-1/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug","lt":"\u002Fproduktai\u002Fmarsrutizatoriai\u002Fethernet-wireless\u002F:slug","de":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug","es":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug","jp":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug"},"page_id":133},
    name: "products-routers-ethernet-wireless-1-:slug-133-slug___es"
  }, {
    path: "/jp/products/accessories/3rd-party-solutions/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Ftreciuju-saliu-sprendimai\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug"},"page_id":130},
    name: "products-accessories-3rd-party-solutions-:slug-130-slug___jp"
  }, {
    path: "/jp/products/accessories/antenna-options/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fantenu-parinktys\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug"},"page_id":127},
    name: "products-accessories-antenna-options-:slug-127-slug___jp"
  }, {
    path: "/jp/products/accessories/mounting-options/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fmontavimo-galimybes\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug"},"page_id":128},
    name: "products-accessories-mounting-options-:slug-128-slug___jp"
  }, {
    path: "/jp/products/accessories/other-1/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother-2\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita-2\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fother-2\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fother-2\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fother-1\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug"},"page_id":131},
    name: "products-accessories-other-2-:slug-131-slug___jp"
  }, {
    path: "/jp/products/accessories/powering-options/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-priedai\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug"},"page_id":125},
    name: "products-accessories-powering-options-:slug-125-slug___jp"
  }, {
    path: "/jp/products/ethernet-wireless/routers-ethernet/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys\u002Frouters-ethernet\u002F:slug","de":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","es":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","jp":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","ua":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug"},"page_id":134},
    name: "products-ethernet-wireless-routers-ethernet-:slug-134-slug___jp"
  }, {
    path: "/jp/products/routers/ethernet-wireless-1/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug","lt":"\u002Fproduktai\u002Fmarsrutizatoriai\u002Fethernet-wireless\u002F:slug","de":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug","es":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug","jp":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug"},"page_id":133},
    name: "products-routers-ethernet-wireless-1-:slug-133-slug___jp"
  }, {
    path: "/lt/produktai/priedai/treciuju-saliu-sprendimai/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Ftreciuju-saliu-sprendimai\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug"},"page_id":130},
    name: "products-accessories-3rd-party-solutions-:slug-130-slug___lt"
  }, {
    path: "/lt/produktai/priedai/antenu-parinktys/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fantenu-parinktys\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug"},"page_id":127},
    name: "products-accessories-antenna-options-:slug-127-slug___lt"
  }, {
    path: "/lt/produktai/priedai/montavimo-galimybes/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fmontavimo-galimybes\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug"},"page_id":128},
    name: "products-accessories-mounting-options-:slug-128-slug___lt"
  }, {
    path: "/lt/produktai/priedai/kita-2/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother-2\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita-2\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fother-2\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fother-2\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fother-1\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug"},"page_id":131},
    name: "products-accessories-other-2-:slug-131-slug___lt"
  }, {
    path: "/lt/produktai/priedai/maitinimo-priedai/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-priedai\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug"},"page_id":125},
    name: "products-accessories-powering-options-:slug-125-slug___lt"
  }, {
    path: "/lt/produktai/eternetas-ir-belaidis-rysys/routers-ethernet/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys\u002Frouters-ethernet\u002F:slug","de":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","es":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","jp":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","ua":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug"},"page_id":134},
    name: "products-ethernet-wireless-routers-ethernet-:slug-134-slug___lt"
  }, {
    path: "/lt/produktai/marsrutizatoriai/ethernet-wireless/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug","lt":"\u002Fproduktai\u002Fmarsrutizatoriai\u002Fethernet-wireless\u002F:slug","de":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug","es":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug","jp":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug"},"page_id":133},
    name: "products-routers-ethernet-wireless-1-:slug-133-slug___lt"
  }, {
    path: "/ua/products/accessories/3rd-party-solutions/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Ftreciuju-saliu-sprendimai\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug"},"page_id":130},
    name: "products-accessories-3rd-party-solutions-:slug-130-slug___ua"
  }, {
    path: "/ua/products/accessories/antenna-options/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fantenu-parinktys\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug"},"page_id":127},
    name: "products-accessories-antenna-options-:slug-127-slug___ua"
  }, {
    path: "/ua/products/accessories/mounting-options/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fmontavimo-galimybes\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug"},"page_id":128},
    name: "products-accessories-mounting-options-:slug-128-slug___ua"
  }, {
    path: "/ua/products/accessories/other/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother-2\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita-2\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fother-2\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fother-2\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fother-1\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug"},"page_id":131},
    name: "products-accessories-other-2-:slug-131-slug___ua"
  }, {
    path: "/ua/products/accessories/powering-options/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-priedai\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug"},"page_id":125},
    name: "products-accessories-powering-options-:slug-125-slug___ua"
  }, {
    path: "/ua/products/ethernet-wireless/routers-ethernet/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys\u002Frouters-ethernet\u002F:slug","de":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","es":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","jp":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","ua":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug"},"page_id":134},
    name: "products-ethernet-wireless-routers-ethernet-:slug-134-slug___ua"
  }, {
    path: "/ua/products/routers/ethernet-wireless-1/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug","lt":"\u002Fproduktai\u002Fmarsrutizatoriai\u002Fethernet-wireless\u002F:slug","de":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug","es":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug","jp":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug"},"page_id":133},
    name: "products-routers-ethernet-wireless-1-:slug-133-slug___ua"
  }, {
    path: "/de/about-us/policies-certificates/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug","de":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","es":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","jp":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","ua":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___de"
  }, {
    path: "/de/products/access-points/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccess-points\u002F:slug","lt":"\u002Fproduktai\u002Faccess-points\u002F:slug","de":"\u002Fproducts\u002Faccess-points\u002F:slug","es":"\u002Fproducts\u002Faccess-points\u002F:slug","jp":"\u002Fproducts\u002Faccess-points\u002F:slug","ua":"\u002Fproducts\u002Faccess-points\u002F:slug"},"page_id":182},
    name: "products-access-points-:slug-182-slug___de"
  }, {
    path: "/de/products/accessories/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002F:slug"},"page_id":116},
    name: "products-accessories-:slug-116-slug___de"
  }, {
    path: "/de/products/cellular-iot/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fcellular-iot\u002F:slug","lt":"\u002Fproduktai\u002Fmobilus-iot\u002F:slug","de":"\u002Fproducts\u002Fcellular-iot\u002F:slug","es":"\u002Fproducts\u002Fcellular-iot\u002F:slug","jp":"\u002Fproducts\u002Fcellular-iot\u002F:slug","ua":"\u002Fproducts\u002Fcellular-iot\u002F:slug"},"page_id":110},
    name: "products-cellular-iot-:slug-110-slug___de"
  }, {
    path: "/de/products/ethernet-switches/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-switches\u002F:slug","lt":"\u002Fproduktai\u002Fsakotuvai\u002F:slug","de":"\u002Fproducts\u002Fethernet-switches\u002F:slug","es":"\u002Fproducts\u002Fethernet-switches\u002F:slug","jp":"\u002Fproducts\u002Fethernet-switches\u002F:slug","ua":"\u002Fproducts\u002Fethernet-switches\u002F:slug"},"page_id":120},
    name: "products-ethernet-switches-:slug-120-slug___de"
  }, {
    path: "/de/products/ethernet-wireless/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys\u002F:slug","de":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","es":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","jp":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","ua":"\u002Fproducts\u002Fethernet-wireless\u002F:slug"},"page_id":117},
    name: "products-ethernet-wireless-:slug-117-slug___de"
  }, {
    path: "/de/products/gateways/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fgateways\u002F:slug","lt":"\u002Fproduktai\u002Ftinklu-sietuvai\u002F:slug","de":"\u002Fproducts\u002Fgateways\u002F:slug","es":"\u002Fproducts\u002Fgateways\u002F:slug","jp":"\u002Fproducts\u002Fgateways\u002F:slug","ua":"\u002Fproducts\u002Fgateways\u002F:slug"},"page_id":113},
    name: "products-gateways-:slug-113-slug___de"
  }, {
    path: "/de/products/modems/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fmodems\u002F:slug","lt":"\u002Fproduktai\u002Fmodemai\u002F:slug","de":"\u002Fproducts\u002Fmodems\u002F:slug","es":"\u002Fproducts\u002Fmodems\u002F:slug","jp":"\u002Fproducts\u002Fmodems\u002F:slug","ua":"\u002Fproducts\u002Fmodems\u002F:slug"},"page_id":112},
    name: "products-modems-:slug-112-slug___de"
  }, {
    path: "/de/products/routers/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters\u002F:slug","lt":"\u002Fproduktai\u002Fmarsrutizatoriai\u002F:slug","de":"\u002Fproducts\u002Frouters\u002F:slug","es":"\u002Fproducts\u002Frouters\u002F:slug","jp":"\u002Fproducts\u002Frouters\u002F:slug","ua":"\u002Fproducts\u002Frouters\u002F:slug"},"page_id":115},
    name: "products-routers-:slug-115-slug___de"
  }, {
    path: "/de/resources/blogs/:slug",
    component: _2932e7e4,
    meta: {"paths":{"en":"\u002Fresources\u002Fblogs\u002F:slug","lt":"\u002Fresursai\u002Fblogs\u002F:slug","de":"\u002Fresources\u002Fblogs\u002F:slug","es":"\u002Fresources\u002Fblogs\u002F:slug","jp":"\u002Fresources\u002Fblogs\u002F:slug","ua":"\u002Fresources\u002Fblogs\u002F:slug"},"page_id":124},
    name: "resources-blogs-:slug-124-slug___de"
  }, {
    path: "/de/resources/videos/:slug",
    component: _0c947502,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos\u002F:slug","lt":"\u002Fresursai\u002Fvaizdo-irasai\u002F:slug","de":"\u002Fresources\u002Fvideos\u002F:slug","es":"\u002Fresources\u002Fvideos\u002F:slug","jp":"\u002Fresources\u002Fvideos\u002F:slug","ua":"\u002Fresources\u002Fvideos\u002F:slug"},"page_id":101},
    name: "resources-videos-:slug-101-slug___de"
  }, {
    path: "/de/resources/webinars/:slug",
    component: _2c6c8c4b,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars\u002F:slug","lt":"\u002Fresursai\u002Fwebinarai\u002F:slug","de":"\u002Fresources\u002Fwebinars\u002F:slug","es":"\u002Fresources\u002Fwebinars\u002F:slug","jp":"\u002Fresources\u002Fwebinars\u002F:slug","ua":"\u002Fresources\u002Fwebinars\u002F:slug"},"page_id":179},
    name: "resources-webinars-:slug-179-slug___de"
  }, {
    path: "/de/use-cases/energy-utilities/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fenergetika\u002F:slug","de":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","es":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","jp":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","ua":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug"},"page_id":91},
    name: "use-cases-energy-utilities-:slug-91-slug___de"
  }, {
    path: "/de/use-cases/enterprise/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenterprise\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fverslo-sektorius\u002F:slug","de":"\u002Fuse-cases\u002Fenterprise\u002F:slug","es":"\u002Fuse-cases\u002Fenterprise\u002F:slug","jp":"\u002Fuse-cases\u002Fenterprise\u002F:slug","ua":"\u002Fuse-cases\u002Fenterprise\u002F:slug"},"page_id":94},
    name: "use-cases-enterprise-:slug-94-slug___de"
  }, {
    path: "/de/use-cases/industrial-automation/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fautomatizavimas\u002F:slug","de":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","es":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","jp":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","ua":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug"},"page_id":90},
    name: "use-cases-industrial-automation-:slug-90-slug___de"
  }, {
    path: "/de/use-cases/retail/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fretail\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fprekybos-sektorius\u002F:slug","de":"\u002Fuse-cases\u002Fretail\u002F:slug","es":"\u002Fuse-cases\u002Fretail\u002F:slug","jp":"\u002Fuse-cases\u002Fretail\u002F:slug","ua":"\u002Fuse-cases\u002Fretail\u002F:slug"},"page_id":95},
    name: "use-cases-retail-:slug-95-slug___de"
  }, {
    path: "/de/use-cases/smart-city/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fismanus-miestas\u002F:slug","de":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","es":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","jp":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","ua":"\u002Fuse-cases\u002Fsmart-city\u002F:slug"},"page_id":92},
    name: "use-cases-smart-city-:slug-92-slug___de"
  }, {
    path: "/de/use-cases/transportation/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftransportation\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Ftransportas\u002F:slug","de":"\u002Fuse-cases\u002Ftransportation\u002F:slug","es":"\u002Fuse-cases\u002Ftransportation\u002F:slug","jp":"\u002Fuse-cases\u002Ftransportation\u002F:slug","ua":"\u002Fuse-cases\u002Ftransportation\u002F:slug"},"page_id":93},
    name: "use-cases-transportation-:slug-93-slug___de"
  }, {
    path: "/es/about-us/policies-certificates/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug","de":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","es":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","jp":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","ua":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___es"
  }, {
    path: "/es/products/access-points/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccess-points\u002F:slug","lt":"\u002Fproduktai\u002Faccess-points\u002F:slug","de":"\u002Fproducts\u002Faccess-points\u002F:slug","es":"\u002Fproducts\u002Faccess-points\u002F:slug","jp":"\u002Fproducts\u002Faccess-points\u002F:slug","ua":"\u002Fproducts\u002Faccess-points\u002F:slug"},"page_id":182},
    name: "products-access-points-:slug-182-slug___es"
  }, {
    path: "/es/products/accessories/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002F:slug"},"page_id":116},
    name: "products-accessories-:slug-116-slug___es"
  }, {
    path: "/es/products/cellular-iot/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fcellular-iot\u002F:slug","lt":"\u002Fproduktai\u002Fmobilus-iot\u002F:slug","de":"\u002Fproducts\u002Fcellular-iot\u002F:slug","es":"\u002Fproducts\u002Fcellular-iot\u002F:slug","jp":"\u002Fproducts\u002Fcellular-iot\u002F:slug","ua":"\u002Fproducts\u002Fcellular-iot\u002F:slug"},"page_id":110},
    name: "products-cellular-iot-:slug-110-slug___es"
  }, {
    path: "/es/products/ethernet-switches/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-switches\u002F:slug","lt":"\u002Fproduktai\u002Fsakotuvai\u002F:slug","de":"\u002Fproducts\u002Fethernet-switches\u002F:slug","es":"\u002Fproducts\u002Fethernet-switches\u002F:slug","jp":"\u002Fproducts\u002Fethernet-switches\u002F:slug","ua":"\u002Fproducts\u002Fethernet-switches\u002F:slug"},"page_id":120},
    name: "products-ethernet-switches-:slug-120-slug___es"
  }, {
    path: "/es/products/ethernet-wireless/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys\u002F:slug","de":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","es":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","jp":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","ua":"\u002Fproducts\u002Fethernet-wireless\u002F:slug"},"page_id":117},
    name: "products-ethernet-wireless-:slug-117-slug___es"
  }, {
    path: "/es/products/gateways/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fgateways\u002F:slug","lt":"\u002Fproduktai\u002Ftinklu-sietuvai\u002F:slug","de":"\u002Fproducts\u002Fgateways\u002F:slug","es":"\u002Fproducts\u002Fgateways\u002F:slug","jp":"\u002Fproducts\u002Fgateways\u002F:slug","ua":"\u002Fproducts\u002Fgateways\u002F:slug"},"page_id":113},
    name: "products-gateways-:slug-113-slug___es"
  }, {
    path: "/es/products/modems/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fmodems\u002F:slug","lt":"\u002Fproduktai\u002Fmodemai\u002F:slug","de":"\u002Fproducts\u002Fmodems\u002F:slug","es":"\u002Fproducts\u002Fmodems\u002F:slug","jp":"\u002Fproducts\u002Fmodems\u002F:slug","ua":"\u002Fproducts\u002Fmodems\u002F:slug"},"page_id":112},
    name: "products-modems-:slug-112-slug___es"
  }, {
    path: "/es/products/routers/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters\u002F:slug","lt":"\u002Fproduktai\u002Fmarsrutizatoriai\u002F:slug","de":"\u002Fproducts\u002Frouters\u002F:slug","es":"\u002Fproducts\u002Frouters\u002F:slug","jp":"\u002Fproducts\u002Frouters\u002F:slug","ua":"\u002Fproducts\u002Frouters\u002F:slug"},"page_id":115},
    name: "products-routers-:slug-115-slug___es"
  }, {
    path: "/es/resources/blogs/:slug",
    component: _2932e7e4,
    meta: {"paths":{"en":"\u002Fresources\u002Fblogs\u002F:slug","lt":"\u002Fresursai\u002Fblogs\u002F:slug","de":"\u002Fresources\u002Fblogs\u002F:slug","es":"\u002Fresources\u002Fblogs\u002F:slug","jp":"\u002Fresources\u002Fblogs\u002F:slug","ua":"\u002Fresources\u002Fblogs\u002F:slug"},"page_id":124},
    name: "resources-blogs-:slug-124-slug___es"
  }, {
    path: "/es/resources/videos/:slug",
    component: _0c947502,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos\u002F:slug","lt":"\u002Fresursai\u002Fvaizdo-irasai\u002F:slug","de":"\u002Fresources\u002Fvideos\u002F:slug","es":"\u002Fresources\u002Fvideos\u002F:slug","jp":"\u002Fresources\u002Fvideos\u002F:slug","ua":"\u002Fresources\u002Fvideos\u002F:slug"},"page_id":101},
    name: "resources-videos-:slug-101-slug___es"
  }, {
    path: "/es/resources/webinars/:slug",
    component: _2c6c8c4b,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars\u002F:slug","lt":"\u002Fresursai\u002Fwebinarai\u002F:slug","de":"\u002Fresources\u002Fwebinars\u002F:slug","es":"\u002Fresources\u002Fwebinars\u002F:slug","jp":"\u002Fresources\u002Fwebinars\u002F:slug","ua":"\u002Fresources\u002Fwebinars\u002F:slug"},"page_id":179},
    name: "resources-webinars-:slug-179-slug___es"
  }, {
    path: "/es/use-cases/energy-utilities/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fenergetika\u002F:slug","de":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","es":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","jp":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","ua":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug"},"page_id":91},
    name: "use-cases-energy-utilities-:slug-91-slug___es"
  }, {
    path: "/es/use-cases/enterprise/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenterprise\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fverslo-sektorius\u002F:slug","de":"\u002Fuse-cases\u002Fenterprise\u002F:slug","es":"\u002Fuse-cases\u002Fenterprise\u002F:slug","jp":"\u002Fuse-cases\u002Fenterprise\u002F:slug","ua":"\u002Fuse-cases\u002Fenterprise\u002F:slug"},"page_id":94},
    name: "use-cases-enterprise-:slug-94-slug___es"
  }, {
    path: "/es/use-cases/industrial-automation/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fautomatizavimas\u002F:slug","de":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","es":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","jp":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","ua":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug"},"page_id":90},
    name: "use-cases-industrial-automation-:slug-90-slug___es"
  }, {
    path: "/es/use-cases/retail/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fretail\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fprekybos-sektorius\u002F:slug","de":"\u002Fuse-cases\u002Fretail\u002F:slug","es":"\u002Fuse-cases\u002Fretail\u002F:slug","jp":"\u002Fuse-cases\u002Fretail\u002F:slug","ua":"\u002Fuse-cases\u002Fretail\u002F:slug"},"page_id":95},
    name: "use-cases-retail-:slug-95-slug___es"
  }, {
    path: "/es/use-cases/smart-city/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fismanus-miestas\u002F:slug","de":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","es":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","jp":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","ua":"\u002Fuse-cases\u002Fsmart-city\u002F:slug"},"page_id":92},
    name: "use-cases-smart-city-:slug-92-slug___es"
  }, {
    path: "/es/use-cases/transportation/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftransportation\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Ftransportas\u002F:slug","de":"\u002Fuse-cases\u002Ftransportation\u002F:slug","es":"\u002Fuse-cases\u002Ftransportation\u002F:slug","jp":"\u002Fuse-cases\u002Ftransportation\u002F:slug","ua":"\u002Fuse-cases\u002Ftransportation\u002F:slug"},"page_id":93},
    name: "use-cases-transportation-:slug-93-slug___es"
  }, {
    path: "/jp/about-us/policies-certificates/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug","de":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","es":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","jp":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","ua":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___jp"
  }, {
    path: "/jp/products/access-points/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccess-points\u002F:slug","lt":"\u002Fproduktai\u002Faccess-points\u002F:slug","de":"\u002Fproducts\u002Faccess-points\u002F:slug","es":"\u002Fproducts\u002Faccess-points\u002F:slug","jp":"\u002Fproducts\u002Faccess-points\u002F:slug","ua":"\u002Fproducts\u002Faccess-points\u002F:slug"},"page_id":182},
    name: "products-access-points-:slug-182-slug___jp"
  }, {
    path: "/jp/products/accessories/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002F:slug"},"page_id":116},
    name: "products-accessories-:slug-116-slug___jp"
  }, {
    path: "/jp/products/cellular-iot/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fcellular-iot\u002F:slug","lt":"\u002Fproduktai\u002Fmobilus-iot\u002F:slug","de":"\u002Fproducts\u002Fcellular-iot\u002F:slug","es":"\u002Fproducts\u002Fcellular-iot\u002F:slug","jp":"\u002Fproducts\u002Fcellular-iot\u002F:slug","ua":"\u002Fproducts\u002Fcellular-iot\u002F:slug"},"page_id":110},
    name: "products-cellular-iot-:slug-110-slug___jp"
  }, {
    path: "/jp/products/ethernet-switches/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-switches\u002F:slug","lt":"\u002Fproduktai\u002Fsakotuvai\u002F:slug","de":"\u002Fproducts\u002Fethernet-switches\u002F:slug","es":"\u002Fproducts\u002Fethernet-switches\u002F:slug","jp":"\u002Fproducts\u002Fethernet-switches\u002F:slug","ua":"\u002Fproducts\u002Fethernet-switches\u002F:slug"},"page_id":120},
    name: "products-ethernet-switches-:slug-120-slug___jp"
  }, {
    path: "/jp/products/ethernet-wireless/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys\u002F:slug","de":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","es":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","jp":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","ua":"\u002Fproducts\u002Fethernet-wireless\u002F:slug"},"page_id":117},
    name: "products-ethernet-wireless-:slug-117-slug___jp"
  }, {
    path: "/jp/products/gateways/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fgateways\u002F:slug","lt":"\u002Fproduktai\u002Ftinklu-sietuvai\u002F:slug","de":"\u002Fproducts\u002Fgateways\u002F:slug","es":"\u002Fproducts\u002Fgateways\u002F:slug","jp":"\u002Fproducts\u002Fgateways\u002F:slug","ua":"\u002Fproducts\u002Fgateways\u002F:slug"},"page_id":113},
    name: "products-gateways-:slug-113-slug___jp"
  }, {
    path: "/jp/products/modems/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fmodems\u002F:slug","lt":"\u002Fproduktai\u002Fmodemai\u002F:slug","de":"\u002Fproducts\u002Fmodems\u002F:slug","es":"\u002Fproducts\u002Fmodems\u002F:slug","jp":"\u002Fproducts\u002Fmodems\u002F:slug","ua":"\u002Fproducts\u002Fmodems\u002F:slug"},"page_id":112},
    name: "products-modems-:slug-112-slug___jp"
  }, {
    path: "/jp/products/routers/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters\u002F:slug","lt":"\u002Fproduktai\u002Fmarsrutizatoriai\u002F:slug","de":"\u002Fproducts\u002Frouters\u002F:slug","es":"\u002Fproducts\u002Frouters\u002F:slug","jp":"\u002Fproducts\u002Frouters\u002F:slug","ua":"\u002Fproducts\u002Frouters\u002F:slug"},"page_id":115},
    name: "products-routers-:slug-115-slug___jp"
  }, {
    path: "/jp/resources/blogs/:slug",
    component: _2932e7e4,
    meta: {"paths":{"en":"\u002Fresources\u002Fblogs\u002F:slug","lt":"\u002Fresursai\u002Fblogs\u002F:slug","de":"\u002Fresources\u002Fblogs\u002F:slug","es":"\u002Fresources\u002Fblogs\u002F:slug","jp":"\u002Fresources\u002Fblogs\u002F:slug","ua":"\u002Fresources\u002Fblogs\u002F:slug"},"page_id":124},
    name: "resources-blogs-:slug-124-slug___jp"
  }, {
    path: "/jp/resources/videos/:slug",
    component: _0c947502,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos\u002F:slug","lt":"\u002Fresursai\u002Fvaizdo-irasai\u002F:slug","de":"\u002Fresources\u002Fvideos\u002F:slug","es":"\u002Fresources\u002Fvideos\u002F:slug","jp":"\u002Fresources\u002Fvideos\u002F:slug","ua":"\u002Fresources\u002Fvideos\u002F:slug"},"page_id":101},
    name: "resources-videos-:slug-101-slug___jp"
  }, {
    path: "/jp/resources/webinars/:slug",
    component: _2c6c8c4b,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars\u002F:slug","lt":"\u002Fresursai\u002Fwebinarai\u002F:slug","de":"\u002Fresources\u002Fwebinars\u002F:slug","es":"\u002Fresources\u002Fwebinars\u002F:slug","jp":"\u002Fresources\u002Fwebinars\u002F:slug","ua":"\u002Fresources\u002Fwebinars\u002F:slug"},"page_id":179},
    name: "resources-webinars-:slug-179-slug___jp"
  }, {
    path: "/jp/use-cases/energy-utilities/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fenergetika\u002F:slug","de":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","es":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","jp":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","ua":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug"},"page_id":91},
    name: "use-cases-energy-utilities-:slug-91-slug___jp"
  }, {
    path: "/jp/use-cases/enterprise/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenterprise\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fverslo-sektorius\u002F:slug","de":"\u002Fuse-cases\u002Fenterprise\u002F:slug","es":"\u002Fuse-cases\u002Fenterprise\u002F:slug","jp":"\u002Fuse-cases\u002Fenterprise\u002F:slug","ua":"\u002Fuse-cases\u002Fenterprise\u002F:slug"},"page_id":94},
    name: "use-cases-enterprise-:slug-94-slug___jp"
  }, {
    path: "/jp/use-cases/industrial-automation/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fautomatizavimas\u002F:slug","de":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","es":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","jp":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","ua":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug"},"page_id":90},
    name: "use-cases-industrial-automation-:slug-90-slug___jp"
  }, {
    path: "/jp/use-cases/retail/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fretail\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fprekybos-sektorius\u002F:slug","de":"\u002Fuse-cases\u002Fretail\u002F:slug","es":"\u002Fuse-cases\u002Fretail\u002F:slug","jp":"\u002Fuse-cases\u002Fretail\u002F:slug","ua":"\u002Fuse-cases\u002Fretail\u002F:slug"},"page_id":95},
    name: "use-cases-retail-:slug-95-slug___jp"
  }, {
    path: "/jp/use-cases/smart-city/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fismanus-miestas\u002F:slug","de":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","es":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","jp":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","ua":"\u002Fuse-cases\u002Fsmart-city\u002F:slug"},"page_id":92},
    name: "use-cases-smart-city-:slug-92-slug___jp"
  }, {
    path: "/jp/use-cases/transportation/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftransportation\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Ftransportas\u002F:slug","de":"\u002Fuse-cases\u002Ftransportation\u002F:slug","es":"\u002Fuse-cases\u002Ftransportation\u002F:slug","jp":"\u002Fuse-cases\u002Ftransportation\u002F:slug","ua":"\u002Fuse-cases\u002Ftransportation\u002F:slug"},"page_id":93},
    name: "use-cases-transportation-:slug-93-slug___jp"
  }, {
    path: "/lt/apie-mus/politika-ir-sertifikatai/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug","de":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","es":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","jp":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","ua":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___lt"
  }, {
    path: "/lt/produktai/access-points/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccess-points\u002F:slug","lt":"\u002Fproduktai\u002Faccess-points\u002F:slug","de":"\u002Fproducts\u002Faccess-points\u002F:slug","es":"\u002Fproducts\u002Faccess-points\u002F:slug","jp":"\u002Fproducts\u002Faccess-points\u002F:slug","ua":"\u002Fproducts\u002Faccess-points\u002F:slug"},"page_id":182},
    name: "products-access-points-:slug-182-slug___lt"
  }, {
    path: "/lt/produktai/priedai/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002F:slug"},"page_id":116},
    name: "products-accessories-:slug-116-slug___lt"
  }, {
    path: "/lt/produktai/mobilus-iot/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fcellular-iot\u002F:slug","lt":"\u002Fproduktai\u002Fmobilus-iot\u002F:slug","de":"\u002Fproducts\u002Fcellular-iot\u002F:slug","es":"\u002Fproducts\u002Fcellular-iot\u002F:slug","jp":"\u002Fproducts\u002Fcellular-iot\u002F:slug","ua":"\u002Fproducts\u002Fcellular-iot\u002F:slug"},"page_id":110},
    name: "products-cellular-iot-:slug-110-slug___lt"
  }, {
    path: "/lt/produktai/sakotuvai/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-switches\u002F:slug","lt":"\u002Fproduktai\u002Fsakotuvai\u002F:slug","de":"\u002Fproducts\u002Fethernet-switches\u002F:slug","es":"\u002Fproducts\u002Fethernet-switches\u002F:slug","jp":"\u002Fproducts\u002Fethernet-switches\u002F:slug","ua":"\u002Fproducts\u002Fethernet-switches\u002F:slug"},"page_id":120},
    name: "products-ethernet-switches-:slug-120-slug___lt"
  }, {
    path: "/lt/produktai/eternetas-ir-belaidis-rysys/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys\u002F:slug","de":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","es":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","jp":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","ua":"\u002Fproducts\u002Fethernet-wireless\u002F:slug"},"page_id":117},
    name: "products-ethernet-wireless-:slug-117-slug___lt"
  }, {
    path: "/lt/produktai/tinklu-sietuvai/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fgateways\u002F:slug","lt":"\u002Fproduktai\u002Ftinklu-sietuvai\u002F:slug","de":"\u002Fproducts\u002Fgateways\u002F:slug","es":"\u002Fproducts\u002Fgateways\u002F:slug","jp":"\u002Fproducts\u002Fgateways\u002F:slug","ua":"\u002Fproducts\u002Fgateways\u002F:slug"},"page_id":113},
    name: "products-gateways-:slug-113-slug___lt"
  }, {
    path: "/lt/produktai/modemai/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fmodems\u002F:slug","lt":"\u002Fproduktai\u002Fmodemai\u002F:slug","de":"\u002Fproducts\u002Fmodems\u002F:slug","es":"\u002Fproducts\u002Fmodems\u002F:slug","jp":"\u002Fproducts\u002Fmodems\u002F:slug","ua":"\u002Fproducts\u002Fmodems\u002F:slug"},"page_id":112},
    name: "products-modems-:slug-112-slug___lt"
  }, {
    path: "/lt/produktai/marsrutizatoriai/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters\u002F:slug","lt":"\u002Fproduktai\u002Fmarsrutizatoriai\u002F:slug","de":"\u002Fproducts\u002Frouters\u002F:slug","es":"\u002Fproducts\u002Frouters\u002F:slug","jp":"\u002Fproducts\u002Frouters\u002F:slug","ua":"\u002Fproducts\u002Frouters\u002F:slug"},"page_id":115},
    name: "products-routers-:slug-115-slug___lt"
  }, {
    path: "/lt/resursai/blogs/:slug",
    component: _2932e7e4,
    meta: {"paths":{"en":"\u002Fresources\u002Fblogs\u002F:slug","lt":"\u002Fresursai\u002Fblogs\u002F:slug","de":"\u002Fresources\u002Fblogs\u002F:slug","es":"\u002Fresources\u002Fblogs\u002F:slug","jp":"\u002Fresources\u002Fblogs\u002F:slug","ua":"\u002Fresources\u002Fblogs\u002F:slug"},"page_id":124},
    name: "resources-blogs-:slug-124-slug___lt"
  }, {
    path: "/lt/resursai/vaizdo-irasai/:slug",
    component: _0c947502,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos\u002F:slug","lt":"\u002Fresursai\u002Fvaizdo-irasai\u002F:slug","de":"\u002Fresources\u002Fvideos\u002F:slug","es":"\u002Fresources\u002Fvideos\u002F:slug","jp":"\u002Fresources\u002Fvideos\u002F:slug","ua":"\u002Fresources\u002Fvideos\u002F:slug"},"page_id":101},
    name: "resources-videos-:slug-101-slug___lt"
  }, {
    path: "/lt/resursai/webinarai/:slug",
    component: _2c6c8c4b,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars\u002F:slug","lt":"\u002Fresursai\u002Fwebinarai\u002F:slug","de":"\u002Fresources\u002Fwebinars\u002F:slug","es":"\u002Fresources\u002Fwebinars\u002F:slug","jp":"\u002Fresources\u002Fwebinars\u002F:slug","ua":"\u002Fresources\u002Fwebinars\u002F:slug"},"page_id":179},
    name: "resources-webinars-:slug-179-slug___lt"
  }, {
    path: "/lt/visi-panaudojimai/energetika/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fenergetika\u002F:slug","de":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","es":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","jp":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","ua":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug"},"page_id":91},
    name: "use-cases-energy-utilities-:slug-91-slug___lt"
  }, {
    path: "/lt/visi-panaudojimai/verslo-sektorius/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenterprise\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fverslo-sektorius\u002F:slug","de":"\u002Fuse-cases\u002Fenterprise\u002F:slug","es":"\u002Fuse-cases\u002Fenterprise\u002F:slug","jp":"\u002Fuse-cases\u002Fenterprise\u002F:slug","ua":"\u002Fuse-cases\u002Fenterprise\u002F:slug"},"page_id":94},
    name: "use-cases-enterprise-:slug-94-slug___lt"
  }, {
    path: "/lt/visi-panaudojimai/automatizavimas/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fautomatizavimas\u002F:slug","de":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","es":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","jp":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","ua":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug"},"page_id":90},
    name: "use-cases-industrial-automation-:slug-90-slug___lt"
  }, {
    path: "/lt/visi-panaudojimai/prekybos-sektorius/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fretail\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fprekybos-sektorius\u002F:slug","de":"\u002Fuse-cases\u002Fretail\u002F:slug","es":"\u002Fuse-cases\u002Fretail\u002F:slug","jp":"\u002Fuse-cases\u002Fretail\u002F:slug","ua":"\u002Fuse-cases\u002Fretail\u002F:slug"},"page_id":95},
    name: "use-cases-retail-:slug-95-slug___lt"
  }, {
    path: "/lt/visi-panaudojimai/ismanus-miestas/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fismanus-miestas\u002F:slug","de":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","es":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","jp":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","ua":"\u002Fuse-cases\u002Fsmart-city\u002F:slug"},"page_id":92},
    name: "use-cases-smart-city-:slug-92-slug___lt"
  }, {
    path: "/lt/visi-panaudojimai/transportas/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftransportation\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Ftransportas\u002F:slug","de":"\u002Fuse-cases\u002Ftransportation\u002F:slug","es":"\u002Fuse-cases\u002Ftransportation\u002F:slug","jp":"\u002Fuse-cases\u002Ftransportation\u002F:slug","ua":"\u002Fuse-cases\u002Ftransportation\u002F:slug"},"page_id":93},
    name: "use-cases-transportation-:slug-93-slug___lt"
  }, {
    path: "/products/accessories/3rd-party-solutions/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Ftreciuju-saliu-sprendimai\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002F3rd-party-solutions\u002F:slug"},"page_id":130},
    name: "products-accessories-3rd-party-solutions-:slug-130-slug___en"
  }, {
    path: "/products/accessories/antenna-options/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fantenu-parinktys\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fantenna-options\u002F:slug"},"page_id":127},
    name: "products-accessories-antenna-options-:slug-127-slug___en"
  }, {
    path: "/products/accessories/mounting-options/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fmontavimo-galimybes\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fmounting-options\u002F:slug"},"page_id":128},
    name: "products-accessories-mounting-options-:slug-128-slug___en"
  }, {
    path: "/products/accessories/other-2/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fother-2\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fkita-2\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fother-2\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fother-2\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fother-1\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fother\u002F:slug"},"page_id":131},
    name: "products-accessories-other-2-:slug-131-slug___en"
  }, {
    path: "/products/accessories/powering-options/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002Fmaitinimo-priedai\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002Fpowering-options\u002F:slug"},"page_id":125},
    name: "products-accessories-powering-options-:slug-125-slug___en"
  }, {
    path: "/products/ethernet-wireless/routers-ethernet/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys\u002Frouters-ethernet\u002F:slug","de":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","es":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","jp":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug","ua":"\u002Fproducts\u002Fethernet-wireless\u002Frouters-ethernet\u002F:slug"},"page_id":134},
    name: "products-ethernet-wireless-routers-ethernet-:slug-134-slug___en"
  }, {
    path: "/products/routers/ethernet-wireless-1/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug","lt":"\u002Fproduktai\u002Fmarsrutizatoriai\u002Fethernet-wireless\u002F:slug","de":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug","es":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug","jp":"\u002Fproducts\u002Frouters\u002Fethernet-wireless-1\u002F:slug"},"page_id":133},
    name: "products-routers-ethernet-wireless-1-:slug-133-slug___en"
  }, {
    path: "/ua/about-us/policies-certificates/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug","de":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","es":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","jp":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","ua":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___ua"
  }, {
    path: "/ua/products/access-points/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccess-points\u002F:slug","lt":"\u002Fproduktai\u002Faccess-points\u002F:slug","de":"\u002Fproducts\u002Faccess-points\u002F:slug","es":"\u002Fproducts\u002Faccess-points\u002F:slug","jp":"\u002Fproducts\u002Faccess-points\u002F:slug","ua":"\u002Fproducts\u002Faccess-points\u002F:slug"},"page_id":182},
    name: "products-access-points-:slug-182-slug___ua"
  }, {
    path: "/ua/products/accessories/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002F:slug"},"page_id":116},
    name: "products-accessories-:slug-116-slug___ua"
  }, {
    path: "/ua/products/cellular-iot/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fcellular-iot\u002F:slug","lt":"\u002Fproduktai\u002Fmobilus-iot\u002F:slug","de":"\u002Fproducts\u002Fcellular-iot\u002F:slug","es":"\u002Fproducts\u002Fcellular-iot\u002F:slug","jp":"\u002Fproducts\u002Fcellular-iot\u002F:slug","ua":"\u002Fproducts\u002Fcellular-iot\u002F:slug"},"page_id":110},
    name: "products-cellular-iot-:slug-110-slug___ua"
  }, {
    path: "/ua/products/ethernet-switches/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-switches\u002F:slug","lt":"\u002Fproduktai\u002Fsakotuvai\u002F:slug","de":"\u002Fproducts\u002Fethernet-switches\u002F:slug","es":"\u002Fproducts\u002Fethernet-switches\u002F:slug","jp":"\u002Fproducts\u002Fethernet-switches\u002F:slug","ua":"\u002Fproducts\u002Fethernet-switches\u002F:slug"},"page_id":120},
    name: "products-ethernet-switches-:slug-120-slug___ua"
  }, {
    path: "/ua/products/ethernet-wireless/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys\u002F:slug","de":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","es":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","jp":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","ua":"\u002Fproducts\u002Fethernet-wireless\u002F:slug"},"page_id":117},
    name: "products-ethernet-wireless-:slug-117-slug___ua"
  }, {
    path: "/ua/products/gateways/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fgateways\u002F:slug","lt":"\u002Fproduktai\u002Ftinklu-sietuvai\u002F:slug","de":"\u002Fproducts\u002Fgateways\u002F:slug","es":"\u002Fproducts\u002Fgateways\u002F:slug","jp":"\u002Fproducts\u002Fgateways\u002F:slug","ua":"\u002Fproducts\u002Fgateways\u002F:slug"},"page_id":113},
    name: "products-gateways-:slug-113-slug___ua"
  }, {
    path: "/ua/products/modems/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fmodems\u002F:slug","lt":"\u002Fproduktai\u002Fmodemai\u002F:slug","de":"\u002Fproducts\u002Fmodems\u002F:slug","es":"\u002Fproducts\u002Fmodems\u002F:slug","jp":"\u002Fproducts\u002Fmodems\u002F:slug","ua":"\u002Fproducts\u002Fmodems\u002F:slug"},"page_id":112},
    name: "products-modems-:slug-112-slug___ua"
  }, {
    path: "/ua/products/routers/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters\u002F:slug","lt":"\u002Fproduktai\u002Fmarsrutizatoriai\u002F:slug","de":"\u002Fproducts\u002Frouters\u002F:slug","es":"\u002Fproducts\u002Frouters\u002F:slug","jp":"\u002Fproducts\u002Frouters\u002F:slug","ua":"\u002Fproducts\u002Frouters\u002F:slug"},"page_id":115},
    name: "products-routers-:slug-115-slug___ua"
  }, {
    path: "/ua/resources/blogs/:slug",
    component: _2932e7e4,
    meta: {"paths":{"en":"\u002Fresources\u002Fblogs\u002F:slug","lt":"\u002Fresursai\u002Fblogs\u002F:slug","de":"\u002Fresources\u002Fblogs\u002F:slug","es":"\u002Fresources\u002Fblogs\u002F:slug","jp":"\u002Fresources\u002Fblogs\u002F:slug","ua":"\u002Fresources\u002Fblogs\u002F:slug"},"page_id":124},
    name: "resources-blogs-:slug-124-slug___ua"
  }, {
    path: "/ua/resources/videos/:slug",
    component: _0c947502,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos\u002F:slug","lt":"\u002Fresursai\u002Fvaizdo-irasai\u002F:slug","de":"\u002Fresources\u002Fvideos\u002F:slug","es":"\u002Fresources\u002Fvideos\u002F:slug","jp":"\u002Fresources\u002Fvideos\u002F:slug","ua":"\u002Fresources\u002Fvideos\u002F:slug"},"page_id":101},
    name: "resources-videos-:slug-101-slug___ua"
  }, {
    path: "/ua/resources/webinars/:slug",
    component: _2c6c8c4b,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars\u002F:slug","lt":"\u002Fresursai\u002Fwebinarai\u002F:slug","de":"\u002Fresources\u002Fwebinars\u002F:slug","es":"\u002Fresources\u002Fwebinars\u002F:slug","jp":"\u002Fresources\u002Fwebinars\u002F:slug","ua":"\u002Fresources\u002Fwebinars\u002F:slug"},"page_id":179},
    name: "resources-webinars-:slug-179-slug___ua"
  }, {
    path: "/ua/use-cases/energy-utilities/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fenergetika\u002F:slug","de":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","es":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","jp":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","ua":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug"},"page_id":91},
    name: "use-cases-energy-utilities-:slug-91-slug___ua"
  }, {
    path: "/ua/use-cases/enterprise/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenterprise\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fverslo-sektorius\u002F:slug","de":"\u002Fuse-cases\u002Fenterprise\u002F:slug","es":"\u002Fuse-cases\u002Fenterprise\u002F:slug","jp":"\u002Fuse-cases\u002Fenterprise\u002F:slug","ua":"\u002Fuse-cases\u002Fenterprise\u002F:slug"},"page_id":94},
    name: "use-cases-enterprise-:slug-94-slug___ua"
  }, {
    path: "/ua/use-cases/industrial-automation/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fautomatizavimas\u002F:slug","de":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","es":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","jp":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","ua":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug"},"page_id":90},
    name: "use-cases-industrial-automation-:slug-90-slug___ua"
  }, {
    path: "/ua/use-cases/retail/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fretail\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fprekybos-sektorius\u002F:slug","de":"\u002Fuse-cases\u002Fretail\u002F:slug","es":"\u002Fuse-cases\u002Fretail\u002F:slug","jp":"\u002Fuse-cases\u002Fretail\u002F:slug","ua":"\u002Fuse-cases\u002Fretail\u002F:slug"},"page_id":95},
    name: "use-cases-retail-:slug-95-slug___ua"
  }, {
    path: "/ua/use-cases/smart-city/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fismanus-miestas\u002F:slug","de":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","es":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","jp":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","ua":"\u002Fuse-cases\u002Fsmart-city\u002F:slug"},"page_id":92},
    name: "use-cases-smart-city-:slug-92-slug___ua"
  }, {
    path: "/ua/use-cases/transportation/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftransportation\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Ftransportas\u002F:slug","de":"\u002Fuse-cases\u002Ftransportation\u002F:slug","es":"\u002Fuse-cases\u002Ftransportation\u002F:slug","jp":"\u002Fuse-cases\u002Ftransportation\u002F:slug","ua":"\u002Fuse-cases\u002Ftransportation\u002F:slug"},"page_id":93},
    name: "use-cases-transportation-:slug-93-slug___ua"
  }, {
    path: "/about-us/policies-certificates/:slug",
    component: _8d5b5624,
    meta: {"paths":{"en":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","lt":"\u002Fapie-mus\u002Fpolitika-ir-sertifikatai\u002F:slug","de":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","es":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","jp":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug","ua":"\u002Fabout-us\u002Fpolicies-certificates\u002F:slug"},"page_id":18},
    name: "about-us-policies-certificates-:slug-18-slug___en"
  }, {
    path: "/de/newsroom/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug","de":"\u002Fnewsroom\u002F:slug","es":"\u002Fnewsroom\u002F:slug","jp":"\u002Fnewsroom\u002F:slug","ua":"\u002Fnewsroom\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___de"
  }, {
    path: "/de/products/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002F:slug","lt":"\u002Fproduktai\u002F:slug","de":"\u002Fproducts\u002F:slug","es":"\u002Fproducts\u002F:slug","jp":"\u002Fproducts\u002F:slug","ua":"\u002Fproducts\u002F:slug"},"page_id":114},
    name: "products-:slug-114-slug___de"
  }, {
    path: "/de/use-cases/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002F:slug","de":"\u002Fuse-cases\u002F:slug","es":"\u002Fuse-cases\u002F:slug","jp":"\u002Fuse-cases\u002F:slug","ua":"\u002Fuse-cases\u002F:slug"},"page_id":96},
    name: "use-cases-:slug-96-slug___de"
  }, {
    path: "/es/newsroom/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug","de":"\u002Fnewsroom\u002F:slug","es":"\u002Fnewsroom\u002F:slug","jp":"\u002Fnewsroom\u002F:slug","ua":"\u002Fnewsroom\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___es"
  }, {
    path: "/es/products/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002F:slug","lt":"\u002Fproduktai\u002F:slug","de":"\u002Fproducts\u002F:slug","es":"\u002Fproducts\u002F:slug","jp":"\u002Fproducts\u002F:slug","ua":"\u002Fproducts\u002F:slug"},"page_id":114},
    name: "products-:slug-114-slug___es"
  }, {
    path: "/es/use-cases/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002F:slug","de":"\u002Fuse-cases\u002F:slug","es":"\u002Fuse-cases\u002F:slug","jp":"\u002Fuse-cases\u002F:slug","ua":"\u002Fuse-cases\u002F:slug"},"page_id":96},
    name: "use-cases-:slug-96-slug___es"
  }, {
    path: "/jp/newsroom/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug","de":"\u002Fnewsroom\u002F:slug","es":"\u002Fnewsroom\u002F:slug","jp":"\u002Fnewsroom\u002F:slug","ua":"\u002Fnewsroom\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___jp"
  }, {
    path: "/jp/products/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002F:slug","lt":"\u002Fproduktai\u002F:slug","de":"\u002Fproducts\u002F:slug","es":"\u002Fproducts\u002F:slug","jp":"\u002Fproducts\u002F:slug","ua":"\u002Fproducts\u002F:slug"},"page_id":114},
    name: "products-:slug-114-slug___jp"
  }, {
    path: "/jp/use-cases/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002F:slug","de":"\u002Fuse-cases\u002F:slug","es":"\u002Fuse-cases\u002F:slug","jp":"\u002Fuse-cases\u002F:slug","ua":"\u002Fuse-cases\u002F:slug"},"page_id":96},
    name: "use-cases-:slug-96-slug___jp"
  }, {
    path: "/lt/naujienos/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug","de":"\u002Fnewsroom\u002F:slug","es":"\u002Fnewsroom\u002F:slug","jp":"\u002Fnewsroom\u002F:slug","ua":"\u002Fnewsroom\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___lt"
  }, {
    path: "/lt/produktai/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002F:slug","lt":"\u002Fproduktai\u002F:slug","de":"\u002Fproducts\u002F:slug","es":"\u002Fproducts\u002F:slug","jp":"\u002Fproducts\u002F:slug","ua":"\u002Fproducts\u002F:slug"},"page_id":114},
    name: "products-:slug-114-slug___lt"
  }, {
    path: "/lt/visi-panaudojimai/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002F:slug","de":"\u002Fuse-cases\u002F:slug","es":"\u002Fuse-cases\u002F:slug","jp":"\u002Fuse-cases\u002F:slug","ua":"\u002Fuse-cases\u002F:slug"},"page_id":96},
    name: "use-cases-:slug-96-slug___lt"
  }, {
    path: "/products/access-points/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccess-points\u002F:slug","lt":"\u002Fproduktai\u002Faccess-points\u002F:slug","de":"\u002Fproducts\u002Faccess-points\u002F:slug","es":"\u002Fproducts\u002Faccess-points\u002F:slug","jp":"\u002Fproducts\u002Faccess-points\u002F:slug","ua":"\u002Fproducts\u002Faccess-points\u002F:slug"},"page_id":182},
    name: "products-access-points-:slug-182-slug___en"
  }, {
    path: "/products/accessories/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Faccessories\u002F:slug","lt":"\u002Fproduktai\u002Fpriedai\u002F:slug","de":"\u002Fproducts\u002Faccessories\u002F:slug","es":"\u002Fproducts\u002Faccessories\u002F:slug","jp":"\u002Fproducts\u002Faccessories\u002F:slug","ua":"\u002Fproducts\u002Faccessories\u002F:slug"},"page_id":116},
    name: "products-accessories-:slug-116-slug___en"
  }, {
    path: "/products/cellular-iot/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fcellular-iot\u002F:slug","lt":"\u002Fproduktai\u002Fmobilus-iot\u002F:slug","de":"\u002Fproducts\u002Fcellular-iot\u002F:slug","es":"\u002Fproducts\u002Fcellular-iot\u002F:slug","jp":"\u002Fproducts\u002Fcellular-iot\u002F:slug","ua":"\u002Fproducts\u002Fcellular-iot\u002F:slug"},"page_id":110},
    name: "products-cellular-iot-:slug-110-slug___en"
  }, {
    path: "/products/ethernet-switches/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-switches\u002F:slug","lt":"\u002Fproduktai\u002Fsakotuvai\u002F:slug","de":"\u002Fproducts\u002Fethernet-switches\u002F:slug","es":"\u002Fproducts\u002Fethernet-switches\u002F:slug","jp":"\u002Fproducts\u002Fethernet-switches\u002F:slug","ua":"\u002Fproducts\u002Fethernet-switches\u002F:slug"},"page_id":120},
    name: "products-ethernet-switches-:slug-120-slug___en"
  }, {
    path: "/products/ethernet-wireless/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","lt":"\u002Fproduktai\u002Feternetas-ir-belaidis-rysys\u002F:slug","de":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","es":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","jp":"\u002Fproducts\u002Fethernet-wireless\u002F:slug","ua":"\u002Fproducts\u002Fethernet-wireless\u002F:slug"},"page_id":117},
    name: "products-ethernet-wireless-:slug-117-slug___en"
  }, {
    path: "/products/gateways/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fgateways\u002F:slug","lt":"\u002Fproduktai\u002Ftinklu-sietuvai\u002F:slug","de":"\u002Fproducts\u002Fgateways\u002F:slug","es":"\u002Fproducts\u002Fgateways\u002F:slug","jp":"\u002Fproducts\u002Fgateways\u002F:slug","ua":"\u002Fproducts\u002Fgateways\u002F:slug"},"page_id":113},
    name: "products-gateways-:slug-113-slug___en"
  }, {
    path: "/products/modems/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Fmodems\u002F:slug","lt":"\u002Fproduktai\u002Fmodemai\u002F:slug","de":"\u002Fproducts\u002Fmodems\u002F:slug","es":"\u002Fproducts\u002Fmodems\u002F:slug","jp":"\u002Fproducts\u002Fmodems\u002F:slug","ua":"\u002Fproducts\u002Fmodems\u002F:slug"},"page_id":112},
    name: "products-modems-:slug-112-slug___en"
  }, {
    path: "/products/routers/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002Frouters\u002F:slug","lt":"\u002Fproduktai\u002Fmarsrutizatoriai\u002F:slug","de":"\u002Fproducts\u002Frouters\u002F:slug","es":"\u002Fproducts\u002Frouters\u002F:slug","jp":"\u002Fproducts\u002Frouters\u002F:slug","ua":"\u002Fproducts\u002Frouters\u002F:slug"},"page_id":115},
    name: "products-routers-:slug-115-slug___en"
  }, {
    path: "/resources/blogs/:slug",
    component: _2932e7e4,
    meta: {"paths":{"en":"\u002Fresources\u002Fblogs\u002F:slug","lt":"\u002Fresursai\u002Fblogs\u002F:slug","de":"\u002Fresources\u002Fblogs\u002F:slug","es":"\u002Fresources\u002Fblogs\u002F:slug","jp":"\u002Fresources\u002Fblogs\u002F:slug","ua":"\u002Fresources\u002Fblogs\u002F:slug"},"page_id":124},
    name: "resources-blogs-:slug-124-slug___en"
  }, {
    path: "/resources/videos/:slug",
    component: _0c947502,
    meta: {"paths":{"en":"\u002Fresources\u002Fvideos\u002F:slug","lt":"\u002Fresursai\u002Fvaizdo-irasai\u002F:slug","de":"\u002Fresources\u002Fvideos\u002F:slug","es":"\u002Fresources\u002Fvideos\u002F:slug","jp":"\u002Fresources\u002Fvideos\u002F:slug","ua":"\u002Fresources\u002Fvideos\u002F:slug"},"page_id":101},
    name: "resources-videos-:slug-101-slug___en"
  }, {
    path: "/resources/webinars/:slug",
    component: _2c6c8c4b,
    meta: {"paths":{"en":"\u002Fresources\u002Fwebinars\u002F:slug","lt":"\u002Fresursai\u002Fwebinarai\u002F:slug","de":"\u002Fresources\u002Fwebinars\u002F:slug","es":"\u002Fresources\u002Fwebinars\u002F:slug","jp":"\u002Fresources\u002Fwebinars\u002F:slug","ua":"\u002Fresources\u002Fwebinars\u002F:slug"},"page_id":179},
    name: "resources-webinars-:slug-179-slug___en"
  }, {
    path: "/ua/newsroom/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug","de":"\u002Fnewsroom\u002F:slug","es":"\u002Fnewsroom\u002F:slug","jp":"\u002Fnewsroom\u002F:slug","ua":"\u002Fnewsroom\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___ua"
  }, {
    path: "/ua/products/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002F:slug","lt":"\u002Fproduktai\u002F:slug","de":"\u002Fproducts\u002F:slug","es":"\u002Fproducts\u002F:slug","jp":"\u002Fproducts\u002F:slug","ua":"\u002Fproducts\u002F:slug"},"page_id":114},
    name: "products-:slug-114-slug___ua"
  }, {
    path: "/ua/use-cases/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002F:slug","de":"\u002Fuse-cases\u002F:slug","es":"\u002Fuse-cases\u002F:slug","jp":"\u002Fuse-cases\u002F:slug","ua":"\u002Fuse-cases\u002F:slug"},"page_id":96},
    name: "use-cases-:slug-96-slug___ua"
  }, {
    path: "/use-cases/energy-utilities/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fenergetika\u002F:slug","de":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","es":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","jp":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug","ua":"\u002Fuse-cases\u002Fenergy-utilities\u002F:slug"},"page_id":91},
    name: "use-cases-energy-utilities-:slug-91-slug___en"
  }, {
    path: "/use-cases/enterprise/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fenterprise\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fverslo-sektorius\u002F:slug","de":"\u002Fuse-cases\u002Fenterprise\u002F:slug","es":"\u002Fuse-cases\u002Fenterprise\u002F:slug","jp":"\u002Fuse-cases\u002Fenterprise\u002F:slug","ua":"\u002Fuse-cases\u002Fenterprise\u002F:slug"},"page_id":94},
    name: "use-cases-enterprise-:slug-94-slug___en"
  }, {
    path: "/use-cases/industrial-automation/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fautomatizavimas\u002F:slug","de":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","es":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","jp":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug","ua":"\u002Fuse-cases\u002Findustrial-automation\u002F:slug"},"page_id":90},
    name: "use-cases-industrial-automation-:slug-90-slug___en"
  }, {
    path: "/use-cases/retail/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fretail\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fprekybos-sektorius\u002F:slug","de":"\u002Fuse-cases\u002Fretail\u002F:slug","es":"\u002Fuse-cases\u002Fretail\u002F:slug","jp":"\u002Fuse-cases\u002Fretail\u002F:slug","ua":"\u002Fuse-cases\u002Fretail\u002F:slug"},"page_id":95},
    name: "use-cases-retail-:slug-95-slug___en"
  }, {
    path: "/use-cases/smart-city/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Fismanus-miestas\u002F:slug","de":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","es":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","jp":"\u002Fuse-cases\u002Fsmart-city\u002F:slug","ua":"\u002Fuse-cases\u002Fsmart-city\u002F:slug"},"page_id":92},
    name: "use-cases-smart-city-:slug-92-slug___en"
  }, {
    path: "/use-cases/transportation/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002Ftransportation\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002Ftransportas\u002F:slug","de":"\u002Fuse-cases\u002Ftransportation\u002F:slug","es":"\u002Fuse-cases\u002Ftransportation\u002F:slug","jp":"\u002Fuse-cases\u002Ftransportation\u002F:slug","ua":"\u002Fuse-cases\u002Ftransportation\u002F:slug"},"page_id":93},
    name: "use-cases-transportation-:slug-93-slug___en"
  }, {
    path: "/newsroom/:slug",
    component: _7248b9d5,
    meta: {"paths":{"en":"\u002Fnewsroom\u002F:slug","lt":"\u002Fnaujienos\u002F:slug","de":"\u002Fnewsroom\u002F:slug","es":"\u002Fnewsroom\u002F:slug","jp":"\u002Fnewsroom\u002F:slug","ua":"\u002Fnewsroom\u002F:slug"},"page_id":8},
    name: "newsroom-:slug-8-slug___en"
  }, {
    path: "/products/:slug",
    component: _73a5528b,
    meta: {"paths":{"en":"\u002Fproducts\u002F:slug","lt":"\u002Fproduktai\u002F:slug","de":"\u002Fproducts\u002F:slug","es":"\u002Fproducts\u002F:slug","jp":"\u002Fproducts\u002F:slug","ua":"\u002Fproducts\u002F:slug"},"page_id":114},
    name: "products-:slug-114-slug___en"
  }, {
    path: "/use-cases/:slug",
    component: _7f8eb981,
    meta: {"paths":{"en":"\u002Fuse-cases\u002F:slug","lt":"\u002Fvisi-panaudojimai\u002F:slug","de":"\u002Fuse-cases\u002F:slug","es":"\u002Fuse-cases\u002F:slug","jp":"\u002Fuse-cases\u002F:slug","ua":"\u002Fuse-cases\u002F:slug"},"page_id":96},
    name: "use-cases-:slug-96-slug___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
