export const formatSuggestedProducts = (products = []) => {
  return (
    products?.map((item) => {
      return {
        id: item.id || '',
        title: item.title || '',
      }
    }) || []
  )
}

export const handleSortTags = (defaultTag = {}, tags = [], sortParam = '') => {
  const sortTagsList = [
    defaultTag,
    ...tags.map((item) => ({
      code: item.key,
      title: item.name,
      count: item.count,
    })),
  ]
  let selectedSortTag = {}
  if (!sortParam || !sortTagsList.some((item) => item.code === sortParam)) {
    selectedSortTag = sortTagsList[0]
  } else {
    selectedSortTag = sortTagsList.find((item) => item.code === sortParam)
  }
  return { sortTagsList, selectedSortTag }
}
