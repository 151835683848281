import {
  required,
  email,
  minLength,
  maxLength,
  helpers,
} from 'vuelidate/lib/validators'

export const getValidator = (validator, parameters, extra = {}) => {
  const validators = {
    required,
    max: maxLength(parseInt(parameters) || 255),
    min: minLength(parseInt(parameters) || 10),
    email,
    string: (value) => true,
    number: (value) => typeof value === 'number',
    accepted: (value) => value === true,
    sometimes: (value) => true,
    valid_phone: (value) => true,
    array: (value) => true,
    digits_between: (value) => true,
    between: (value) => true,
    integer: (value) => Number.isInteger(value),
    same: (value) => true,
    numeric: (value) => true,
    doesnt_end_with: helpers.withParams(
      { rule: parameters, ...extra },
      (value) => {
        const parametersArray = parameters.replace(/\s/g, '').split(',')
        return !parametersArray.some((parameter) =>
          value?.split('@')?.[1]?.includes(parameter),
        )
      },
    ),
  }
  if (validators?.[validator]) {
    return validators[validator]
  } else {
    console.error(`Validator ${validator} not found`)
    return null
  }
}
