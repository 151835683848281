import Vue from 'vue'
import { gql } from 'graphql-tag'
import { safeParseJson } from '~/utils'

const queryPage = async (
  { app, i18n, store, route, params },
  query,
  variables,
) => {
  const { routeParams } = store.state.i18n
  const hasParams = routeParams?.[i18n.locale] || false
  const isDifferent =
    Object.values(routeParams).findIndex(
      (item) => item.slug === route.params.slug,
    ) === -1
  const path = route.path
    .replace(params.slug, '')
    .replace(`/${i18n.locale}/`, '/')

  const newSlug =
    hasParams && !isDifferent
      ? store.state.i18n?.routeParams?.[i18n.locale].fullUrl
      : (params?.slug ? path + params?.slug : path).replace('//', '/')

  const { data } = await app.$gqlQuery({
    query,
    fetchPolicy: 'no-cache',
    variables: {
      slug: `${newSlug}`,
      languageCode: i18n.locale,
      domainId: 2,
      ...variables,
    },
  })
  const dataKey = Object.keys(data)[0]
  if (data?.[dataKey]?.urls) {
    try {
      const urls = data?.[dataKey]?.urls
      const tempRouteParams = {}

      const trueUrls = typeof urls === 'string' ? safeParseJson(urls) : urls
      for (const i in trueUrls) {
        tempRouteParams[`${i}`] = {
          slug: urls?.[`${i}`]?.url_key_slug.replace('/', ''),
          fullUrl: urls?.[`${i}`]?.full_url,
        }
      }
      await store.dispatch('i18n/setRouteParams', tempRouteParams)
    } catch (e) {
      console.log(e)
      throw new Error(e)
    }
  }

  return { data }
}

const queryScheme = `

formResponse(input:{ code: $code, domain_id: $domainId, language_code: $languageCode, sort: true }){
  id,
  code,
  successMessage:message_after_completion_translated,
  fields: fields_list {
      id: field_id,
      label: label_translated,
      description: description_translated,
      name: input_name,
      props,
      attributes,
      comment: tooltip_translated,
      list: values_list,
      defaultValue: default_value,
      input: input_data {
        type,
        field,
        type_validator_rule,
        is_special_prepared,
        need_values_list
      },
      rules: rules_list {
      rule,
      validator_title_translated,
      example,
      used_with_type,
      is_type_validator,
      with_parameters,
      parameters
      }
  }
  properties: properties_array {
    trackPixel: track_pixel
  }
}
`

const buildSingleQuery = (code) =>
  queryScheme
    .replace('$code', `"${code}"`)
    .replace('formResponse', `${code}: formResponse`)

const queryForm = async ({ app, i18n, $config: { domainId } }, code) => {
  let builtQuery = ``

  if (Array.isArray(code)) {
    for (const codeIterator of code) {
      builtQuery += buildSingleQuery(codeIterator)
    }
  } else {
    builtQuery = buildSingleQuery(code)
  }

  const { data } = await app.$gqlQuery({
    query: gql`
      query FormQuery($domainId: Int!, $languageCode: String!) {
        ${builtQuery}
      }
    `,
    fetchPolicy: 'no-cache',
    variables: {
      languageCode: i18n.locale,
      domainId,
      code,
    },
  })
  return data
}

Vue.prototype.queryPage = queryPage

const gqlRequest = async ({
  query,
  variables,
  fetchPolicy = 'default',
  context,
}) => {
  const apiToken = context?.$config?.apiToken || process?.env?.API_TOKEN
  const apiUrl = context?.$config?.apiUrl || process?.env?.API_URL

  const options = {
    method: 'post',
    cache: fetchPolicy,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
      'Cache-Control': 'no-cache',
    },
    body: JSON.stringify({
      operationName: query.definitions[0].name.value,
      query: query.loc.source.body,
      variables,
    }),
  }
  const response = await fetch(apiUrl, options)

  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  const json = await response.json()

  if (json?.errors?.length) {
    console.error(json.errors)
    throw new Error(json.errors[0].message)
  }

  return json
}

export const gqlQuery = async ({ query, variables, fetchPolicy, context }) => {
  const response = await gqlRequest({ query, variables, fetchPolicy, context })

  return response
}

export const gqlMutation = async ({
  query,
  variables,
  fetchPolicy,
  context,
}) => {
  const response = await gqlRequest({ query, variables, fetchPolicy, context })

  return response
}

export default (context, inject) => {
  inject('gqlQuery', ({ query, variables, fetchPolicy }) =>
    gqlQuery({ query, variables, fetchPolicy, context }),
  )

  inject('gqlMutation', ({ query, variables, fetchPolicy }) =>
    gqlMutation({ query, variables, fetchPolicy, context }),
  )

  inject('queryPage', (query, variables) =>
    queryPage(context, query, variables),
  )
  inject('queryForm', (code) => queryForm(context, code))
}
