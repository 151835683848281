export const state = () => ({
  clientWidth: 0,
  footerInViewport: false,
})

export const mutations = {
  setClientWidth(state, payload) {
    state.clientWidth = payload
  },
  setFooterInViewport(state, payload) {
    state.footerInViewport = payload
  },
}
export const actions = {
  clientWidth({ commit }, payload) {
    commit('setClientWidth', payload)
  },
}

export const getters = {
  isDesktop: (state) => {
    return state.clientWidth >= 1024
  },
  isTablet: (state) => {
    return state.clientWidth > 768 && state.clientWidth < 1024
  },
  isMobile: (state) => {
    return state.clientWidth <= 768
  },
  isMobileSmall: (state) => {
    return state.clientWidth <= 400
  },
  isTabletOrDesktop: (state) => {
    return state.clientWidth >= 768
  },
  isMobileOrTablet: (state) => {
    return state.clientWidth < 1024
  },
  isIpadPro: (state) => {
    return state.clientWidth === 1024
  },
  clientWidth: (state) => {
    return state.clientWidth
  },
  isFooterInViewport: (state) => {
    return state.footerInViewport
  },
}
