import { THUMBS } from './constants.js'
class Manager {
  media
  thumbName
  formatName
  #thumbSet
  constructor(media) {
    this.media = media
    this.thumbName = THUMBS.original
    this.formatName = ''
    this.#thumbSet = false
  }

  get(variableName = 'src', defaultImage = '/images/default-image.jpg') {
    const { thumbName, formatName, media } = this
    const { extras } = media || {}
    let getter = media?.[`${variableName}`]
    if (thumbName !== THUMBS.original && extras) {
      const thumb = extras?.[thumbName]

      if (!thumb) {
        console.error(`${thumbName} does not exist in current media`)
        return defaultImage
      }
      if (formatName !== '') {
        if (typeof thumb?.[formatName]?.[`${variableName}`] === 'undefined') {
          throw new TypeError(
            `${variableName} not found in ${thumbName} ${formatName}`,
          )
        }

        getter = thumb[formatName]?.[`${variableName}`]
      }
    } else {
      if (!media || typeof media?.src === 'undefined')
        console.error(`Invalid media file: ${media?.src}`)

      getter = media?.src || defaultImage
    }
    return getter
  }

  thumb(thumb) {
    if (typeof THUMBS?.[thumb] === 'undefined') {
      throw new TypeError(`Invalid thumb: ${thumb}`)
    }
    this.thumbName = THUMBS[thumb]
    this.#thumbSet = true
    return this
  }

  format(format) {
    if (this.#thumbSet === false) {
      throw new Error('You must set a thumb before setting a format')
    }
    this.formatName = format
    return this
  }
}

export default Manager
