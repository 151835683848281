
import VueScrollTo from 'vue-scrollto'
import { spacings } from './extended/styling'
import { fireTag, getCookie } from '~/utils'
const SUCCESS = 'success'
const ERROR = 'error'

export default {
  props: {
    code: {
      type: String,
      default: '',
    },
    language: {
      type: String,
      default: 'en',
    },
    buttonText: {
      type: String,
      default: '',
    },
    prefills: {
      type: Array,
      default: () => [],
    },
    scrollOnSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      formState: false,
      serverErrors: [],
      structure: {
        errorMessage: '',
        successMessage: '',
        inputs: [],
      },
      trackPixel: false,
    }
  },
  async fetch() {
    try {
      if (!this.code) throw new Error('No form code')
      const url = `${this.$config.domainHost}/api/form?language=${this.language}&form=${this.code}`
      const response = await fetch(url)
      if (!response.ok) {
        throw new Error('Bad response')
      }

      const responseData = (await response.json()).data

      let inputs = responseData.inputs
      inputs = this.addRelations(responseData.inputs)
      inputs = this.addPrefills(inputs, this.prefills)
      inputs = this.handleStyling(inputs)

      this.structure = { ...responseData, inputs }
      this.trackPixel = responseData?.properties?.trackPixel
    } catch (e) {
      console.log(e)
      this.setFormState(ERROR)
    } finally {
      this.loading = false
    }
  },
  fetchOnServer: false,
  watch: {
    prefills(newValue) {
      this.structure.inputs = this.addPrefills(this.structure?.inputs, newValue)
    },
  },
  mounted() {
    try {
      this.$recaptcha.init()
    } catch (e) {
      console.log(e)
    }
  },
  beforeDestroy() {
    this.$recaptcha.destroy()
  },
  methods: {
    addPrefills(inputs = [], prefills = []) {
      for (const prefill of prefills) {
        const { name, change } = prefill
        const index = inputs.findIndex(
          (input) => input.attributes.name === name,
        )
        if (index === -1) continue
        change(inputs[index])
      }

      return inputs
    },
    async getToken() {
      try {
        return await this.$recaptcha.execute('login')
      } catch (e) {
        console.log('token error: ' + e)
        return await this.getToken()
      }
    },
    async onSubmit(formEvent) {
      formEvent.preventDefault()
      if (this.loading) return

      if (this.scrollOnSubmit)
        VueScrollTo.scrollTo(this.$el, 300, { offset: -70 })

      this.loading = true

      this.serverErrors = []

      try {
        const formData = new FormData(formEvent.target)
        const token = await this.getToken()
        const isAdwords = this.isAdwords()

        formData.append('no_captcha', true)
        formData.append('g-recaptcha-response', token)
        formData.append('adwords', isAdwords)

        const options = {
          method: 'POST',
          body: formData,
        }
        const url = `${this.$config.domainHost}/api/form?language=${this.language}&form=${this.code}`

        const postRequest = await fetch(url, options)
        const result = await postRequest.json()

        this.loading = false

        if (result?.data === true && this.trackPixel)
          fireTag(formData, 'mutation', this.code)

        if (result?.data === true) return this.setFormState(SUCCESS)
        if (result?.data?.errors) return this.setInputErrors(result.data.errors)

        throw new Error('Something went wrong!')
      } catch (exception) {
        this.setFormState(ERROR)
        this.loading = false
      }
    },
    isAdwords() {
      try {
        return getCookie('_gcl_aw') !== '' ? 1 : 0
      } catch {
        return 0
      }
    },
    handleStyling(inputs) {
      return inputs.map((input) => {
        const spacing = input?.props?.spacing
        if (!spacing)
          return { ...input, props: { ...input.props, class: 'my-2' } }
        delete input.props.spacing
        const twSpaces = spacings(spacing)
        return { ...input, props: { ...input.props, class: twSpaces } }
      })
    },
    addRelations(responseInputs) {
      const inputs = []
      for (const input of responseInputs) {
        if (input?.props?.relation?.relatedFieldName) {
          const parentId = inputs.findIndex(
            (item) =>
              item.attributes.name === input.props.relation.relatedFieldName,
          )

          this.$set(inputs[parentId], 'changeable', { value: '' })
          input.props.relation.watcher = inputs[parentId].changeable
        }
        inputs.push(input)
      }
      return inputs
    },
    setFormState(state) {
      const states = {
        [SUCCESS]: {
          state: SUCCESS,
          title: this.structure.successTitle,
          message: this.structure.successMessage,
        },
        [ERROR]: {
          state: ERROR,
          title: this.structure.errorTitle,
          message: this.structure.errorMessage,
        },
      }
      if (state === SUCCESS) this.setInputErrors()

      this.formState = states[state]
    },
    setInputErrors(errors = {}) {
      for (let i = 0; i <= this.structure.inputs.length; i++) {
        const name = this.structure.inputs?.[i]?.attributes?.name

        if (name === undefined) continue

        if (typeof this.structure.inputs[i].attributes?.error === 'undefined') {
          this.$set(this.structure.inputs[i].attributes, 'error', '')
        }

        try {
          this.structure.inputs[i].attributes.error = errors[name] || ''
        } catch (ex) {
          console.error(ex, 'error!')
        }

        try {
          delete errors[name]
        } catch (e) {
          console.error(e)
        }
      }

      this.serverErrors = Object.values(errors)
    },
  },
}
