import { useCasesForProduct } from '@/queries/useCase/useCasePosts.gql'
export default {
  data() {
    return {
      activeItem: null,
      featuredInUseCasesLocal: [],
      hasMore: false,
      page: 1,
      isLoading: false,
    }
  },
  computed: {
    productID() {
      return this.$parent.product.id
    },
    isAccessory() {
      return this.$parent.product.isAccessory
    },
    relatedLink() {
      return `${this.localePath('/use-cases')}?${
        this.isAccessory ? 'accessories' : 'products'
      }=${this.productID}`
    },
  },
  async created() {
    await this.fetchItems()
  },
  methods: {
    async fetchItems(type) {
      if (this.isLoading || this.noFetchFeaturedInUseCases) return
      this.isLoading = true
      try {
        const response = await this.$gqlQuery({
          query: useCasesForProduct,
          fetchPolicy: 'no-cache',
          variables: {
            domainId: this.$config.domainId,
            languageCode: this.$i18n.locale,
            page: this.page,
            postsLimit: this?.fetchAmount || 3, // gets from computed property to change fetch amount by each component itself
            productIds: [parseInt(this.productID)],
          },
        })

        const fetchedUseCases =
          response?.data?.useCasePosts?.data?.map((item) => ({
            category: item.assignedPage.title_translated,
            direct_url: item.direct_url,
            grid_photo: item.grid_photo_data,
            title: item.title_translated,
            id: item.id,
            date: item.date,
            content: item?.content || '',
          })) || []

        if (type === 'loadMore') {
          this.featuredInUseCasesLocal = [
            ...this.featuredInUseCasesLocal,
            ...fetchedUseCases,
          ]
        } else {
          this.featuredInUseCasesLocal = fetchedUseCases
          this.activeItem = this.featuredInUseCasesLocal[0]
        }

        this.hasMore =
          response.data?.useCasePosts?.paginatorInfo?.hasMorePages || false
        this.page++
        this.isLoading = false
      } catch (ex) {
        this.$sentry.captureException(`Error fetching useCasesForProduct`, ex)
        this.isLoading = false
      }
    },
  },
}
