
export default {
  name: 'InfinityLoader',
  props: {
    theme: {
      type: String,
      default: 'light',
    },
  },
  data() {
    return {
      observer: null,
      isAvailable: false,
    }
  },
  computed: {
    color() {
      return this.theme === 'light' ? 'bg-gray-4' : 'bg-blue-800'
    },
  },
  mounted() {
    if ('IntersectionObserver' in window) {
      this.isAvailable = true
      const options = this.options || {}
      this.observer = new IntersectionObserver(([entry]) => {
        if (entry && entry.isIntersecting) {
          this.$emit('loadMore')
        }
      }, options)

      this.observer.observe(this.$el)
    }
  },
  destroyed() {
    this.observer.disconnect()
  },
}
