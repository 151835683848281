const formatLink = (rawLinkObject) => {
  let urlKey = 'to'
  const parsedUrl = JSON.parse(rawLinkObject.current_url)
  let urlValue = parsedUrl.full_url
  if (
    rawLinkObject.redirect_url_translated !== '' &&
    rawLinkObject.redirect_url_translated !== null
  ) {
    urlKey = 'href'
    urlValue = rawLinkObject.redirect_url_translated
  }
  return {
    id: rawLinkObject.id,
    type: rawLinkObject.type,
    name: rawLinkObject.title_translated,
    [`${urlKey}`]: urlValue,
  }
}

const recursiveChildren = (parent) => {
  if (Object.keys(parent.childrens).length > 0) {
    const list = []
    for (const item of Object.values(parent.childrens)) {
      list.push(formatLink(item))
    }
    return list
  } else {
    console.log('no')
  }
  console.log('---------------------------------')
}

export const formatResponse = (rawData) => {
  const menu = []
  for (const item of Object.values(rawData)) {
    const menuSection = {
      ...formatLink(item),
      list: [],
    }
    menuSection.list = recursiveChildren(item)
    menu.push(menuSection)
  }
  return menu
}
