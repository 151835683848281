const middleware = {}

middleware['navigationTransperancy'] = require('../middleware/navigationTransperancy.js')
middleware['navigationTransperancy'] = middleware['navigationTransperancy'].default || middleware['navigationTransperancy']

middleware['pageMiddleware'] = require('../middleware/pageMiddleware.js')
middleware['pageMiddleware'] = middleware['pageMiddleware'].default || middleware['pageMiddleware']

middleware['redirectMiddleware'] = require('../middleware/redirectMiddleware.js')
middleware['redirectMiddleware'] = middleware['redirectMiddleware'].default || middleware['redirectMiddleware']

export default middleware
