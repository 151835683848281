export const trimEndUrlSlash = (url = '') => {
  if (typeof url !== 'string') return url

  const lastCharIndex = url.length - 1

  if (url[lastCharIndex] === '/') {
    return url.slice(0, -1)
  }

  return url
}

export default function ({ req, $redirects, redirect, route, res }) {
  const { originalUrl } = req || false
  const path = trimEndUrlSlash(
    typeof originalUrl !== 'undefined' ? originalUrl : route.fullPath,
  )

  if (path.includes('///')) {
    redirect(path.replace(/\/\/+/g, '/'))
  }

  if (path && $redirects?.[path]) {
    res.setHeader('Cache-Control', 'no-cache')
    const { url, redirect_code: code } = $redirects?.[path]
    if (path !== url) {
      console.log(url, 'redirect with code', code)
      redirect(code, url)
    }
  }
}
