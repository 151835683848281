
import { mapActions } from 'vuex'
export default {
  name: 'Mobile',
  head() {
    return {
      bodyAttrs: {
        class: this.isOpen ? 'overflow-hidden' : '',
      },
    }
  },
  computed: {
    isOpen: {
      get() {
        return this.$store.state.navigation.mobile.isOpen
      },
      set() {
        this.toggleNavigationAction('mobile')
      },
    },
    careerAmount() {
      return this.$store.state.navigation?.careerCount || 0
    },
    transformedMenu() {
      // transformed for design logic on mobile
      const headerMenu = [
        ...(this.$store.state.navigation.menus?.[this.$i18n.locale]?.header ||
          []),
      ]

      const isCurrentUrl = (url) => {
        return (
          url === '/' ||
          url === `/${this.$i18n.locale}` ||
          url === `/${this.$i18n.locale}/`
        )
      }
      if (headerMenu.length === 0 || isCurrentUrl(headerMenu?.[0]?.url))
        return headerMenu
      const iot = {
        title: headerMenu[0].title,
        url: headerMenu[0].url,
        badge: headerMenu[0].badge,
        children: [],
      }

      const currentBranchId = headerMenu[0]?.children?.findIndex((navItem) =>
        isCurrentUrl(navItem?.url),
      )

      headerMenu[0] = {
        // swaped current company to top and iot to  children list
        ...headerMenu[0].children[currentBranchId],
        children: [
          iot,
          ...headerMenu[0].children.filter(
            (_, index) => currentBranchId !== index,
          ),
        ],
      }

      return headerMenu
    },
  },
  methods: {
    ...mapActions({
      toggleNavigationAction: 'navigation/toggleNavigationAction',
    }),
  },
}
