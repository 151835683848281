import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=bdb68ca0"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"
import style0 from "./Footer.vue?vue&type=style&index=0&id=bdb68ca0&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@3.2.5_vue-temp_n4gdrmfirpmgaryqotnkfbokze/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/src/components/Button.vue').default,NewsletterForm: require('/src/components/NewsletterForm.vue').default,Button: require('/src/components/Button.vue').default,Footer: require('/src/components/Footer.vue').default})
