import sectionScrollToMixin from './sectionScrollToMixin.js'

export default {
  mounted() {
    this.$nextTick(() => {
      this.scrollToTable()
    })
  },
  mixins: [sectionScrollToMixin],
  methods: {
    scrollToTable() {
      const tab = this.$route.hash?.toLowerCase()?.replace('#', '')

      if (!this.isValidTabName(tab)) return

      this.activeTab = this.$t(`front.product.tabs.${tab}`)

      this.scrollToCurrent()
    },
    isValidTabName(name) {
      if (!this.tabHeadings) return false
      return this.tabHeadings.includes(name)
    },
  },
}
