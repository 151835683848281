
import { mapMutations } from 'vuex'
import { getLocalStorageCookiesPreferences } from '~/components/cookies/cookiesUtils'
import { dateFnsLocale, resizeMixin } from '~/mixins'

export default {
  name: 'Default',
  mixins: [dateFnsLocale, resizeMixin],
  data() {
    return {
      showCookiesBar: false,
    }
  },
  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true })
  },
  computed: {
    socialLinks() {
      const { linkedin, youtube, facebook, twitter, instagram } = this.$config
      const urls = { linkedin, youtube, facebook, twitter, instagram }

      return Object.keys(urls).reduce((previous, currentKey) => {
        const currentUrl = urls[currentKey]
        return currentUrl
          ? [...previous, { name: currentKey, url: currentUrl }]
          : previous
      }, [])
    },
    showModal() {
      return (
        this.$store.state['dynamic-contacts'].structure ||
        this.$store.state['dynamic-contacts'].loading
      )
    },
  },
  watch: {
    $route: {
      handler(to) {
        this.closeNavigation()
        this.closeNavigation('mobile')
      },
      immediate: true,
    },
  },
  mounted() {
    const isAgreed = getLocalStorageCookiesPreferences()
    this.showCookiesBar = !isAgreed
  },
  methods: {
    ...mapMutations({
      closeNavigation: 'navigation/closeNavigation',
    }),
  },
}
