import { formatDistance, format } from 'date-fns'

export function getDateDistance(date, locale) {
  if (!date) return ''
  try {
    const result = formatDistance(
      new Date(date.replace(/-/g, '/')),
      new Date(),
      {
        addSuffix: true,
        locale,
      },
    )
    return result
  } catch (error) {
    console.log(error)
    return date
  }
}

export function getFormattedDate(date, locale, dateFormat) {
  if (!date) return ''
  try {
    const result = format(new Date(date.replace(/-/g, '/')), dateFormat, {
      addSuffix: true,
      locale,
    })
    return result
  } catch (error) {
    console.log(error)
    return date
  }
}
