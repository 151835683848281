export default {
  props: {
    value: {
      type: [String, Boolean, Number, Object, Array],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: () =>
        `${new Date().getTime() + Math.floor(Math.random() * 100)}`,
    },
    name: {
      type: String,
      default: '',
    },
    success: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    error: {
      type: String,
      default: '',
    },
    max: {
      type: [Boolean, Number],
      default: false,
    },
    min: {
      type: [Boolean, Number],
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    comment: {
      type: String,
      default: '',
    },
    hasCounter: {
      type: Boolean,
      default: false,
    },
    attributes: {
      type: Object,
      default: () => ({}),
    },
    relation: {
      type: [Object, Boolean],
      default: false,
    },
    transparent: {
      type: Boolean,
      deafult: false,
    },
    defaultValue: {
      type: [String, Boolean, Number],
      default: '',
    },
    theme: {
      type: String,
      default: 'default',
      validator(theme) {
        return ['default', 'dark'].includes(theme)
      },
    },
    size: {
      type: String,
      default: 'default',
      validator(inputSize) {
        return ['default', 'big'].includes(inputSize)
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisibleRelation: true,
    }
  },
  watch: {
    relation: {
      handler(newRelation) {
        if (newRelation !== false) {
          this.isVisibleRelation = false
          this.$watch(
            `$parent.form.${newRelation.related_field_name}`,
            (newValue) => {
              const { props } = this.$parent.fields.find(
                (predicate) =>
                  predicate.name === newRelation.related_field_name,
              )
              if (typeof props === 'object' && props?.customOptions) {
                for (const option of props.customOptions) {
                  if (
                    option.data_content?.toLowerCase() ===
                      newValue.toLowerCase() ||
                    option.data_value === newValue
                  ) {
                    this.isVisibleRelation = true
                  } else {
                    this.isVisibleRelation = false
                  }
                }
              }
            },
          )
        }
      },
      immediate: true,
    },
  },
}
