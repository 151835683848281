import { page as pageQuery } from './../queries/page.gql'
import { formatMeta, isPaginationUpdate } from '~/utils'

export default function ({ app, route, i18n, $config, store, $sentry, from }) {
  const routeId = route?.meta?.[0]?.page_id || 0
  const updateFromPagination = isPaginationUpdate(route, from)

  if (routeId === 0 || 'slug' in route.params || updateFromPagination) return
  return app
    .$gqlQuery({
      fetchPolicy: 'reload',
      query: pageQuery,
      variables: {
        id: routeId,
        languageCode: app.i18n.locale,
        domainId: $config.domainId,
      },
    })
    .then(({ data: { page } }) => {
      if (Object.keys(page?.translations || {})?.length > 0) {
        Object.assign(i18n._vm.messages[i18n.locale], page.translations)
      }

      if (typeof page?.metaTitle === 'undefined') {
        $sentry.captureException(
          `Meta Title cannot be fetched of page id: ${routeId}`,
        )
      }
      // TODO:: we are fetching meta info with this middleware as well as with various queries through out the project. We need to review queries used and remove call to page if we are already getting info in this middleware. If we are not getting some info, we should refactor this middleware as well, to handle those cases.
      const formattedMeta = {
        ...formatMeta({
          title: page?.metaTitle || 'Teltonika - IoT, Internet of Things',
          description: page?.metaDescription,
          img: page?.socialImages?.extras?.e_1200xAuto?.jpg?.src,
          url: $config.domain + route?.fullPath,
        }),
        htmlAttrs: {
          lang: i18n.locale,
        },
      }
      const mainComponent = route.matched[0].components.default
      mainComponent.options.head = formattedMeta
      mainComponent.extendOptions.head = formattedMeta

      if (page && typeof page === 'object') {
        delete page.translations
        store.commit('page/setPage', page)
      }
    })
    .catch((ex) => {
      console.error('pageMiddleware.js error:', ex)
      console.log('pageMiddleware.js error:', ex)
    })
}
