
import { twMerge } from 'tailwind-merge'
import props from './props'
const variants = {
  body_large: {
    main: 'font-inter text-2xl',
    weights: {
      bold: 'font-bold',
      regular: 'font-normal',
    },
  },
  body: {
    main: 'font-inter text-base',
    weights: {
      bold: 'font-bold',
      regular: 'font-normal',
    },
  },
  body_small: {
    main: 'font-inter text-sm',
    weights: {
      bold: 'font-bold',
      regular: 'font-normal',
    },
  },
  body_micro: {
    main: 'font-inter text-xs',
    weights: {
      bold: 'font-bold',
      regular: 'font-normal',
    },
  },
  h5: {
    main: 'text-base text-grey-600 font-oswald uppercase',
    weights: {
      regular: 'font-extralight',
      bold: 'font-medium',
    },
  },
  h4: {
    main: 'text-2xl text-gray-800 font-oswald uppercase',
    weights: {
      regular: 'font-extralight',
      bold: 'font-medium',
    },
  },
  h3: {
    main: 'font-oswald uppercase text-blue-800 text-2xl md:text-3.5xl',
    weights: {
      regular: 'font-extralight',
      bold: 'font-medium',
    },
  },
  h2: {
    main: 'font-oswald uppercase text-blue-800 text-3.5xl md:text-4.5xl',
    weights: {
      regular: 'font-extralight',
      bold: 'font-medium',
    },
  },
  h1: {
    main: 'font-oswald uppercase text-blue-800 text-4.5xl md:text-6.5xl',
    weights: {
      regular: 'font-extralight',
      bold: 'font-medium',
    },
  },
}

export default {
  props,
  computed: {
    dynamicClass() {
      const currentClass = this.props.class || ''
      const typography = this.props.typography

      if (!typography) return currentClass

      const typographyStyles = this.getTypographyStyles(typography)
      return twMerge(currentClass, typographyStyles)
    },
  },
  methods: {
    getTypographyStyles(typography) {
      const variant = variants[typography.variant]
      let classVariant = variant.main

      classVariant += ` ${variant.weights[typography.weight]}`

      return classVariant
    },
  },
}
