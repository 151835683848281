function formatFormForAnalytics(formData) {
  const formattedArray = []
  for (const [key, value] of formData) {
    formattedArray.push({
      name: key,
      value: `${value}`,
    })
  }
  return formattedArray
}

export const fireTag = (formData, postUrl, formId = 'notSet') => {
  if ('dataLayer' in window) {
    window.dataLayer.push({
      event: 'formSubmission',
      formId,
      response: formatFormForAnalytics(formData),
      postUrl,
    })
  }
}
