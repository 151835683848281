
import ClickOutside from 'vue-click-outside'
export default {
  name: 'LanguageSwitcher',
  directives: {
    ClickOutside,
  },
  data() {
    return {
      isVisible: false,
    }
  },
  computed: {
    availableLocales() {
      const locales = this.$i18n.locales
      return locales.sort((a, b) => a.native.localeCompare(b.native))
    },
    currentLocale() {
      return this.$i18n.localeProperties.code
    },
  },
  methods: {
    onHover() {
      if (
        this.$store.getters['resize/isDesktop'] &&
        !this.$store.getters['resize/isIpadPro'] &&
        this.isVisible === false
      )
        this.isVisible = true
    },
    onLeave() {
      if (
        this.$store.getters['resize/isDesktop'] &&
        !this.$store.getters['resize/isIpadPro'] &&
        this.isVisible === true
      )
        this.isVisible = false
    },
    onClick() {
      this.isVisible = false
    },
    toggleDropdown() {
      if (
        !this.$store.getters['resize/isDesktop'] ||
        this.$store.getters['resize/isIpadPro']
      ) {
        this.isVisible = !this.isVisible
      }
    },
  },
}
