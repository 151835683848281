
export default {
  name: 'DesktopSearch',
  data() {
    return {
      searchInput: '',
      searchError: false,
      searchOpen: false,
    }
  },
  watch: {
    searchOpen(value) {
      this.$emit('isOpen', value)
    },
  },
  methods: {
    searchAction() {
      this.searchError = false
      if (this.searchOpen === false) {
        this.searchOpen = true
        this.$nextTick(() => {
          this.$refs?.searchInput.focus()
        })
      } else if (this.searchInput === '') {
        this.triggerError()
      } else {
        this.$router.push({
          path: this.localePath({ path: '/search' }),
          query: { q: this.searchInput.trim() },
        })
        this.searchInput = ''
        this.searchOpen = false
      }
    },
    triggerError() {
      this.searchError = true
      setTimeout(() => {
        this.searchError = false
      }, 1500)
    },
  },
}
