// Cookies JS
export function setCookie(cookieName, cookieValue, expirationDate) {
  const date = new Date()
  date.setTime(date.getTime() + expirationDate * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + date.toGMTString()
  document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';path=/'
}

export function getCookie(cookieName) {
  const name = cookieName + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const decodedCookies = decodedCookie.split(';')
  for (let i = 0; i < decodedCookies.length; i++) {
    let currentCookie = decodedCookies[i]
    while (currentCookie.charAt(0) === ' ') {
      currentCookie = currentCookie.substring(1)
    }
    if (currentCookie.indexOf(name) === 0) {
      return currentCookie.substring(name.length, currentCookie.length)
    }
  }
  return ''
}
