function getByHeadquarter(domains, isHeadquarter) {
  return Object.keys(domains).reduce((previous, currentKey) => {
    const currentDomain = domains[currentKey]
    return currentDomain.isHeadquarter === isHeadquarter
      ? [...previous, { ...currentDomain, code: currentKey }]
      : previous
  }, [])
}

function getLogo(domainCode = this.defaultDomainCode, type = 'dark') {
  return this.domains[domainCode]?.logos?.[type]
}

function getUrl(domainCode = this.defaultDomainCode) {
  return `https://${this.domains[domainCode]?.domain}`
}

function getSubsidiaries() {
  return getByHeadquarter(this.domains, false)
}

function getHeadquarters() {
  return getByHeadquarter(this.domains, true)
}

function domainsPlugin(config) {
  const { domains, domainCodes } = config
  if (!domains) return
  return {
    domains,
    defaultDomainCode: 'iot',
    defaultDomainId: 2,
    domainCodes,
    getSubsidiaries,
    getHeadquarters,
    getLogo,
    getUrl,
  }
}

export default ({ $config }, inject) => {
  inject('domains', () => domainsPlugin($config))
}
