export const highlight = (value, highlightValue) => {
  const startPosition = value
    .toLowerCase()
    .indexOf(highlightValue.toLowerCase())
  const searchableLength = highlightValue.length
  const searchableHighlight = value.slice(
    startPosition,
    startPosition + searchableLength,
  )
  return value.replace(
    searchableHighlight,
    `<strong>${searchableHighlight}</strong>`,
  )
}

export const stripHtml = (value) => {
  if (!value) {
    return
  }
  const regex = /(<([^>]+)>)/gi
  return value.replace(regex, '')
}

export const capitalize = (word) => {
  if (!word) {
    return
  }
  const lower = word.toLowerCase()
  return word.charAt(0).toUpperCase() + lower.slice(1)
}
