export const formatFilters = (filters, selectedFilters) => {
  const formattedFilters = []

  for (const filterKey in filters) {
    const filterBlocks = filters[filterKey]
    const formattedFilterBlocks = filterBlocks.map((filterBlock) =>
      formatFilterBlock(
        filterBlock,
        selectedFilters[filterKey] || [],
        filterKey,
      ),
    )
    formattedFilters.push(...formattedFilterBlocks)
  }

  return formattedFilters
}

const formatFilterBlock = (filterBlock, selectedFilters, urlParam) => {
  try {
    const list = preselectFiltersOptions(filterBlock.list, selectedFilters)
    return {
      isSearchable: filterBlock.show_search,
      list,
      showable: false,
      showableOptions: filterBlock.showable_options,
      title: filterBlock.title,
      urlParam,
    }
  } catch {
    return {}
  }
}

const preselectFiltersOptions = (filterOptions, selectedFilters) => {
  try {
    return Object.keys(filterOptions).map((key) => {
      return {
        code: key.trim(),
        title: filterOptions[key],
        isSelected: selectedFilters.includes(Number(key)),
      }
    })
  } catch {
    return []
  }
}

export const getSelectedFilters = (filters) => {
  const selectedFilterOptions = {}
  for (const { urlParam, list } of filters) {
    for (const { isSelected, code } of list) {
      if (!isSelected) continue
      if (!selectedFilterOptions[urlParam]) {
        selectedFilterOptions[urlParam] = []
      }
      selectedFilterOptions[urlParam].push(Number(code))
    }
  }
  return selectedFilterOptions
}
