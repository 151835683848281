export const spacings = (spacings = {}) => {
  const leftSpacings = {
    8: 'ml-2',
    16: 'ml-4',
    24: 'ml-6',
  }
  const rightSpacings = {
    8: 'mr-2',
    16: 'mr-4',
    24: 'mr-6',
  }
  const topSpacings = {
    8: 'mt-2',
    16: 'mt-4',
    24: 'mt-6',
  }
  const bottomSpacings = {
    8: 'mb-2',
    16: 'mb-4',
    24: 'mb-6',
  }

  const { left, right, top, bottom } = spacings

  return `${leftSpacings[left] || ''} ${
    rightSpacings[right] || ''
  } ${topSpacings[top] || ''} ${bottomSpacings[bottom] || ''}`
    .replace(/ +(?= )/g, '')
    .trim()
}
