
export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    validator: {
      type: [Object, undefined],
      default: undefined,
    },
    rows: {
      type: Number,
      default: 1,
    },
    mode: {
      type: [String, Boolean],
      default: false,
    },
    inputClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasError() {
      return this.validator !== undefined && this.validator.$error
    },
    isRequired() {
      return typeof this.validator?.required !== 'undefined'
    },
  },
  methods: {
    updateInput(event) {
      this.$emit('input', event.target.value)
    },
  },
}
