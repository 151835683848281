export const formatGalleryData = (galleryData = []) => {
  if (!galleryData || !galleryData.length) {
    return
  }
  const items = []
  galleryData.forEach((item) => {
    if (item.section_code === 'flespi_image') {
      const obj = {
        image: item?.data?.[0]?.value,
        date: item?.data?.[4]?.value || '',
        title: item?.data?.[1]?.value || '',
        type: 'image',
      }
      items.push(obj)
    } else if (item?.section_code === 'flespi_video') {
      const link = item?.data?.[0]?.value.replace(/<\/?[^>]+(>|$)/g, '') || ''
      const regex = /[?&]([^=#]+)=([^&#]*)/g
      const params = {}
      let match
      while ((match = regex.exec(link))) {
        params[match[1]] = match[2]
      }
      const obj = {
        videoID: params.v,
        videoLink: link,
        date: item?.data?.[4]?.value || '',
        title: item?.data?.[1]?.value || '',
        type: 'video',
      }
      items.push(obj)
    }
  })
  return items
}
