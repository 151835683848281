import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import VueScrollTo from 'vue-scrollto'
import { requestMixin } from '~/mixins'
import ChoiceComponent from '~/components/form/ChoiceComponent.vue'
import InputComponent from '~/components/form/InputComponent.vue'
import { fireTag } from '~/utils'

/* 
  Newsletter logic for footer modal and /subscribe page
*/

export default {
  mixins: [validationMixin, requestMixin],
  components: {
    InputComponent,
    ChoiceComponent,
  },
  data() {
    return {
      form: {
        email: '',
        subscribe_to: [],
        personalAgreement: false,
      },
      subscriptionList: [
        {
          title: 'form.telematics',
          logo: 'telematics',
          choices: [
            {
              label: 'form.newsletter',
              value: 'personal-tracking-newsletter',
            },
            {
              label: 'form.product_change',
              value: 'vehicle-tracking-pcn',
            },
          ],
        },
        {
          title: 'form.networks',
          logo: 'networks',
          choices: [
            {
              label: 'form.newsletter',
              value: 'networking-newsletter',
            },
            {
              label: 'form.product_change',
              value: 'networking-pcn',
            },
          ],
        },
        {
          title: 'form.telemedic',
          logo: 'telemedic',
          choices: [
            {
              label: 'form.newsletter',
              value: 'telemedic-newsletter',
            },
            // {
            //   label: 'form.product_change',
            //   value: 'telemedic-product-change'
            // }
          ],
        },
        {
          title: 'form.ems',
          logo: 'ems',
          choices: [
            {
              label: 'form.newsletter',
              value: 'ems-newsletter',
            },
            // {
            //   label: 'form.product_change',
            //   value: 'ems-product-change'
            // }
          ],
        },
        {
          title: 'form.energy',
          logo: 'energy',
          choices: [
            {
              label: 'form.newsletter',
              value: 'energy-newsletter',
            },
            {
              label: 'form.product_change',
              value: 'energy-pcn',
            },
          ],
        },
      ],
    }
  },
  computed: {
    currentSubscriptions() {
      if (this.$config.isHeadquarter) {
        return this.subscriptionList
      } else {
        return this.subscriptionList.filter((subscription) =>
          this.$config.domain.includes(subscription.logo),
        )
      }
    },
  },
  mounted() {
    for (let i = 0; i < this.currentSubscriptions.length; i++) {
      for (let j = 0; j < this.currentSubscriptions[i].choices.length; j++) {
        this.form.subscribe_to = [
          ...this.form.subscribe_to,
          this.subscriptionList[i].choices[j].value,
        ]
      }
    }
  },
  methods: {
    onSelect(choice, isSelected) {
      if (isSelected === true) {
        this.form.subscribe_to = [...this.form.subscribe_to, choice.value]
      } else {
        this.form.subscribe_to = this.form.subscribe_to.filter(
          (item) => item !== choice.value,
        )
      }
    },
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid || this.formStatus === this.formStatuses.LOADING) {
        VueScrollTo.scrollTo('.text-red-600', 200, { offset: -75 })
        return
      }

      try {
        this.formStatus = this.formStatuses.LOADING

        const formData = new FormData()
        formData.append('source', window.location.href)
        formData.append('email', this.form.email)
        formData.append(
          'privacy_policy',
          this.form.personalAgreement ? 'agree' : null,
        )
        this.form.subscribe_to.forEach((sub) => {
          formData.append('subscribe_to[]', sub)
        })
        const postUrl = 'https://webapi.teltonika.lt/api/subscribed-newsletters'
        const response = await this.$axios.post(postUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        if (response.data.code === 200) {
          fireTag(formData, postUrl, 'newsletter-form')
          this.responseMessage = response?.data?.message || 'Success!'
          this.formStatus = this.formStatuses.SUCCESS
          this.$refs.newsletterRef.reset()
          this.$v.$reset()
          setTimeout(() => {
            this.formStatus = this.formStatuses.FINISHED
            this.showModal = false
          }, 3000)
        }
      } catch (ex) {
        if (ex?.response?.status >= 500 || ex?.response?.status === 400) {
          if (ex?.response?.data?.message) {
            this.responseMessage =
              ex?.response?.data?.message ||
              'Server error. Please try again later.'
          }
          this.formStatus = this.formStatuses.ERROR
          setTimeout(() => {
            this.formStatus = this.formStatuses.FINISHED
          }, 3000)
        } else {
          this.formStatus = this.formStatuses.FINISHED
        }
      }
    },
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        subscribe_to: {
          notEmpty(value) {
            return value.length > 0
          },
        },
        personalAgreement: {
          required,
          selected(value) {
            return value !== false
          },
        },
      },
    }
  },
}
