
import { defaultHeadMixin } from '~/mixins'
export default {
  name: 'NotFound',
  mixins: [defaultHeadMixin],
  metaInfo() {
    return {
      nav: 'static',
    }
  },

  methods: {
    submitSearch(searchValue) {
      if (searchValue?.trim().length <= 0) return
      this.$router.push({
        path: this.localePath('/search'),
        query: { q: searchValue.trim() },
      })
    },
  },
}
