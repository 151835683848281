
export default {
  name: 'Secondary',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
  },
}
