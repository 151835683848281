export const formatCategories = (categories = []) => {
  const sections = []

  if (!Array.isArray(categories)) return []

  for (const item of categories) {
    if (typeof item?.id === 'undefined') continue

    const component = item?.list_component_name || false

    if (!component) continue

    let formattedSectionItem = {
      id: item.id,
    }
    switch (component) {
      case 'LazyProductsCategoryTitle':
        formattedSectionItem = {
          ...formattedSectionItem,
          props: {
            title: item?.title || '',
            href: item?.processed_url || '',
          },
          component,
        }
        break
      case 'LazySectionProductCategory':
        formattedSectionItem = {
          ...formattedSectionItem,

          props: {
            title: item?.title || '',
            id: item.id,
            href: item?.processed_url || '',
          },
          component: 'LazySectionProductCategory',
        }
        break
      case 'LazySectionProductCategoryTelematics':
        formattedSectionItem = {
          ...formattedSectionItem,

          props: {
            title: item?.title || '',
            id: item.id,
            href: item?.processed_url || '',
          },
          component: 'LazySectionProductCategoryTelematics',
        }
        break

      case 'SectionRms':
        formattedSectionItem = {
          ...formattedSectionItem,
          // all props return by translations
          component,
        }
        break
      default:
        formattedSectionItem = false
        break
    }
    if (formattedSectionItem) sections.push(formattedSectionItem)
  }

  return sections
}

export const formatProductCards = (
  productsList = [],
  alwaysProduct = false,
) => {
  if (!Array.isArray(productsList)) return []

  return productsList.map((productItem) => {
    const { categories } = productItem
    const extSectionGroups =
      getExtSectionGroupsByName(productItem, 'RelationCard') || {}
    const card = extSectionGroups.card || {}
    const connectionTypes = extSectionGroups.connectionTypes || []
    let url = '#'
    const variant = productItem?.is_accessory ? 'accessory' : 'product'
    const media = card?.image
    let extras = {
      subtitle: card?.subtitle || '',
    }
    if (variant === 'product' || alwaysProduct) {
      extras = {
        content: card.content,
        model: card.variant,
      }
    }
    let category = ''
    if (categories && Array.isArray(categories) && categories.length > 0) {
      category = categories[1] || categories[0]
    }
    try {
      url = productItem.directUrl
    } catch (ex) {
      console.error(
        `Cannot parse full url of product with id: ${productItem.id}`,
        ex,
      )
    }
    return {
      id: productItem.id,
      title: card?.title || productItem?.title || '',
      media,
      url,
      variant,
      category,
      extras,
      connectionTypes,
      isComparable: productItem.isComparable,
      sortTag: productItem.sortTag,
    }
  })
}

export const getExtSectionGroupsByName = (relatedProduct, name) => {
  if (!Array.isArray(relatedProduct?.ext_sections) && !name) return {}

  return (
    relatedProduct?.ext_sections?.find((item) => item?.section === name)
      ?.groups || {}
  )
}

export const getProductRelatedCard = (relatedProduct) => {
  const { card } =
    getExtSectionGroupsByName(relatedProduct, 'RelationCard') || {}
  let image

  if (card) {
    image = card?.image
  }

  if (!relatedProduct?.directUrl && !relatedProduct?.direct_url)
    console.error(`Direct_url empty on ${relatedProduct.id} in RelatedCard`)

  return {
    title: card.title,
    image,
    link: relatedProduct?.directUrl || relatedProduct?.direct_url || '/',
  }
}

export const getEOLCard = (relatedProduct) => {
  return getExtSectionGroupsByName(relatedProduct, 'EndOfLifeCard')
}

export const getUseCaseFeaturedProductRelation = (relatedProduct) => {
  return getExtSectionGroupsByName(
    relatedProduct,
    'UseCaseFeaturedProductRelation',
  )
}

export const relatedFormatted = (list) => {
  if (!Array.isArray(list) || list?.length === 0) return false

  return list.map((product) => {
    const card =
      product?.ext_sections?.find((item) => item?.section === 'RelationCard')
        ?.groups?.card || {}
    let image = '/images/default-image.jpg'

    if (card) {
      image = card?.image || '/images/default-image.jpg'
    }

    if (!product?.direct_url)
      console.error(`Direct_url empty on ${product?.id} in RelatedCard`)

    return {
      title: card.title,
      image,
      link: product?.direct_url || '#',
      contentRelated: card.contentRelated,
    }
  })
}
