
export default {
  name: 'FormErrorState',
  props: {
    state: {
      type: String,
      default: 'success',
      validator: (value) => ['success', 'error'].includes(value),
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconAttributes() {
      const icons = {
        success: {
          name: 'globals/check-circle',
          class: 'text-[#16A34A] w-16 h-16',
        },
        error: {
          name: 'globals/x-circle',
          class: 'text-pink-700 w-16 h-16',
        },
      }
      return icons[this.state]
    },
  },
}
