const PREFERENCES_KEY = 'accept_cookies_2'
const hasLocalStorage =
  process.client && typeof window?.localStorage !== 'undefined'

export const getLocalStorageCookiesPreferences = () => {
  if (!hasLocalStorage) throw new Error('Does not support localStorage')

  const cookies = localStorage.getItem(PREFERENCES_KEY) || {}

  if (Object.keys(cookies).length === 0) return false

  return JSON.parse(cookies)
}

export const setLocalStorageCookiesPreferences = (options) => {
  if (!hasLocalStorage) throw new Error('Does not support localStorage')

  try {
    localStorage.setItem(PREFERENCES_KEY, JSON.stringify(options))
    return true
  } catch (ex) {
    return false
  }
}
