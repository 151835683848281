
export default {
  name: 'MobileSearch',
  data() {
    return {
      searchInput: '',
    }
  },
  computed: {
    isCloseVisible() {
      return this.searchInput?.length > 0 || false
    },
  },
  methods: {
    searchAction() {
      if (this.searchInput?.length > 0) {
        this.$router.push({
          path: this.localePath({ path: '/search' }),
          query: { q: this.searchInput.trim() },
        })
        this.searchInput = ''
      }
    },
  },
}
