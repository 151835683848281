import VueRecaptcha from 'vue-recaptcha'
export default {
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      form: {
        recaptcha: null,
      },
    }
  },
  methods: {
    verifyRecaptcha(response) {
      if (response) {
        this.form.recaptcha = response
      }
    },
    onRecaptchaError() {
      this.form.recaptcha = false
    },
  },
}
