export const state = () => ({
  content: {},
})

export const mutations = {
  setPage(state, payload) {
    state.content = payload
  },
}

export const actions = {}
