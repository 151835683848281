export const formatMeta = ({
  title = '',
  description = '',
  img = '',
  url = '',
  canonical = '',
  htmlAttrs = {},
}) => {
  const meta = []
  const finalResult = {}
  if (title) {
    meta.push({
      property: 'og:title',
      hid: 'og:title',
      content: title,
    })
    meta.push({
      property: 'twitter:title',
      hid: 'twitter:title',
      content: title,
    })
  }
  if (description) {
    meta.push({
      name: 'description',
      hid: 'description',
      content: description,
    })
    meta.push({
      property: 'og:description',
      hid: 'og:description',
      content: description,
    })
    meta.push({
      property: 'twitter:description',
      hid: 'twitter:description',
      content: description,
    })
  }
  if (img) {
    const isRemote = /^(http|https)/gi.test(img)
    const finalImg = isRemote ? img : process.env.domain + img
    meta.push({
      property: 'og:image',
      hid: 'og:image',
      content: finalImg,
    })
    meta.push({
      property: 'twitter:image',
      hid: 'twitter:image',
      content: finalImg,
    })
  }
  if (url) {
    meta.push({
      property: 'og:url',
      hid: 'og:url',
      content: url,
    })
    meta.push({
      property: 'twitter:url',
      hid: 'twitter:url',
      content: url,
    })
  }
  if (title) finalResult.title = title
  if (canonical?.length > 0) {
    finalResult.link = [
      {
        rel: 'canonical',
        href: canonical,
      },
    ]
  }
  if (htmlAttrs) {
    finalResult.htmlAttrs = htmlAttrs
  }
  finalResult.meta = meta
  return finalResult
}
