import { getEOLCard, getFormattedDate } from '@/utils'
export default {
  computed: {
    isEol() {
      return this.$parent?.product?.tags?.includes('eol')
    },
    eolCard() {
      const card = getEOLCard(this.$parent?.product)
      if (!card.eol) return false

      return { ...card.eol, replacements: card?.replacements || [] }
    },
  },
  methods: {
    eolFormattedDate(date) {
      return getFormattedDate(
        date,
        this.$store.state['date-fns'].activeLocale,
        'y MMMM d',
      )
    },
  },
}
