export const getUrlParamsByNames = (params, names) => {
  if (!params || !names) return {}
  const filteredUrls = {}
  for (const paramKey in params) {
    const paramValue = params[paramKey]
    if (names.includes(paramKey) && paramValue) {
      const paramValuesAsNumbers = []
        .concat(paramValue)
        .map((value) => Number(value))
      filteredUrls[paramKey] = paramValuesAsNumbers
    }
  }
  return filteredUrls
}

export const getHyperlinkInfo = (runningOrigin, link) => {
  try {
    const linkInstance = new URL(link)
    const origin = linkInstance.origin

    if (origin !== runningOrigin) return { isExternal: true, link }

    const fullPath = link.replace(origin, '')
    return { isExternal: false, link: fullPath }
  } catch (err) {
    return { isExternal: false, link }
  }
}

export const isPaginationUpdate = (to, from) => {
  const updateFromPagination =
    process?.client &&
    from &&
    to?.path === from?.path &&
    JSON.stringify(to?.query) !== JSON.stringify(from?.query)
  return updateFromPagination
}
