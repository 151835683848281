
import ClickOutside from 'vue-click-outside'
import { mapActions } from 'vuex'

export default {
  name: 'Desktop',
  directives: {
    ClickOutside,
  },
  data() {
    return {
      isSearchOpen: false,
    }
  },
  computed: {
    logo() {
      return this.$config.lightLogo || '/images/branches/iot-white.svg'
    },
    isNavigationOpen: {
      get() {
        return this.$store.state.navigation.desktop.isOpen
      },
      set() {
        this.toggleNavigation('desktop')
      },
    },
    careerAmount() {
      return this.$store.state.navigation?.careerCount || 0
    },
    storeMenu() {
      return this.$store.state.navigation.menus?.[this.$i18n.locale]
    },
    menu() {
      return this.storeMenu?.header || []
    },
    menuListWithoutCompanies() {
      return this.menu?.filter((item, index) => index > 0) || []
    },
    companiesList() {
      return [this.menu[0], ...this.menu[0].children]
    },
  },
  methods: {
    ...mapActions({
      toggleNavigation: 'navigation/toggleNavigationAction',
    }),
    closeNavigation() {
      if (this.isNavigationOpen === true) {
        this.isNavigationOpen = false
      }
    },
  },
}
