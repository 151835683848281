
export default {
  name: 'ChoiceComponent',
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: () => new Date().getTime(),
    },
    toggle: {
      type: Boolean,
      default: false,
    },
  },
}
