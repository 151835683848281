import { mapMutations, mapActions } from 'vuex'
export default {
  watch: {
    showForm: {
      async handler(value) {
        if (value === true) {
          if (this.officeList?.length === 0) {
            await this.fetchOffices()
            this.$forceUpdate()
          }
          await this.setFormLocation()
        }
      },
      immediate: true,
    },
    'form.company'(newValue) {
      this.setActiveOffice(newValue)
    },
    officeList() {
      if (!this.form?.company) {
        return
      }
      this.form.company = this.officeList?.[0]?.address || ''
      this.setActiveOffice(this.storeOffice)
    },
  },
  computed: {
    showForm: {
      get() {
        return this.$store.state.contacts?.show
      },
      set() {
        this.toggleVisible()
      },
    },
    storeOffice() {
      return this.$store.state.contacts.office
    },
    officeList() {
      return this.$store.state.contacts.offices
    },
  },
  methods: {
    ...mapMutations({
      toggleForm: 'contacts/toggleShow',
    }),
    ...mapActions({ fetchOffices: 'contacts/fetchOffices' }),

    toggleVisible(office = 'geleziniovilko18a') {
      this.toggleForm(office)
    },
    setActiveOffice(address) {
      this.activeOffice = this.officeList.find(
        (office) => office.address === address,
      )
    },
    async setFormLocation() {
      if (this.form?.location !== null) return
      try {
        const response = await this.$axios.get(
          'https://webapi.teltonika.lt/api/location',
        )
        if (response) {
          this.form.location =
            response?.data?.user_location ||
            response?.user_location ||
            'Lithuania'
        }
      } catch (_) {
        this.form.location = 'Lithuania'
      }
    },
  },
}
