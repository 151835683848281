import { FORM_STATUSES } from '~/types/forms'
export default {
  computed: {
    formStatuses() {
      return FORM_STATUSES
    },
    isLoading() {
      return this.formStatus === FORM_STATUSES.LOADING
    },
    isError() {
      return this.formStatus === FORM_STATUSES.ERROR
    },
    isFinished() {
      return this.formStatus === FORM_STATUSES.FINISHED
    },
    isSuccess() {
      return this.formStatus === FORM_STATUSES.SUCCESS
    },
  },
  data() {
    return {
      formStatus: FORM_STATUSES.FINISHED,
      responseMessage: 'Your request has been successfully sent. Thank you.',
    }
  },
}
