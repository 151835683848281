
export default {
  name: 'MobileNavigation',
  data() {
    return {
      activeTab: null,
    }
  },
  computed: {
    logo() {
      return this.$config.lightLogo || '/images/branches/iot-white.svg'
    },
    availableLanguages() {
      return this.$i18n.loadedLanguages
    },
  },
  mounted() {},
  methods: {
    setTab(value = null) {
      this.activeTab = value
    },
  },
}
