import safeParseJson from './safeParseJson'

export const slugUrl = (urlData = '', title = 'No title provided') => {
  if (!urlData || !Object.keys(urlData).length) {
    console.error(`No current_url for title: ${title}`)
  }

  if (typeof urlData === 'string') {
    return safeParseJson(urlData).full_url
  }

  if (!urlData.full_url) {
    console.error(`Post title: ${title} does not have generated slug`)
  }

  return urlData.full_url
}
