import { parseISO, format } from 'date-fns'
import { getEmbeddedUrl } from '@/utils'

export const WEBINAR_TYPES = {
  UPCOMING: 'upcoming',
  PAST: 'past',
}

export function getWebinarTimeByTimeZone(webinarDate) {
  if (!webinarDate) return new Date()
  // formattedWebinarDate needed, because new Date(yyyy-mm-dd) - not working on safari, it works with 'yyyy/mm/dd'
  const formattedWebinarDate = new Date(webinarDate.replace(/-/g, '/'))
  const diffFromGmt = new Date(webinarDate).getTimezoneOffset()

  const webinarTimeByTimeZone = new Date(
    formattedWebinarDate.setMinutes(
      formattedWebinarDate.getMinutes() - diffFromGmt,
    ),
  )

  return webinarTimeByTimeZone
}

export function formatWebinarDateByLocale(date, locale, dateFormat) {
  return (
    format(parseISO(getWebinarTimeByTimeZone(date).toISOString()), dateFormat, {
      addSuffix: true,
      locale,
    }) || ''
  )
}

export const formatWebinarObject = (item) => {
  if (!item) return false
  return {
    item,
    zoomWebinarID: item?.webinar_id || '',
    title: item?.title_translated || '',
    description: item?.description_translated || '',
    startTime: item?.webinar_starts_at,
    // TODO: remove when webinar single page is updated
    speakers: item?.speaker_translated?.split(',') || [],
    speakersArray: item?.speakers_array || [],
    language: item?.language || '',
    languageCode: item?.language_code || '',
    duration: item?.duration + ' min' || '',
    type: item?.webinar_past ? WEBINAR_TYPES.PAST : WEBINAR_TYPES.UPCOMING,
    isPast: item?.webinar_past,
    slug: item?.current_url?.full_url || '/',
    previewImage: item?.grid_photo_data || '/images/use-case-default-bg.jpg',
    backgroundImage: item?.photo_data || '/images/use-case-default-bg.jpg',
    youtubeVideoLink: getEmbeddedUrl(item?.video_url),
    registrationLink: item?.registration_link || '',
    hasVideo: item?.video_url !== null || false,
    faqs: item?.faqs || false,
  }
}
