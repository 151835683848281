const DOMAIN_FORMS = {
  ems: 'EMSContactForm',
  iot: 'IOTContactForm',
  gps: 'TelematicsContactForm',
  telemedic: 'TelemedicContactForm',
  energy: 'EnergyContactForm',
  networks: 'NetworksContactForm',
  thth: 'THTHContactForm',
}

const getFormCodeByDomain = (domain) => {
  if (domain.includes('high-tech-hill')) {
    return DOMAIN_FORMS.thth
  }

  for (const [key, value] of Object.entries(DOMAIN_FORMS)) {
    if (domain.includes(key)) {
      return value
    }
  }
}

export const state = () => ({
  show: false,
  structure: {},
  errors: false,
  loading: false,
  preselects: {},
})

export const mutations = {
  setStructure(state, structure) {
    state.structure = structure
    state.show = true
    state.loading = false
    state.errors = false
  },
  setServerStructure(state, structure) {
    state.structure = structure
  },
  setShow(state, show) {
    state.show = show
    if (show === false) {
      state.preselects = {}
    }
  },
  setLoading(state, loading) {
    state.loading = loading
  },
  setErrors(state, errors) {
    state.errors = errors
  },
  setPreselects(state, preselects) {
    state.preselects = preselects
  },
  addField(state, field) {
    if (
      state.structure?.fields?.some(
        (fieldItem) => fieldItem.name !== field?.name,
      )
    ) {
      state.structure.fields = [...state.structure.fields, field]
    }
  },
  removeFieldByName(state, fieldName) {
    if (state?.structure?.fields)
      state.structure.fields = state.structure.fields.filter(
        (field) => field.name !== fieldName,
      )
  },
}

export const actions = {
  openContactForm({ commit }, preselects) {
    commit('setShow', true)

    // if (!state.structure) {
    //   commit('setLoading', true)
    //   // const structure = await dispatch('fetchForm')
    //   // if (!structure) return
    //   // commit('setStructure', structure)
    // }

    if (preselects && typeof preselects === 'object') {
      commit('setPreselects', preselects)
    }
  },
  async fetchForm({ commit }) {
    try {
      const formCode = getFormCodeByDomain(this.$config.domain)
      const response = await this.$queryForm(formCode)

      return response[formCode]
    } catch (ex) {
      console.error(ex)
      commit('setErrors', true)

      return false
    }
  },
}
