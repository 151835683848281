import { gql } from 'graphql-tag'

export const state = () => ({
  theme: 'default',
  desktop: {
    isOpen: false,
  },
  mobile: {
    isOpen: false,
  },
  badges: [],
  headerMenu: [],
  footerMenu: [],
  menus: [],
  isNavigationVisible: true,
})

export const mutations = {
  setTheme(state, theme) {
    if (theme !== state.theme) state.theme = theme
  },
  setBadges(state, badges) {
    state.badges = badges
  },
  openNavigation(state) {
    state.desktop.isOpen = true
    state.mobile.isOpen = false
  },
  closeNavigation(state, type = 'desktop') {
    state[type].isOpen = false
  },
  toggleNavigation(state, type = 'desktop') {
    state[type].isOpen = !state[type].isOpen
  },
  setHeaderMenu(state, menu) {
    state.headerMenu = menu
  },
  setMenus(state, menus) {
    state.menus = menus
  },
  setIsNavigationVisible(state, payload) {
    state.isNavigationVisible = payload
  },
}

export const getters = {
  getIsNavigationVisible: (state) => {
    return state.isNavigationVisible
  },
}

function getHeaderBadges(menu, result = new Set([])) {
  for (const menuItem of menu) {
    if (menuItem.badge?.type) {
      result.add(menuItem.badge.type)
    }
    if (menuItem.children.length > 0) {
      getHeaderBadges(menuItem.children, result)
    }
  }
  return [...result]
}

function getBadgesQuery(badges) {
  let structure = ''

  for (const badge of badges) {
    structure += `${badge}(domain_id: $domainId)

    `
  }
  return structure
}

export const actions = {
  async toggleNavigationAction({ commit, dispatch, state }, type = 'desktop') {
    commit('toggleNavigation', type)
    if (state.badges.length === 0) await dispatch('fetchBadges')
  },
  async fetchBadges({ commit }) {
    const currentMenu = this.state.navigation.menus[this.$i18n.locale].header
    const badges = getHeaderBadges(currentMenu)
    if (badges?.length === 0) return false

    try {
      const { data } = await this.app.gqlQuery({
        cachePolicy: 'cache-only',
        query: gql`
          query badges($domainId: Int!) {
            ${getBadgesQuery(badges)}
          }
        `,
        variables: {
          domainId: this.$config.domainId,
        },
      })
      commit('setBadges', data)
    } catch (ex) {
      console.log(ex)
    }
  },
}
