
import { debounce } from '@/utils'
export default {
  name: 'SimpleSearch',
  data() {
    return {
      search: '',
      minChars: 3,
      maxChars: 100,
      isValidationActivated: false,
    }
  },
  computed: {
    error() {
      return (
        this.isValidationActivated &&
        (this.search.trim().length < this.minChars ||
          this.search.trim().length > this.maxChars)
      )
    },
    errorMessage() {
      if (this.search.trim().length < this.minChars)
        return this.$t('search.error_message_minimum')
      if (this.search.trim().length > this.maxChars)
        return this.$t('search.error_message_maximum')
      return ''
    },
  },
  created() {
    this.minChars = parseInt(this.$t('search.mininum_characters'))
    this.maxChars = parseInt(this.$t('search.maximum_characters'))
  },
  mounted() {
    this.search = this.$route?.query?.q || ''
    if (this.$route?.query?.q) {
      this.$nextTick(() => {
        this.submitSearch()
      })
    }
  },
  methods: {
    inputSearch: debounce(function () {
      this.submitSearch()
    }, 300),
    submitSearch() {
      if (!this.isValidationActivated) this.isValidationActivated = true
      if (this.error) return
      this.$emit('submit', this.search)
    },
  },
}
