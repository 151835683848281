
import VueScrollTo from 'vue-scrollto'
export default {
  name: 'Footer',
  filters: {
    iotFilter(value) {
      if (!value) return ''
      return value?.replace('IOT', 'IoT') || ''
    },
  },
  props: {
    socialLinks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isElementInViewport: false,
    }
  },
  computed: {
    isSubscribePage() {
      return this.$route?.name?.includes('subscribe') || false
    },
    allMenus() {
      return (
        this.$store.state.navigation.menus[this.$i18n?.locale || 'en'] || {}
      )
    },
    menu() {
      return this.allMenus?.footer?.filter(this.itemHasUrl) || false
    },
    miniMenu() {
      return this.allMenus?.footerMini?.filter(this.itemHasUrl) || false
    },
    topMenu() {
      return this.allMenus?.footerTop?.filter(this.itemHasUrl) || false
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      threshold: 0.5,
    })
    this.observer.observe(this.$refs.footer)
  },
  beforeDestroy() {
    this.observer.disconnect()
  },
  methods: {
    scrollToSubscribeForm() {
      VueScrollTo.scrollTo('#SubscribeNewslettersForm', 300, {
        offset: -200,
      })
    },
    handleIntersection(entries) {
      const [entry] = entries
      if (entry.isIntersecting) {
        this.$store.commit('resize/setFooterInViewport', true)
        this.isElementInViewport = true
      } else {
        this.isElementInViewport = false
        this.$store.commit('resize/setFooterInViewport', false)
      }
    },
    getLink(linkObject) {
      if (linkObject?.redirect_url) {
        return {
          href: linkObject?.redirect_url?.includes('http')
            ? linkObject?.redirect_url
            : this.localePath(linkObject?.redirect_url || '/'),
        }
      }
      return {
        to: this.localePath(linkObject.full_url),
      }
    },
    itemHasUrl(item) {
      if (!item.url) {
        this.$sentry.captureException(
          `No url generated for link with title: ${item.title}`,
        )
      }
      return !!item.url
    },
  },
}
