import Vue from 'vue'
import FileManagerImage from './FileManagerImage.vue'
import Manager from './manager.js'
import { THUMBS } from './constants.js'

const filemanager = (media) => {
  return new Manager(media)
}

Vue.component('FileManagerImage', FileManagerImage)

export default (_, inject) => {
  inject('filemanager', (media) => filemanager(media))
  inject('thumbs', THUMBS)
}
