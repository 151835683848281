import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      checkCompare: false,
    }
  },
  computed: {
    ...mapGetters({ isMobile: 'resize/isMobile' }),
    disabled() {
      const legitAmount = this.isMobile ? 2 : 4
      return (
        this.$store.state.product.comparableProductList.length >= legitAmount &&
        !this.checkCompare
      )
    },
  },
  mounted() {
    this.checkCompare = this.$store.state.product.comparableProductList?.some(
      (item) => item?.id === this.id || false,
    )
  },
  watch: {
    '$store.state.product.comparableProductList'(newVal) {
      this.checkCompare = newVal?.some((item) => item?.id === this.id) || false
    },
  },
  methods: {
    toggleCheckBox() {
      this.toggleComparableProduct({
        id: this.id,
        image: this.media,
        title: this.title,
      })
    },
    ...mapActions({
      toggleComparableProduct: 'product/toggleComparableProduct',
    }),
  },
}
