import Vue from 'vue'
import fetch from 'node-fetch'
const redirectsList = async (host = '') => {
  try {
    const request = await fetch(host + '/redirects.json', {
      cache: 'no-cache',
      headers: {
        'cache-control': 'no-cache',
        Authorization:
          'Basic ' + Buffer.from('tlt-admin:i5N8a8ctk8cHoX').toString('base64'),
      },
    })
    const redirects = await request.json()
    const parsed = JSON.parse(redirects)
    return parsed
  } catch (ex) {
    return {}
  }
}

const menusList = async (host = '') => {
  try {
    const request = await fetch(host + '/menus.json', {
      cache: 'no-cache',
      headers: {
        'cache-control': 'no-cache',
        Authorization:
          'Basic ' + Buffer.from('tlt-admin:i5N8a8ctk8cHoX').toString('base64'),
      },
    })
    const menus = await request.json()
    const byLanguages = menus.reduce((acc, reducer) => ({
      ...reducer,
      ...acc,
    }))
    return byLanguages
  } catch (ex) {
    return {}
  }
}

Vue.prototype.redirects = redirectsList

export default async (context, inject) => {
  if (process.server && context.store.state.navigation.menus.length === 0) {
    let url = ''
    try {
      const protocol =
        process.env.NODE_ENV === 'development' ||
        context.req.headers.host.include('localhost')
          ? 'http://'
          : 'https://'
      url = new URL(protocol + context.req.headers.host).origin
    } catch (e) {
      url = 'http://' + context.req.headers.host
    }
    try {
      if (process.env?.ORIGIN_URL && process.env?.ORIGIN_URL?.length > 0) {
        url = process.env?.ORIGIN_URL
      }
    } catch (ex) {}

    const redirects = await redirectsList(url)
    const menus = await menusList(url)
    context.store.commit('navigation/setMenus', menus)
    inject('redirects', redirects)
  }
}
