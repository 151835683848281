import { gql } from 'graphql-tag'

export const state = () => ({
  show: false,
  office: 'geleziniovilko18a',
  meta: {},
  offices: [],
})

export const mutations = {
  toggleShow(state, office) {
    state.show = !state.show
    state.office = office || 'geleziniovilko18a'
  },
  setMeta(state, meta) {
    if (meta && typeof meta === 'object') {
      state.meta = meta || {}
    }
  },
  setOffices(state, payload = []) {
    state.offices = payload || []
  },
  setOffice(state, office = 'geleziniovilko18a') {
    state.office = office
  },
}

export const actions = {
  async fetchOffices({ commit, state }) {
    if (state?.offices?.length > 0) return
    try {
      const { data } = await this.app.gqlQuery({
        cachePolicy: 'cache-only',
        query: gql`
          query offices($languageCode: String!, $domainId: Int!) {
            contacts(
              input: { language_code: $languageCode, domain_id: $domainId }
            ) {
              data {
                id
                title: title_translated
                value: form_value
                address: address_translated
                email
              }
            }
          }
        `,
        variables: {
          domainId: this.$config.domainId,
          languageCode: this.$i18n.locale,
        },
      })
      if (Array.isArray(data?.contacts?.data)) {
        commit('setOffices', data.contacts?.data)
      }
    } catch (ex) {
      this.$sentry.captureException(ex)
    }
  },
}
