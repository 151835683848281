import { getUniqueListBy } from './formatHelpers'

const getServiceIcon = (service) => {
  if (service.toLowerCase() === 'headquarters') {
    return 'flag'
  } else if (service.toLowerCase() === 'stock') {
    return 'cargo'
  } else if (service.toLowerCase() === 'manufacturing') {
    return 'cog'
  } else if (service.toLowerCase() === 'support') {
    return 'support'
  } else {
    return 'coins'
  }
}

export const formatCountryOptionsList = (data) => {
  if (!Array.isArray(data)) return []
  return data.map((item) => {
    return {
      text: item.countryName,
      value: item.countryName,
    }
  })
}

export const getOfficeTypes = (data = {}) => {
  return Object.keys(data).map((service) => ({
    name: data[service].title_translated,
    icon: getServiceIcon(service),
  }))
}

/*
    Formats response countries array
    Params: contacts: Array
    Returns: Array of countries
*/
export const formatCountriesList = (contacts = []) => {
  const countriesList = contacts.map((country) => ({
    countryName: country.country_title_translated,
    position: {
      x: country.x_coordinate,
      y: country.y_coordinate,
    },
    flag:
      country.location_code || country.country_title_translated.toLowerCase(),
    offices: contacts.filter(
      (contact) =>
        contact.country_title_translated.toLowerCase() ===
        country.country_title_translated.toLowerCase(),
    ),
    mapRegion: country?.mapRegion || { key: '', title: '' },
  }))

  return getUniqueListBy(countriesList, 'countryName')
}

export const formatRawMapResponse = (response) => {
  const tempContacts = response.data.map((contact) => ({
    ...contact,
    id: contact?.id || -1,
    position: {
      lat: contact?.latitude || 0,
      lng: contact?.longitude || 0,
    },
    address: contact?.address_translated || '',
    types: getOfficeTypes(contact?.services_data),
    value: contact?.address_translated || '',
  }))
  return formatCountriesList(tempContacts)
}
