// Example of finding if device is mobile: write $store.getters['resize/isMobile'] in any component.
// If you need to 'watch' device, use mapGetters, because $store.getters['resize/isMobile'] is not a valid string in watcher

import { debounce } from '~/utils'

export default {
  data() {
    return {
      resizeDelay: 1000,
      resizeObserverMethod: null,
    }
  },
  mounted() {
    if (process.client) {
      this.setClientWidth()
      this.resizeObserverMethod = debounce(
        this.resizeObserver,
        this.resizeDelay,
      )
      window.addEventListener('resize', this.resizeObserverMethod)
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeObserverMethod)
  },
  methods: {
    setClientWidth() {
      this.$store.dispatch('resize/clientWidth', window?.innerWidth || 0)
    },
    resizeObserver() {
      this.setClientWidth()
    },
  },
}
