export function formatUseCases(items, prodDomain = '') {
  if (!items.length) return
  return (
    items.map((item) => {
      return {
        id: item?.id || '',
        title: item?.title || '',
        link: item?.url || '',
        prodFullLink: 'https://' + prodDomain + item?.url || '',
        backgroundImage: item?.thumb || {},
        labelTitle: item?.assignedPage?.title || '',
        labelUrl: item?.assignedPage?.currentUrl?.full_url || '',
        prodLabelUrl:
          'https://' + prodDomain + item?.assignedPage?.currentUrl?.full_url ||
          '',
      }
    }) || []
  )
}
