export default {
  attributes: {
    type: Object,
    default: () => ({}),
  },
  component: {
    type: String,
    default: '',
  },
  defaultValue: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
  imageLabel: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  priority: {
    type: Number,
    default: 0,
  },
  props: {
    type: Object,
    default: () => ({}),
  },
  translations: {
    type: Object,
    default: () => ({}),
  },
  textLabel: {
    type: String,
    default: '',
  },
  tooltip: {
    type: String,
    default: '',
  },
  options: {
    type: Array,
    default: () => [],
  },
  changeable: {
    type: Object,
    default: () => ({}),
  },
}
