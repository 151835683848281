
export default {
  name: 'ButtonComponent',
  props: {
    as: {
      type: String,
      default: 'button',
      validator(value) {
        return ['NuxtLink', 'button', 'a'].includes(value)
      },
    },
    to: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: 'primary',
      validator(value) {
        return [
          'default',
          'primary',
          'secondary',
          'accent',
          'ghost',
          'transparent',
          'secondary-new',
        ].includes(value)
      },
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['large', 'medium', 'small'].includes(value)
      },
    },
    mobileWidth: {
      type: String,
      default: 'large',
      validator(value) {
        return ['large', 'medium'].includes(value)
      },
    },
    iconPath: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dynamicProps() {
      const props = {}

      const mobileWidths = {
        large: 'width--large',
        medium: 'width--medium',
      }

      props.class = mobileWidths[this.mobileWidth]

      if (this.disabled) props.class = props.class + ' cursor-not-allowed'

      if (this.to) props.to = this.to
      if (this.href) props.href = this.href
      if (this.href && this.target) props.target = this.target
      if (this.as === 'button') props.type = this.type || 'button'
      if (this.disabled) props.disabled = this.disabled

      return props
    },
    style() {
      const themes = {
        primary:
          'bg-blue-800 text-white hover:bg-blue-800 active-border--light',
        secondary:
          'bg-blue-800 text-white hover:bg-blue-800 active-border--light',
        accent:
          'bg-secondary text-white hover:bg-[#0BA4BA] active-border--dark',
        default:
          'bg-white text-blue-800 hover:bg-gray-2 hover:text-white active-border--light',
        ghost:
          'bg-transparent text-gray-2 border--ghost hover:bg-gray-2 hover:text-white active-border--light',
        transparent:
          'bg-transparent text-white border--white hover:bg-white hover:text-blue-800 active-border--light',
        'secondary-new':
          'bg-blue-600 text-white hover:bg-blue-800 active-border--light',
      }
      const mobile = 'px-4 text-lg leading-6 py-2'
      const sizes = {
        large: `${mobile} md:text-xl md:py-4 px--large`,
        medium: `${mobile} md:leading-6.5 md:py-3 px--medium`,
        small: `${mobile} md:text-base px--small`,
      }

      const commonDisabledClasses = 'pointer-events-none'

      const disabled = {
        primary: `${commonDisabledClasses} bg-blue-800-disabled`,
        secondary: `${commonDisabledClasses} bg-secondary-disabled`,
        accent: `${commonDisabledClasses} bg-accent-disabled`,
        default: `${commonDisabledClasses} bg-blue-800-disabled`,
        ghost: `${commonDisabledClasses} text-gray-3 disabled-ghost-border`,
        transparent: `${commonDisabledClasses}`,
        'secondary-new': `${commonDisabledClasses}`,
      }

      return `${themes[this.theme]} ${sizes[this.size]} ${
        this.disabled ? disabled[this.theme] : ''
      }`
    },
  },
}
